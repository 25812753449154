package com.sunday.kmplicenseperu.components

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.BorderBrand
import com.sunday.kmplicenseperu.models.Theme.BorderCorrect
import com.sunday.kmplicenseperu.models.Theme.BorderIncorrect
import com.sunday.kmplicenseperu.models.Theme.BorderPrimary
import com.sunday.kmplicenseperu.models.Theme.BorderPrimaryDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceAmber
import com.sunday.kmplicenseperu.models.Theme.SurfaceAmberDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceBlue
import com.sunday.kmplicenseperu.models.Theme.SurfaceBlueDark
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimaryDark
import com.sunday.kmplicenseperu.models.Theme.TextPrimary
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.pages.questions.QuestionsVM
import com.sunday.kmplicenseperu.styles.HighlightStyle
import com.sunday.kmplicenseperu.styles.HighlightStyle2
import com.sunday.kmplicenseperu.styles.MainButtonStyle
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.hightligh
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.disabled
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.w3c.dom.get

@Composable
fun CustomButton(
    breakpoint: Breakpoint,
    darkMode: Boolean,
    buttonText: String,
    disabled: Boolean = false,
    different: Boolean = false,
    onClick: () -> Unit
) {
    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val surfaceBlue = if(!darkMode) SurfaceBlue.color.rgb
    else SurfaceBlueDark.color.rgb
    val surfaceAmber = if(!darkMode) SurfaceAmber.color.rgb
    else SurfaceAmberDark.color.rgb
    val borderPrimary = if(!darkMode) BorderPrimary.color.rgb
    else BorderPrimaryDark.color.rgb
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        if(disabled) {
            Button(
                attrs = Modifier
                    .height(40.px)
                    .width(82.percent)
                    .border(width = 0.px)
                    .borderRadius(r = 5.px)
                    .border(width = 1.px, style = LineStyle.Solid, color = borderPrimary)
                    .backgroundColor(surfacePrimary)
                    .disabled(disabled)
                    .toAttrs()
            ) {
                MyParagraph(
                    text = buttonText,
                    color = textPrimary
                )
            }

        } else {
            Button(
                attrs = MainButtonStyle.toModifier()
                    .cursor(Cursor.Pointer)
                    .onClick { onClick() }
                    .height(40.px)
                    .border(width = 0.px)
                    .borderRadius(r = 5.px)
                    .backgroundColor(if(!different) surfaceBlue else surfaceAmber)
                    .toAttrs()
            ) {
                MyParagraph(
                    text = buttonText,
                    color = textPrimary
                )
            }
        }
    }
}

@Composable
fun MyQuestionOption(
    breakpoint: Breakpoint,
    questionsVM: QuestionsVM,
    darkMode: Boolean,
    option: String,
    optionSelected: String,
    optionPreviouslySelected: String,
    realResult: String,
    backgroundColor: CSSColorValue,
    onClick: () -> Unit = {}
) {
    val borderPrimary = if(!darkMode) BorderPrimary.color.rgb
    else BorderPrimaryDark.color.rgb

    if(optionPreviouslySelected != "") {
        questionsVM.updateWithPrevioulySelected(
            optionSelected = optionPreviouslySelected,
        )
    }

    val borderColor =
        if (questionsVM.questionState.isSolutionEnabled) {
            if (option.first().toString() == realResult) BorderCorrect.color.rgb
            else if (option.first().toString() == optionSelected) BorderIncorrect.color.rgb
            else borderPrimary
        } else {
            if (option.first().toString() == optionSelected) BorderBrand.color.rgb
            else borderPrimary
        }

    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val width = 80.percent
//    val width = when(breakpoint) {
//        Breakpoint.ZERO, Breakpoint.SM -> 85.percent
//        Breakpoint.MD -> 80.percent
//        Breakpoint.LG, Breakpoint.XL -> 65.percent
//    }
    Box(
        modifier = Modifier
            .thenIf(
                condition = !darkMode,
                other = HighlightStyle.toModifier()
            )
            .thenIf(
                condition = darkMode,
                other = HighlightStyle2.toModifier()
            )
            .thenIf(
                condition = option.first().toString() == optionSelected,
                other = Modifier.hightligh()
            )
            .width(width)
            .padding(leftRight = 10.px, topBottom = 5.px)
            .borderRadius(r = 5.px)
            .backgroundColor(backgroundColor)
            .cursor(Cursor.Pointer)
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = borderColor
            )
            .onClick { onClick() },
        contentAlignment = Alignment.CenterStart
    ) {
        MyParagraph(
            text = option,
            color = textPrimary
        )
    }
}

