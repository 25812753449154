package com.sunday.kmplicenseperu.pages.questions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.components.CustomButton
import com.sunday.kmplicenseperu.components.My35MinDialogContent
import com.sunday.kmplicenseperu.components.MyCaption
import com.sunday.kmplicenseperu.components.MyChangeRangeDialogContent
import com.sunday.kmplicenseperu.components.MyDialog
import com.sunday.kmplicenseperu.components.MyFinishDialogContent
import com.sunday.kmplicenseperu.components.MyHorizontalSpacer
import com.sunday.kmplicenseperu.components.MyParagraph
import com.sunday.kmplicenseperu.components.MyPauseDialogContent
import com.sunday.kmplicenseperu.components.MyProgressDialogContent
import com.sunday.kmplicenseperu.components.MyQuestionOption
import com.sunday.kmplicenseperu.components.MyQuestionsHeaderInfoDialogContent
import com.sunday.kmplicenseperu.components.MySubtitle
import com.sunday.kmplicenseperu.components.MyTitle
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.components.NotFound
import com.sunday.kmplicenseperu.components.PageStructure
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.models.Question
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.SurfaceAmber
import com.sunday.kmplicenseperu.models.Theme.SurfaceAmberDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceBlue
import com.sunday.kmplicenseperu.models.Theme.SurfaceBlueDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceBrand
import com.sunday.kmplicenseperu.models.Theme.SurfaceBrandDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceGreen
import com.sunday.kmplicenseperu.models.Theme.SurfaceGreenDark
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimaryDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceRed
import com.sunday.kmplicenseperu.models.Theme.SurfaceRedDark
import com.sunday.kmplicenseperu.models.Theme.TextPrimary
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.navigation.Routes.QUESTION_ROUTE
import com.sunday.kmplicenseperu.navigation.Screen
import com.sunday.kmplicenseperu.pages.home.HomeState
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.Constants.DARK_MODE
import com.sunday.kmplicenseperu.util.ExamStudy
import com.sunday.kmplicenseperu.util.Materia
import com.sunday.kmplicenseperu.util.dialogPopUp
import com.sunday.kmplicenseperu.util.questionArrowKeysHandler
import com.sunday.kmplicenseperu.util.questionEscEnterKeysHandler
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get
import org.w3c.dom.set
import kotlin.time.Duration.Companion.seconds

//@Page(routeOverride = "${QUESTION_ROUTE}/{examStudy}/{license}")
@Page(routeOverride = QUESTION_ROUTE)
@Composable
fun QuestionsPage() {

    val questionsVM = remember { QuestionsVM() }
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    var darkMode by remember { mutableStateOf(localStorage[DARK_MODE].toBoolean()) }

    // if it comes from: right-side-column
    if (questionsVM.questionState.comesFromHome == false) {
        questionsVM.updateQuestionsState()
    }

    // if url-manually-changed (params evaluation)
//    if (questionsVM.questionState.comesFromHome == null) {
//        var examStudyParam: ExamStudy? = null
//        var licenseParam: License? = null
//        try {
//            examStudyParam = context.route.params.getValue("examStudy")
//                .takeIf { it == "simulacro" || it == "balotario" }
//                ?.take(1)
//                ?.map { if (it.toString() == "s") ExamStudy.EXAM else ExamStudy.STUDY }
//                ?.first()
//            licenseParam = context.route.params.getValue("license").split("-")
//                .takeIf { it[0] == ("licencia") && it.size == 2 }
//                ?.filter { it != "licencia" }
//                ?.map { License.valueOf(it) }
//                ?.first()
//        } catch (e: Exception) { print(e); context.router.navigateTo(Screen.Home.route) }
//
//        if (examStudyParam == null || licenseParam == null) {
//            context.router.navigateTo(Screen.Home.route)
//        } else {
//            HomeState.examStudy = examStudyParam
//            HomeState.license = licenseParam
//            if(examStudyParam == ExamStudy.STUDY) {
//                HomeState.materia = Materia.GENERAL
//                HomeState.range = HomeState.license.rbRangeGeneralList.first()
//            }
//            questionsVM.updateQuestionsState()
//        }
//    }

    // if url-manually-changed (params evaluation)
    if (questionsVM.questionState.comesFromHome == null) {
        context.router.navigateTo(Screen.Home.route)
    }

    window.onkeydown = {
        if (!questionsVM.anyDialogDisplayed())
            questionArrowKeysHandler(it, questionsVM)
        else
            questionEscEnterKeysHandler(it, questionsVM)
    }

    PageStructure(
        darkMode = darkMode,
        useHeaderInfo = true,
        myVM = questionsVM,
        onHeaderInfo = {
            questionsVM.updateShowHeaderInfoDialog(true)
            questionsVM.onInfo(pauseTimer = true)
        },
        onSwitchTheme = {
            darkMode = !darkMode
            localStorage[DARK_MODE] = darkMode.toString()
        },
        headerTitle = {
            QuestionsPageTitle(
                breakpoint = breakpoint,
                questionsVM = questionsVM,
                darkMode = darkMode
            )
        },
        mainColumn = {
            QuestionsMainColumn(
                modifier = Modifier.fillMaxWidth(),
                breakpoint = breakpoint,
                darkMode = darkMode,
                questionsVM = questionsVM,
            )
        },
        dialogs = {
            QuestionsDialogs(questionsVM = questionsVM, darkMode = darkMode)
        }
    )
}

@Composable
fun QuestionsMainColumn(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint,
    darkMode: Boolean,
    questionsVM: QuestionsVM,
) {
    Column(
        modifier = modifier
    ) {
        QuestionsBodySection(
            breakpoint = breakpoint,
            darkMode = darkMode,
            questionsVM = questionsVM,
        )
        // TODO .. add more components to MAIN-column
    }
}

@Composable
fun QuestionsBodySection(
    breakpoint: Breakpoint,
    darkMode: Boolean,
    questionsVM: QuestionsVM,
) {
    val surfacePrimary = if (!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(surfacePrimary)
                .maxWidth(Constants.PAGE_WIDTH.px),
            contentAlignment = Alignment.Center
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(surfacePrimary),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                MyVerticalSpacer(20.px)
                SingleQuestion(
                    breakpoint = breakpoint,
                    questionsVM = questionsVM,
                    questions = questionsVM.questions,
                    darkMode = darkMode,
                )
                MyVerticalSpacer(10.px)

                ButtonGroup(
                    breakpoint = breakpoint,
                    questionsVM = questionsVM,
                    darkMode = darkMode,
                    onPrevious = { questionsVM.onPrevButton() },
                    onPause = { questionsVM.onPauseButton() },
                    onSolution = { questionsVM.onSolutionButton() },
                    onFinish = { questionsVM.onFinishButton() },
                    onProgress = { questionsVM.onProgressButton() },
                    onNext = { questionsVM.onNextButton() },
                )
                MyVerticalSpacer(20.px)
            }
        }
    }
}

@Composable
fun QuestionsDialogs(
    questionsVM: QuestionsVM,
    darkMode: Boolean,
) {
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    /* Header Info Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(questionsVM.questionState.showHeaderInfoDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyQuestionsHeaderInfoDialogContent(
            questionsVM = questionsVM,
            darkMode = darkMode,
            onSubmit = {
                questionsVM.updateShowHeaderInfoDialog(false)
                questionsVM.onInfo(pauseTimer = false)
            },
        )
    }
    /* Pause Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(questionsVM.questionState.showPauseDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyPauseDialogContent(
            questionsVM = questionsVM,
            darkMode = darkMode,
            onSubmit = {
                questionsVM.updateShowPauseDialog(false)
                questionsVM.onInfo(pauseTimer = false)
            },
        )
    }
    /* Finish Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(questionsVM.questionState.showFinishDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyFinishDialogContent(
            questionsVM = questionsVM,
            timer = questionsVM.formatTimer(questionsVM.timer.ticks),
            darkMode = darkMode,
            onSubmit = {
                questionsVM.updateShowFinishDialog(false)
                context.router.navigateTo(Screen.Home.route)
            },
        )
    }
    /* Progress Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(questionsVM.questionState.showProgressDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyProgressDialogContent(
            questionsVM = questionsVM,
            darkMode = darkMode,
            onDismiss = { questionsVM.updateShowProgressDialog(false) },
            onSubmit = {
                questionsVM.updateShowProgressDialog(false)
                context.router.navigateTo(Screen.Home.route)
            },
        )
    }
    /* 35 min Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(questionsVM.questionState.show35minDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        My35MinDialogContent(
            questionsVM = questionsVM,
            darkMode = darkMode,
            onSubmit = {
                questionsVM.updateShow35minDialog(false)
                questionsVM.onInfo(pauseTimer = false)
            },
        )
    }
    /* Change Range Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(questionsVM.questionState.showChangeRangeDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyChangeRangeDialogContent(
            questionsVM = questionsVM,
            darkMode = darkMode,
        )
    }
}

@Composable
fun Timing(
    color: CSSColorValue,
    questionsVM: QuestionsVM,
) {
    var ticks = questionsVM.timer.ticks
    val pauseTimer = questionsVM.timer.pauseTimer
    val formatTimer = questionsVM.formatTimer(ticks)

    LaunchedEffect(pauseTimer) {
        while (!pauseTimer) {
            questionsVM.increaseTimer(ticks++)
            delay(1.seconds)
        }
    }

    LaunchedEffect(formatTimer) {
        if (formatTimer == "35:00") {
            questionsVM.updateShow35minDialog(true)
            questionsVM.onInfo(pauseTimer = true)
        }
    }
    LaunchedEffect(formatTimer) {
        if (formatTimer == "40:00") {
            questionsVM.onFinishButton()
        }
    }
    Row(verticalAlignment = Alignment.CenterVertically) {
        FaClock(
            modifier = Modifier
                .color(color),
            size = IconSize.SM
        )
        MyHorizontalSpacer(5.px)
        MyParagraph(
            text = formatTimer,
            color = color
        )
    }
}

@Composable
fun SingleQuestion(
    breakpoint: Breakpoint,
    questionsVM: QuestionsVM,
    questions: List<Question>,
    darkMode: Boolean,
) {
    val surfacePrimary = if (!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val textPrimary = if (!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        val width = 90.percent

        // Question-title (exam or study)
        if (breakpoint <= Breakpoint.SM) {
            QuestionsPageTitle(
                breakpoint = breakpoint,
                questionsVM = questionsVM,
                darkMode = darkMode,
            )
            MyVerticalSpacer(20.px)
        }
        // Questions-nav
        QuestionsNavLegend(
            modifier = Modifier.fillMaxWidth(width),
            questionsVM = questionsVM,
            darkMode = darkMode,
        )
        MyVerticalSpacer(4.px)
        Row(
            modifier = Modifier
                .fillMaxWidth(width)
                .maxHeight(90.px)
                .overflow(Overflow.Auto)
                .scrollBehavior(ScrollBehavior.Auto)
                .border(width = 1.px, style = LineStyle.Dotted, color = textPrimary)
                .borderRadius(r = 2.px),
            horizontalArrangement = Arrangement.Center
        ) {
            QuestionsNav(
                questionsVM = questionsVM,
                breakpoint = breakpoint,
                questions = questions,
                darkMode = darkMode,
            )
        }

        MyVerticalSpacer(20.px)
        // Question-text
        MySubtitle(
            modifier = Modifier.fillMaxWidth(width),
            text = "${questionsVM.questionState.questionNumber}) ${questions[questionsVM.questionState.index].question}",
            color = textPrimary
        )
        MyVerticalSpacer(20.px)

        /* Question image */
        if (questions[questionsVM.questionState.index].image != "") {
            MyImage(
                image = questions[questionsVM.questionState.index].image
            )
        }
        MyVerticalSpacer(10.px)

        /* Question options */
        questions[questionsVM.questionState.index].options.forEach { option ->
            MyQuestionOption(
                breakpoint = breakpoint,
                questionsVM = questionsVM,
                darkMode = darkMode,
                option = option,
                optionSelected = questionsVM.questionState.optionSelected,
                optionPreviouslySelected = questionsVM.userOptions.toList()[questionsVM.questionState.index],
                realResult = questions[questionsVM.questionState.index].result,
                backgroundColor = surfacePrimary,
                onClick = {
                    questionsVM.questionOptionClick(
                        optionSelected = option.first().toString(),
                        index = questionsVM.questionState.index
                    )
                }
            )
            MyVerticalSpacer(10.px)
        }
    }
}

@Composable
fun QuestionsNav(
    questionsVM: QuestionsVM,
    questions: List<Question>,
    breakpoint: Breakpoint,
    darkMode: Boolean,
) {
    val surfacePrimary = if (!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val textPrimary = if (!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val surfaceBlue = if (!darkMode) SurfaceBlue.color.rgb
    else SurfaceBlueDark.color.rgb
    val surfaceGreen = if (!darkMode) SurfaceGreen.color.rgb
    else SurfaceGreenDark.color.rgb
    val surfaceAmber = if (!darkMode) SurfaceAmber.color.rgb
    else SurfaceAmberDark.color.rgb
    val surfaceRed = if (!darkMode) SurfaceRed.color.rgb
    else SurfaceRedDark.color.rgb

    val range = if (questionsVM.questionState.currentExamStudy == ExamStudy.EXAM) {
        1..40
    } else {
        questionsVM.questionState.currentRange!!.split("-")[0].toInt()..questionsVM.questionState.currentRange!!.split(
            "-"
        )[1].toInt()
    }
    // to display the number of columns
    val division = when (breakpoint) {
        Breakpoint.ZERO -> 38
        Breakpoint.SM -> 40
        Breakpoint.MD -> 55
        Breakpoint.LG, Breakpoint.XL -> 70
    }

    var time by remember { mutableStateOf(0) }
    var screenWidth by remember { mutableStateOf(window.innerWidth) }

    fun report() {
        screenWidth = window.innerWidth
    }
    window.onresize = { report() }

    LaunchedEffect(screenWidth / division) {
        time = screenWidth / division
    }
    // end: to display the number of columns

    SimpleGrid(
        numColumns = numColumns(base = time) // dynamic columns number
    ) {
        range.forEachIndexed { index, number ->

            val isCurrentQuestion = questionsVM.questionState.questionNumber == number

            val boxBorderWidth = if (isCurrentQuestion) 2.px else 1.px

            val backgroundColor =
                if (questionsVM.questionState.isSolutionEnabled) {
                    if (isCurrentQuestion) surfaceBlue
                    else if (questionsVM.userOptions[index] != "") {
                        if (questionsVM.userOptions[index] == questions[index].result) surfaceGreen
                        else surfaceRed
                    } else surfacePrimary
                } else {
                    if (isCurrentQuestion) surfaceBlue
                    else if (questionsVM.userOptions[index] != "") surfaceAmber
                    else surfacePrimary
                }

            Box(
                modifier = Modifier
                    .height(20.px)
                    .width(25.px)
                    .margin(4.px)
                    .border(width = boxBorderWidth, style = LineStyle.Solid, color = textPrimary)
                    .borderRadius(r = 2.px)
                    .backgroundColor(backgroundColor)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        questionsVM.jumpToIndex(
                            index = index,
                            questionNumber = number
                        )
                    },
                contentAlignment = Alignment.Center
            ) {
                MyCaption(text = number.toString(), color = textPrimary)
            }
        }
    }
}

@Composable
fun QuestionsNavLegend(
    modifier: Modifier,
    questionsVM: QuestionsVM,
    darkMode: Boolean,
) {
    val textPrimary = if (!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val surfaceBlue = if (!darkMode) SurfaceBlue.color.rgb
    else SurfaceBlueDark.color.rgb
    val surfaceGreen = if (!darkMode) SurfaceGreen.color.rgb
    else SurfaceGreenDark.color.rgb
    val surfaceAmber = if (!darkMode) SurfaceAmber.color.rgb
    else SurfaceAmberDark.color.rgb
    val surfaceRed = if (!darkMode) SurfaceRed.color.rgb
    else SurfaceRedDark.color.rgb

    Row(modifier = modifier) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Box(modifier = Modifier.size(12.px).backgroundColor(surfaceBlue))
            MyHorizontalSpacer(4.px)
            MyCaption(text = "Actual", color = textPrimary)
        }
        MyHorizontalSpacer(15.px)
        if (!questionsVM.questionState.isSolutionEnabled) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Box(modifier = Modifier.size(12.px).backgroundColor(surfaceAmber))
                MyHorizontalSpacer(4.px)
                MyCaption(text = "Contestadas", color = textPrimary)
            }
            MyHorizontalSpacer(15.px)
        } else {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Box(modifier = Modifier.size(12.px).backgroundColor(surfaceGreen))
                MyHorizontalSpacer(4.px)
                MyCaption(text = "Correctas", color = textPrimary)
            }
            MyHorizontalSpacer(15.px)
            Row(verticalAlignment = Alignment.CenterVertically) {
                Box(modifier = Modifier.size(12.px).backgroundColor(surfaceRed))
                MyHorizontalSpacer(4.px)
                MyCaption(text = "Incorrectas", color = textPrimary)
            }
        }
        Spacer()
        if(questionsVM.questionState.currentExamStudy == ExamStudy.STUDY)
            MyCaption(
                text = "Cambiar rango",
                color = SurfaceBrand.color.rgb,
                modifier = Modifier
                    .onClick { questionsVM.updateShowChangeRangeDialog(true) }
                    .cursor(Cursor.Pointer)
            )
    }
}

@Composable
fun ButtonGroup(
    breakpoint: Breakpoint,
    questionsVM: QuestionsVM,
    darkMode: Boolean,
    onPrevious: () -> Unit,
    onPause: () -> Unit,
    onSolution: () -> Unit,
    onFinish: () -> Unit,
    onProgress: () -> Unit,
    onNext: () -> Unit,
) {
    val width = when (breakpoint) {
        Breakpoint.ZERO, Breakpoint.SM -> 95.percent
        Breakpoint.MD -> 80.percent
        Breakpoint.LG, Breakpoint.XL -> 70.percent
    }
    Row(
        modifier = Modifier.fillMaxWidth(width),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        CustomButton(
            breakpoint = breakpoint,
            darkMode = darkMode,
            buttonText = "Anterior",
            disabled = questionsVM.isFirstQuestion(),
            onClick = onPrevious
        )
        if (questionsVM.questionState.currentExamStudy == ExamStudy.EXAM)
            CustomButton(
                breakpoint = breakpoint,
                darkMode = darkMode,
                buttonText = "Pausar",
                onClick = onPause
            )
        else
            CustomButton(
                breakpoint = breakpoint,
                darkMode = darkMode,
                buttonText = "Solución",
                different = questionsVM.questionState.isSolutionEnabled,
                onClick = onSolution
            )
        if (questionsVM.questionState.currentExamStudy == ExamStudy.EXAM)
            CustomButton(
                breakpoint = breakpoint,
                darkMode = darkMode,
                buttonText = "Terminar",
                onClick = onFinish
            )
        else
            CustomButton(
                breakpoint = breakpoint,
                darkMode = darkMode,
                buttonText = "Progreso",
                onClick = onProgress
            )
        CustomButton(
            breakpoint = breakpoint,
            darkMode = darkMode,
            buttonText = "Siguiente",
            disabled = questionsVM.isLastQuestion(),
            onClick = onNext
        )
    }
}

@Composable
fun QuestionsPageTitle(
    breakpoint: Breakpoint,
    questionsVM: QuestionsVM,
    darkMode: Boolean,
) {
    val textPrimary = if (!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val width = when (breakpoint) {
        Breakpoint.ZERO, Breakpoint.SM -> 90.percent
        Breakpoint.MD -> 80.percent
        Breakpoint.LG, Breakpoint.XL -> 70.percent
    }

    val textColor = if (breakpoint <= Breakpoint.SM) textPrimary else SurfacePrimary.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(width),
        contentAlignment = Alignment.Center
    ) {
        val materia = when (breakpoint) {
            Breakpoint.ZERO -> questionsVM.questionState.currentMateria.type.replace("erias", ".")
            else -> questionsVM.questionState.currentMateria.type
        }

        if (questionsVM.questionState.currentExamStudy == ExamStudy.EXAM) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                MyTitle(
                    text = "Examen - ${questionsVM.questionState.currentLicense.name}",
                    color = textColor
                )
                Spacer()
                Timing(questionsVM = questionsVM, color = textColor)
            }
        } else {
            MyTitle(
                text = "Estudio - ${questionsVM.questionState.currentLicense.name} - $materia - ${
                    questionsVM.questionState.currentRange!!.replace(
                        "-",
                        "/"
                    )
                }",
                color = textColor,
            )
        }
    }
}

@Composable
fun MyImage(
    image: String,
) {
    val base = 100
    val height = (getTimesHeight(image) * base).px
    val width = (getTimesWidth(image) * base).px
    Image(
        modifier = Modifier
            .height(height)
            .width(width),
        src = "/questions/$image.webp",
        description = "License Image"
    )
}

@Composable
fun getTimesWidth(image: String): Int {
    return if (image.contains("www"))
        image.substringAfter("www").first().digitToInt()
    else
        1
}

@Composable
fun getTimesHeight(image: String): Int {
    return if (image.contains("hhh"))
        image.substringAfter("hhh").first().digitToInt()
    else
        1
}

//@Page(routeOverride = "/preguntas")
//@Composable
//fun QuestionRedirecting() {
//    NotFound()
//}