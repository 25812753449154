package com.sunday.kmplicenseperu.styles

import com.sunday.kmplicenseperu.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val MainButtonStyle by ComponentStyle {
    base {
        Modifier
            .width(82.percent)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
    hover {
        Modifier.width(90.percent)
    }
}

val UnderlineStyle by ComponentStyle {
    base {
        Modifier
            .borderBottom(width = 2.px, style = LineStyle.Solid, color = Colors.Transparent)
            .transition(CSSTransition(property = "borderBottom", duration = 400.ms))
    }
    hover {
        Modifier.borderBottom(width = 2.px, style = LineStyle.Solid, color = Theme.BorderBrand.color.rgb)
    }
}