package com.sunday.kmplicenseperu.pages.legal

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.components.MyLinkToNewTab
import com.sunday.kmplicenseperu.components.MyParagraph
import com.sunday.kmplicenseperu.components.MySubtitle
import com.sunday.kmplicenseperu.components.MyTitle
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.components.PageStructure
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.navigation.Routes
import com.sunday.kmplicenseperu.util.Constants
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get

@Page(routeOverride = Routes.TERMS_OF_USE_ROUTE)
@Composable
fun TermsOfUse() {
    val breakpoint = rememberBreakpoint()
    var darkMode by remember { mutableStateOf(localStorage[Constants.DARK_MODE].toBoolean()) }

    PageStructure(
        darkMode = darkMode,
        useHeaderInfo = false,
        onSwitchTheme = { darkMode = !darkMode },
        headerTitle = {
            TermsOfUsePageTitle(
                breakpoint = breakpoint,
                darkMode = darkMode
            )
        },
        mainColumn = {
            TermsOfUseMainColumn(
                darkMode = darkMode,
            )
        }
    )
}

@Composable
fun TermsOfUseMainColumn(
    darkMode: Boolean,
) {
    val surfacePrimary = if (!darkMode) Theme.SurfacePrimary.color.rgb
    else Theme.SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb
    val textBlue = if(!darkMode) Theme.TextBlue.color.rgb
    else Theme.TextBlueDark.color.rgb

    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(90.percent)
                .backgroundColor(surfacePrimary),
        ) {
            if(breakpoint <= Breakpoint.SM) {
                MyVerticalSpacer(20.px)
                TermsOfUsePageTitle(
                    breakpoint = breakpoint,
                    darkMode = darkMode,
                )
            }

            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Información inicial",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Al utilizar este sitio web estos términos se aplicarán automáticamente a usted; por lo tanto, debe asegurarse de leerlos detenidamente antes de utilizar la aplicación. No está permitido intentar extraer el código fuente de este sitio web y tampoco debe intentar traducirlo a otros idiomas ni crear versiones derivadas. No está permitido copiar ni modificar ninguna parte de la aplicación ni nuestras marcas comerciales de ninguna manera. Los creadores de este sitio web tienen los derechos de autor y otros de propiedad intelectual.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Sobre el sitio web",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Nos comprometemos a garantizar que este sitio web sea lo más útil y eficiente posible. Por ese motivo, nos reservamos el derecho de realizar cambios, en cualquier momento y por cualquier motivo. Es importante tener en cuenta que, aunque nos esforzamos por garantizar que esté actualizada y sea correcta en todo momento; no aceptamos ninguna responsabilidad por cualquier pérdida, directa o indirecta, que usted experimente como resultado de confiar exclusivamente en este sitio web.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Cambios a los Términos de Uso",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Nosotros podemos actualizar nuestros Términos y condiciones de vez en cuando. Por lo tanto, se recomienda revisar esta página periódicamente para detectar cualquier cambio.",
                color = textPrimary
            )
            MyParagraph(
                text = "Estos Términos de Uso entran en vigor a partir del 01-02-2024.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)
        }
    }
}

@Composable
fun TermsOfUsePageTitle(
    breakpoint: Breakpoint,
    darkMode: Boolean,
) {
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb
    val textColor = if(breakpoint <= Breakpoint.SM) textPrimary else Theme.SurfacePrimary.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = "Términos de Uso",
            color = textColor,
        )
    }
}