package com.sunday.kmplicenseperu.pages.home

import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.util.ExamStudy
import com.sunday.kmplicenseperu.util.Materia

data class HomeState(
    var licenseSelected: License = License.A1,
    var isExamSelected: Boolean = true,
    var isStudySelected: Boolean = false,
    var rangeSelected: String? = null,
    var materiaSelected: Materia = Materia.GENERAL,
    var showStudyRange: Boolean = false,

    var showLicenseDialog: Boolean = false,
    var showHeaderInfoDialog: Boolean = false,

    var isEscapePressed: Boolean = false,
    var isEnterPressed: Boolean = false,

) {
    companion object {
        var license: License = License.A1
        var examStudy = ExamStudy.EXAM
        var range: String? = ""
        var materia = Materia.GENERAL
        var comesFromHome: Boolean? = null
    }
}