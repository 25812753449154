package com.sunday.kmplicenseperu.local.questions

import com.sunday.kmplicenseperu.models.Question

object B2CSpecificQuestions {

    fun getB2CSpecificQuestions() = listOf(

        Question(
            question = "¿Es obligatorio que un vehículo de categoría L5 cuente con luz de posición delantera y luz de posición posterior?",
            options = listOf(
                "a) No, es opcional.",
                "b) Sí.",
                "c) Solo es obligatoria a la Luz de posición posterior.",
                "d) No se encuentra regulado si es obligatorio o opcional.",
            ),
            result = "b"
        ),

        Question(
            question = "¿A qué distancia como mínimo debe ser audible el sonido de la bocina para los vehículos de categoría L?",
            options = listOf(
                "a) 100 metros. ",
                "b) 50 metros. ",
                "c) 30 metros.",
                "d) No existe mínimo siempre que estén dentro de los límites máximos permisibles para emisiones sonoras.",
            ),
            result = "b"
        ),

        Question(
            question = "Los vehículos menores destinados al servicio de transporte público especial de pasajeros deben cumplir con lo siguiente:",
            options = listOf(
                "a) Ser de la categoría L5, contar con parachoques posterior y láminas retroflectivas.",
                "b) Ser de la categoría L3 y contar con parachoque posterior.",
                "c) Ser de la categoría L y contar con láminas retroflectivas.",
                "d) Solo ser de la categoría L5.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál definición corresponde a un vehículo de categoría L5?",
            options = listOf(
                "a) Vehículos de tres ruedas asimétricas al eje longitudinal del vehículo, de más de 50 cm3 o una velocidad mayor de 50 km/h.",
                "b) Vehículos de tres ruedas, de hasta 50 cm3 y velocidad máxima de 50 km/h.",
                "c) Vehículos de dos ruedas, de más de 50 cm3 o velocidad mayor a 50 km/h.",
                "d) Vehículos de tres ruedas simétricas al eje longitudinal del vehículo, de más de 50 cm3 o velocidad mayor a 50 km/h y cuyo peso bruto vehicular no exceda de una tonelada.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Qué color está permitido para la luz alta adicional en un vehículo de categoría L?",
            options = listOf(
                "a) Solo Blanco.",
                "b) Blanco o Amarillo.",
                "c) Blanco, Amarillo o Azul.",
                "d) Solo Amarillo.",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué color está permitido para la luz direccional posterior en un vehículo de categoría L5?",
            options = listOf(
                "a) Amarillo o Rojo.",
                "b) Amarillo o Naranja.",
                "c) Solo Amarillo.",
                "d) Blanco, Amarillo o Naranja.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Es obligatoria la luz de placa posterior para los vehículos de categoría L?",
            options = listOf(
                "a) Sí. ",
                "b) No, es opcional.",
                "c) No aplica para vehículos menores.",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Es obligatorio contar con el sistema de freno de estacionamiento para los vehículos de categoría L5?",
            options = listOf(
                "a) Sí. ",
                "b) No, es opcional.",
                "c) No aplica para vehículos menores.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "Cuáles son los instrumentos e indicadores de luz que deben contar vehículos de categoría L de forma obligatoria?",
            options = listOf(
                "a) Luz de testigo de luz alta, luz de testigo de direccionales, velocímetro y odómetro e indicador de nivel de combustible.",
                "b) Luz de testigo de direccionales, velocímetro, odómetro y tacógrafo.",
                "c) Luz de testigo de luz alta, luz de testigo de direccionales, velocímetro y odómetro.",
                "d) Indicador de nivel de combustible, luz de testigo de luz alta y velocímetro.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Dónde debe ubicarse el depósito de combustible para un vehículo de categoría L2 y L5?",
            options = listOf(
                "a) De acuerdo al Diseño del fabricante. Si el vehículo no es completamente cerrado podrá ubicarse como en los vehículos de las categorías L1 y L3.",
                "b) En la parte externa del vehículo. Si el vehículo no es completamente cerrado podrá ubicarse como en los vehículos de las categorías L1 y L3.",
                "c) En la parte externa de la cabina.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál es la ubicación que debe tener el tubo de escape de un vehículo categoría L5?",
            options = listOf(
                "a) La salida del tubo de escape debe descargar las emisiones del motor hacia atrás del vehículo.",
                "b) La salida del tubo de escape debe estar ubicada a 300 mm como máximo del extremo posterior del compartimiento de pasajeros y descargar las emisiones del motor hacia atrás del vehículo.",
                "c) El extremo del tubo de escape no debe sobresalir del vehículo y debe descargar las emisiones del motor por la parte posterior o lateral izquierda del vehículo.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "Las láminas retroreflectivas deben estar compuestas por: ",
            options = listOf(
                "a) Blanca y Anaranjada.",
                "b) Amarilla y Roja.",
                "c) Blanca y Amarilla.",
                "d) Blanca y Roja.",
            ),
            result = "d"
        ),

        Question(
            question = "Las láminas deben colocarse a no menos de ____ mm y no más de ____ m sobre la superficie de la carretera.",
            options = listOf(
                "a) 300 y 1,60",
                "b) 200 y 1,60",
                "c) 300 y 1,40",
                "d) 350 y 1,50",
            ),
            result = "a"
        ),

        Question(
            question = "¿Qué porcentaje como máximo de oscurecimiento debe tener los parabrisas de vidrio de seguridad para los vehículos menores?",
            options = listOf(
                "a) 40% ",
                "b) 20%",
                "c) 30%",
                "d) 50%",
            ),
            result = "c"
        ),

        Question(
            question = "La licencia de conducir clase B categoría II C permite conducir vehículos señalados en la categoría:",
            options = listOf(
                "a) B II a ",
                "b) B II b ",
                "c) A I ",
                "d) Sólo a) y b) ",
            ),
            result = "d"
        ),

        Question(
            question = "La licencia de conducir clase B categoría II B autoriza a conducir vehículos de la categoría: ",
            options = listOf(
                "a) L3 ",
                "b) L4",
                "c) L5 ",
                "d) Sólo a) y b) ",
            ),
            result = "d"
        ),

        Question(
            question = "La licencia de conducir Clase B categoría II A, autoriza conducir vehículos de la categoría: ",
            options = listOf(
                "a) L1",
                "b) L2",
                "c) L5 ",
                "d) Sólo a) y b) ",
            ),
            result = "d"
        ),

        Question(
            question = "Las licencias de conducir de Clase B tienen una vigencia de ______. ",
            options = listOf(
                "a) 3 años ",
                "b) 5 años ",
                "c) 8 años ",
                "d) 2 años",
            ),
            result = "b"
        ),

        Question(
            question = "Es una licencia de conducir que se obtiene por otorgamiento directo:",
            options = listOf(
                "a) Clase A Categoría II A ",
                "b) Clase A Categoría III C ",
                "c) Clase A Categoría II B ",
                "d) Clase B Categoría I ",
            ),
            result = "d"
        ),

        Question(
            question = "Es una licencia de conducir que se obtiene por otorgamiento directo: ",
            options = listOf(
                "a) Clase A Categoría II A ",
                "b) Clase A Categoría III C ",
                "c) Clase A Categoría II B ",
                "d) Clase B Categoría II C ",
            ),
            result = "d"
        ),

        Question(
            question = "Es una licencia de conducir que se obtiene por otorgamiento directo:",
            options = listOf(
                "a) Clase A Categoría II A ",
                "b) Clase A Categoría III C ",
                "c) Clase A Categoría II B ",
                "d) Clase B Categoría II B",
            ),
            result = "d"
        ),

        Question(
            question = "Es un requisito para la obtención de una licencia de conducir B-II C:",
            options = listOf(
                "a) Presentar una Constancia de Finalización del Programa de Formación de Conductores - COFIPRO expedida por una Escuela de Conductores.",
                "b) Presentar constancia de estudios que acredite que el postulante culminó la secundaria.",
                "c) Presentar una declaración jurada de no tener antecedentes penales ni judiciales.",
                "d) Presentar certificado de no tener antecedentes penales ni judiciales.",
            ),
            result = "a"
        ),

        Question(
            question = "La licencia de conducir clase B categoría II C permite conducir vehículos de las siguientes categorías ______. ",
            options = listOf(
                "a) L1 y L2 ",
                "b) L3 y L4 ",
                "c) L5 ",
                "d) Todas las anteriores.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Cuál es el seguro obligatorio para prestar el servicio de transporte en vehículos menores? ",
            options = listOf(
                "a) AFOCAT o SOAT. ",
                "b) Seguro Complementario de Riesgo.",
                "c) Póliza de Responsabilidad Civil frente a Terceros. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál es el organismo competente para autorizar y fiscalizar el servicio?",
            options = listOf(
                "a) Gobiernos Regionales.",
                "b) Ministerio de Transportes y Comunicaciones.",
                "c) Municipalidades Distritales. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "c"
        ),

        Question(
            question = "¿En qué lugares se encuentra permitida la prestación del servicio de vehículos menores? ",
            options = listOf(
                "a) Vías arteriales.",
                "b) En las vías urbanas que determine la Municipalidad Distrital competente, donde no exista o sea deficiente el servicio de transporte público urbano masivo.",
                "c) En las vías alimentadoras de rutas consideradas en el plan Regulador de cada Municipalidad Provincial, donde no exista o sea deficiente el servicio de transporte público urbano masivo.",
                "d) b y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "La velocidad máxima de circulación de un vehículo menor empleado para el Servicio Especial no excederá los:",
            options = listOf(
                "a) 30 kilómetros por hora.",
                "b) 50 kilómetros por hora.",
                "c) 70 kilómetros por hora. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "Periodicidad de la capacitación en materia de seguridad vial:",
            options = listOf(
                "a) Cada dos años.",
                "b) Anualmente.",
                "c) Cada tres años. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "En caso de accidentes de tránsito cómo debe proceder un transportista autorizado:",
            options = listOf(
                "a) Seguir circulando a fin de evitar congestión vehicular.",
                "b) Seguir circulando y advertir a las autoridades del hecho con posterioridad al evento.",
                "c) Comunicar de inmediato a las autoridades competentes, efectuando las acciones necesarias para que la(s) víctima(s) reciba(n) una atención adecuada y oportuna.",
                "d) Comunicar de inmediato a los Bomberos, realizando las acciones necesarias para evitar congestión vehicular.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Cuáles son las consecuencias para prestar el servicio sin contar con Permiso?",
            options = listOf(
                "a) Amonestación verbal.",
                "b) Únicamente multa.",
                "c) Multa fijada por la autoridad municipal.",
                "d) Retención e internamiento del vehículo, siendo liberado previo pago de la multa fijada por autoridad municipal.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Cuál es la categoría del vehículo exigida para prestar el servicio? ",
            options = listOf(
                "a) L3 ",
                "b) L2 ",
                "c) L5 ",
                "d) b y c son correctas. ",
            ),
            result = "c"
        ),

        Question(
            question = "¿Cuál no es exigible para prestar el servicio?",
            options = listOf(
                "a) Parachoque.",
                "b) Láminas retroreflectivas.",
                "c) Protectores laterales.",
                "d) a y b son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Es una obligación de un conductor de un vehículo que transporta carga:",
            options = listOf(
                "a) Que la carga solo se encuentre debidamente acomodada.",
                "b) Que la carga no sobrepase las dimensiones de la carrocería.",
                "c) Que la carga esté adecuadamente acomodada, sujeta y cubierta de forma tal que no ponga en peligro a las personas u otros vehículos usuarios de la vía.",
                "d) b y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Se encuentra prohibido que el conductor de un vehículo menor automotor o no motorizado:",
            options = listOf(
                "a) Se sujete a otro vehículo que transite por la vía pública.",
                "b) La exhibición de Placa Única de Rodaje.",
                "c) Conducir por las vías locales autorizadas por la autoridad competente.",
                "d) a y c son correctas.",
            ),
            result = "a"
        ),

        Question(
            question = "Son obligaciones de un conductor y acompañante de una motocicleta o cualquier otro tipo de ciclomotor o de una bicicleta.",
            options = listOf(
                "a) Usar casco protector autorizado.",
                "b) Usar anteojos protectores cuando el casco no tenga protector cortaviento o el vehículo carezca de parabrisas.",
                "c) Uso de GPS.",
                "d) a y b son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Cuál es el carril en el cual los vehículos menores deben circular?",
            options = listOf(
                "a) Carril de la derecha, de manera ordenada y sin hacer maniobras que pongan en riesgo la vida de los ocupantes del vehículo y de la de terceros.",
                "b) Carril central, de manera ordenada y sin hacer maniobras que pongan en riesgo la vida de los ocupantes del vehículo y de la de terceros.",
                "c) Carril izquierdo, de manera ordenada y sin hacer maniobras que pongan en riesgo la vida de los ocupantes del vehículo y de la de terceros.",
                "d) Por el carril donde exista menos congestión vehicular, de manera ordenada y sin hacer maniobras que pongan en riesgo la vida de los ocupantes del vehículo y de la de terceros.",
            ),
            result = "a"
        ),

        Question(
            question = "Los vehículos menores, motorizados o no motorizados, que presten servicio de transporte público especial de pasajeros, pueden circular por las siguientes vías:",
            options = listOf(
                "a) Las que señalen las autoridades competentes.",
                "b) Vías expresas o pasos a desnivel.",
                "c) La red vial nacional, regional o departamental.",
                "d) La red vial nacional, regional o departamental siempre que la autoridad competente lo permita.",
            ),
            result = "a"
        ),

        Question(
            question = "Los vehículos automotores de la categoría L5 deberán usar alternativamente:",
            options = listOf(
                "a) Cinturones de seguridad para los asientos de los pasajeros.",
                "b) Uno o más soportes fijados a su estructura, que permitan a los pasajeros asirse de ellos mientras son transportados.",
                "c) Asientos acolchados.",
                "d) Puertas fijadas a su estructura para seguridad de los pasajeros.",
            ),
            result = "b"
        ),

        Question(
            question = "Se encuentra permitido que el conductor de un vehículo menor automotor o no motorizado ______. ",
            options = listOf(
                "a) Lleve carga o pasajeros que dificulten su visibilidad, equilibrio y adecuada conducción",
                "b) Transportar la cantidad de personas que ocupen asientos especialmente acondicionados para tal objeto.",
                "c) Circular por las vías autorizadas por la autoridad competente.",
                "d) b y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Son obligaciones de un conductor de un vehículo menor autorizado para transporte carga:",
            options = listOf(
                "a) Asegurarse que la carga no sea arrastrada.",
                "b) Asegurarse que la carga no comprometa la estabilidad y conducción del vehículo.",
                "c) Asegurarse que la carga no oculte las luces, dispositivos reflectivos o la Placa Única Nacional de Rodaje y no afecte la visibilidad.",
                "d) Todas son correctas.",
            ),
            result = "d"
        ),

        )
}