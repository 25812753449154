package com.sunday.kmplicenseperu.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.components.MyDialog
import com.sunday.kmplicenseperu.components.MyHomeHeaderInfoDialogContent
import com.sunday.kmplicenseperu.components.MyHorizontalSpacer
import com.sunday.kmplicenseperu.components.MyLicenseDialogContent
import com.sunday.kmplicenseperu.components.MyParagraph
import com.sunday.kmplicenseperu.components.MyHomeDialogPointer
import com.sunday.kmplicenseperu.components.MySubtitle
import com.sunday.kmplicenseperu.components.MyTitle
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.models.LicenseList.getALicenses
import com.sunday.kmplicenseperu.models.LicenseList.getBLicenses
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimaryDark
import com.sunday.kmplicenseperu.models.Theme.SurfaceSecondary
import com.sunday.kmplicenseperu.models.Theme.SurfaceSecondaryDark
import com.sunday.kmplicenseperu.models.Theme.TextPrimary
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.navigation.Screen
import com.sunday.kmplicenseperu.pages.home.HomeState
import com.sunday.kmplicenseperu.pages.home.HomeVM
import com.sunday.kmplicenseperu.components.PageStructure
import com.sunday.kmplicenseperu.styles.HighlightStyle
import com.sunday.kmplicenseperu.styles.HighlightStyle2
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.Constants.DARK_MODE
import com.sunday.kmplicenseperu.util.Cookies
import com.sunday.kmplicenseperu.util.ExamStudy
import com.sunday.kmplicenseperu.util.Materia
import com.sunday.kmplicenseperu.util.Res
import com.sunday.kmplicenseperu.util.dialogPopUp
import com.sunday.kmplicenseperu.util.homeEscEnterKeysHandler
import com.sunday.kmplicenseperu.util.insertGTMnoscript
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get
import org.w3c.dom.set

@Page()
@Composable
fun HomePage() {

    LaunchedEffect(true) {
        insertGTMnoscript()
    }
    val homeVM = remember { HomeVM() }
    val breakpoint = rememberBreakpoint()
    var darkMode by remember { mutableStateOf(localStorage[DARK_MODE].toBoolean()) }
    window.onkeydown = {
        if(homeVM.anyDialogDisplayed())
            homeEscEnterKeysHandler(it, homeVM)
    }

    PageStructure(
        darkMode = darkMode,
        logo = Res.Image.logoHome,
        useHeaderInfo = true,
        onHeaderInfo = { homeVM.updateShowHeaderInfoDialog(true) },
        onSwitchTheme = {
            darkMode = !darkMode
            localStorage[DARK_MODE] = darkMode.toString()
        },
        headerTitle = {
            HomePageTitle(
                breakpoint = breakpoint,
                darkMode = darkMode
            )
        },
        mainColumn = {
            HomeMainColumn(
                modifier = Modifier.fillMaxWidth(),
                breakpoint = breakpoint,
                darkMode = darkMode,
                homeVM = homeVM
            )
        },
        dialogs = {
            HomeDialogs(homeVM = homeVM, darkMode = darkMode)
        }
    )
}

@Composable
fun HomeMainColumn(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint,
    darkMode: Boolean,
    homeVM: HomeVM
) {
    Column(
        modifier = modifier
    ) {
        HomeBodySection(
            homeVM = homeVM,
            breakpoint = breakpoint,
            darkMode = darkMode,
        )
        // TODO .. add more components to MAIN-column
    }
}

@Composable
fun HomeBodySection(
    breakpoint: Breakpoint,
    darkMode: Boolean,
    homeVM: HomeVM
) {
    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val surfaceSecondary = if(!darkMode) SurfaceSecondary.color.rgb
    else SurfaceSecondaryDark.color.rgb

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(surfacePrimary)
                .maxWidth(Constants.PAGE_WIDTH.px),
            contentAlignment = Alignment.Center
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                if(breakpoint <= Breakpoint.SM) {
                    MyVerticalSpacer(12.px)
                    HomePageTitle(
                        breakpoint = breakpoint,
                        darkMode = darkMode,
                    )
                    MyVerticalSpacer(12.px)
                }

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .backgroundColor(surfacePrimary),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Licenses(
                        breakpoint = breakpoint,
                        licenses = getALicenses(),
                        darkMode = darkMode,
                        homeVM = homeVM
                    )
                    Licenses(
                        breakpoint = breakpoint,
                        licenses = getBLicenses(),
                        darkMode = darkMode,
                        homeVM = homeVM
                    )
                }
                Column(modifier = Modifier
                    .fillMaxWidth()
                    .height(15.px)
                    .backgroundColor(surfaceSecondary)
                ) {}
            }
        }
    }
}

@Composable
fun HomeDialogs(
    homeVM: HomeVM,
    darkMode: Boolean
) {
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    /* Header Info Dialog */
    MyDialog(
        modifier = Modifier.dialogPopUp(homeVM.homeState.showHeaderInfoDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyHomeHeaderInfoDialogContent(
            homeVM = homeVM,
            darkMode = darkMode,
            onSubmit = {
                homeVM.updateShowHeaderInfoDialog(false)
            },
        )
    }

    // License-Dialog
    MyDialog(
        modifier = Modifier.dialogPopUp(homeVM.homeState.showLicenseDialog),
        breakpoint = breakpoint,
        darkMode = darkMode,
    ) {
        MyLicenseDialogContent(
            homeVM = homeVM,
            licenseType = homeVM.homeState.licenseSelected,
            darkMode = darkMode,
            onDismiss = {
                homeVM.updateShowLicenseDialog(false)
                homeVM.resetExamStudyOptions()
            },
            onSubmit = {
                homeVM.updateShowLicenseDialog(false)

                HomeState.apply {
                    license = homeVM.homeState.licenseSelected
                    examStudy = if(homeVM.homeState.isExamSelected) ExamStudy.EXAM
                    else ExamStudy.STUDY
                    range = homeVM.homeState.rangeSelected
                    materia = homeVM.homeState.materiaSelected
                    comesFromHome = true
                }

//                val examStudyPath =
//                    if(HomeState.examStudy == ExamStudy.EXAM) "simulacro"
//                    else "balotario"
//                val licensePath = "licencia-${HomeState.license.name}"
//
                Cookies.validateCookies()
                context.router.navigateTo(Screen.Questions.route)
//                context.router.navigateTo(
//                    // site.com/posts?id=12345&mode=edit
//                    Screen.Questions.route + "?examStudy=${examStudyPath}&license=${licensePath}"
////                    Screen.Questions.route + "/${examStudyPath}/${licensePath}"
//                )
            },
        )
    }
}

@Composable
fun Licenses(
    breakpoint: Breakpoint,
    licenses: List<License>,
    darkMode: Boolean,
    homeVM: HomeVM
) {
    val surfaceSecondary = if(!darkMode) SurfaceSecondary.color.rgb
    else SurfaceSecondaryDark.color.rgb
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val wholeCardsMargin = when(breakpoint) {
        Breakpoint.ZERO, Breakpoint.SM -> 5.px
        else -> 10.px
    }

    Column(
        modifier = Modifier.fillMaxWidth()
            .backgroundColor(surfaceSecondary)
            .padding(/*topBottom = 20.px, */leftRight = wholeCardsMargin),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyVerticalSpacer(20.px)
        MySubtitle(
            text = "Clase " + licenses.first().name.first(),
            color = textPrimary
        )
        MyVerticalSpacer(15.px)

        SimpleGrid(
            modifier = Modifier
                .fillMaxWidth(),
            numColumns = numColumns(base = 2, sm = 2, md = 2, lg = 3, xl = 4)
        ) {
            licenses.forEach {
                LicenseCard(
                    breakpoint = breakpoint,
                    license = it,
                    darkMode = darkMode,
                    onClick = {
                        homeVM.updateShowLicenseDialog(true)
                        homeVM.updateLicenseSelected(it)
                    }
                )
            }
        }
    }
}

@Composable
fun LicenseCard(
    breakpoint: Breakpoint,
    license: License,
    darkMode: Boolean,
    onClick: () -> Unit
) {
    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val cardMargin = when(breakpoint) {
        Breakpoint.ZERO, Breakpoint.SM -> 5.px
        else -> 10.px
    }

    Column(
        modifier = Modifier
            .thenIf(
                condition = !darkMode,
                other = HighlightStyle.toModifier()
            )
            .thenIf(
                condition = darkMode,
                other = HighlightStyle2.toModifier()
            )
            .backgroundColor(surfacePrimary)
            .margin(all = cardMargin)
            .padding(all = 10.px)
            .borderRadius(r = 10.px)
            .cursor(Cursor.Pointer)
            .onClick { onClick() },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.CenterStart
        ) {
            MyParagraph(
                text = license.cardTitle,
                color = textPrimary
            )
        }
        MyVerticalSpacer(10.px)
        Image(
            modifier = Modifier
                .height(90.px)
                .width(140.px),
            src = "/licenses/${license.image}.webp",
            description = "License Image"
        )
    }
}

@Composable
fun StudyRange(
    homeVM: HomeVM,
    modifier: Modifier = Modifier,
    rbRangeGeneralList: List<String>,
    rbRangeSpecificList: List<String>,
    darkMode: Boolean
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    Column(
        modifier = modifier
    ) {
        MySubtitle(
            text = "* Elige un rango de preguntas",
            color = textPrimary
        )
        MyVerticalSpacer(10.px)

        if(rbRangeGeneralList.isNotEmpty()) {
            Row(modifier = Modifier.fillMaxWidth()) {
                MyHorizontalSpacer(20.px)
                MySubtitle(
                    text = Materia.GENERAL.type,
                    color = textPrimary
                )
            }
            MyVerticalSpacer(10.px)
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                rbRangeGeneralList.forEach {
                    MyHomeDialogPointer(
                        darkMode = darkMode,
                        text = it,
                        selected = it == homeVM.homeState.rangeSelected,
                        onClick = {
                            homeVM.updateRangeSelected(it)
                            homeVM.updateMateriaSelected(Materia.GENERAL)
                        }
                    )
                }
            }
        }

        if(rbRangeSpecificList.isNotEmpty()) {
            MyVerticalSpacer(10.px)
            Row(modifier = Modifier.fillMaxWidth()) {
                MyHorizontalSpacer(20.px)
                MySubtitle(
                    text = Materia.SPECIFIC.type,
                    color = textPrimary
                )
            }
            MyVerticalSpacer(10.px)
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                rbRangeSpecificList.forEach {
                    MyHomeDialogPointer(
                        darkMode = darkMode,
                        text = it,
                        selected = it == homeVM.homeState.rangeSelected,
                        onClick = {
                            homeVM.updateRangeSelected(it)
                            homeVM.updateMateriaSelected(Materia.SPECIFIC)
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun HomePageTitle(
    breakpoint: Breakpoint,
    darkMode: Boolean,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val textColor = if(breakpoint <= Breakpoint.SM) textPrimary else SurfacePrimary.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = "Simulacro del examen / Estudiar el balotario",
            color = textColor,
        )
    }
}