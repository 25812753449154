package com.sunday.kmplicenseperu.pages.home

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.util.Materia

class HomeVM {
    var homeState by mutableStateOf(HomeState())
        private set

    fun updateShowLicenseDialog(value: Boolean) {
        homeState = homeState.copy(
            showLicenseDialog = value
        )
    }
    fun updateShowHeaderInfoDialog(value: Boolean) {
        homeState = homeState.copy(
            showHeaderInfoDialog = value
        )
    }

    fun anyDialogDisplayed(): Boolean {
        return (homeState.showHeaderInfoDialog
                || homeState.showLicenseDialog)
    }

    fun updateLicenseSelected(license: License) {
        homeState = homeState.copy(
            licenseSelected = license
        )
    }
    fun updateShowStudyRange(value: Boolean) {
        homeState = homeState.copy(
            showStudyRange = value
        )
    }
    fun updateIsExamSelected() {
        if(!homeState.isExamSelected) {
            resetExamStudyOptions()
        }
    }
    fun updateIsStudySelected(range: String) {
        if(!homeState.isStudySelected) {
            homeState = homeState.copy(
                isExamSelected = false,
                isStudySelected = true,
                rangeSelected = range,
                showStudyRange = true
            )
        }
    }
    fun updateRangeSelected(range: String) {
        homeState = homeState.copy(
            rangeSelected = range,
        )
    }
    fun updateMateriaSelected(materia: Materia) {
        homeState = homeState.copy(
            materiaSelected = materia,
        )
    }

    fun resetExamStudyOptions() {
        homeState = homeState.copy(
            isExamSelected = true,
            isStudySelected = false,
            rangeSelected = null,
            showStudyRange = false,
            materiaSelected = Materia.GENERAL
        )
    }

    fun updateIsEscapePressed(value: Boolean) {
        homeState = homeState.copy(
            isEscapePressed = value
        )
    }
    fun updateIsEnterPressed(value: Boolean) {
        homeState = homeState.copy(
            isEnterPressed = value
        )
    }

}