package com.sunday.kmplicenseperu.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.models.LicenseList
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.HalfBlack
import com.sunday.kmplicenseperu.models.Theme.HalfWhite
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimaryDark
import com.sunday.kmplicenseperu.models.Theme.TextPrimary
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.navigation.Legal
import com.sunday.kmplicenseperu.navigation.Routes
import com.sunday.kmplicenseperu.navigation.Screen
import com.sunday.kmplicenseperu.pages.home.HomeState
import com.sunday.kmplicenseperu.pages.questions.QuestionsVM
import com.sunday.kmplicenseperu.sections.FooterSection
import com.sunday.kmplicenseperu.sections.HeaderSection
import com.sunday.kmplicenseperu.styles.UnderlineStyle
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.Cookies.cookiesExistInBrowser
import com.sunday.kmplicenseperu.util.Cookies.cookiesSet
import com.sunday.kmplicenseperu.util.ExamStudy
import com.sunday.kmplicenseperu.util.Materia
import com.sunday.kmplicenseperu.util.Res
import com.sunday.kmplicenseperu.util.VERTICAL_AD
import com.sunday.kmplicenseperu.util.insertAd
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderLeft
import com.varabyte.kobweb.compose.ui.modifiers.borderRight
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.translateX
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaBookOpenReader
import com.varabyte.kobweb.silk.components.icons.fa.FaCookieBite
import com.varabyte.kobweb.silk.components.icons.fa.FaEnvelopeOpenText
import com.varabyte.kobweb.silk.components.icons.fa.FaHouse
import com.varabyte.kobweb.silk.components.icons.fa.FaShieldHalved
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun PageStructure(
    darkMode: Boolean,
    logo: String = Res.Image.logo,
    useHeaderInfo: Boolean,
    myVM: Any? = null,
    onHeaderInfo: () -> Unit  = {},
    onSwitchTheme: () -> Unit,
    cookiesBannerVisibleInThisPage: Boolean = true,
    headerTitle: @Composable () -> Unit,
    mainColumn: @Composable () -> Unit,
    dialogs: @Composable () -> Unit = {}
) {
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    var menuOpened by remember { mutableStateOf(false) }
    var opacity by remember { mutableStateOf(0.percent) }
    var translateX by remember { mutableStateOf((-100).percent) }

    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.BottomStart
    ) {
        Column(
            modifier = Modifier.fillMaxSize().backgroundColor(surfacePrimary),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            // Header
            HeaderSection(
                breakpoint = breakpoint,
                darkMode = darkMode,
                logo = logo,
                useHeaderInfo = useHeaderInfo,
                menuOpened = menuOpened,
                onHouse = { context.router.navigateTo(Screen.Home.route) },
                onMenu = { menuOpened = true; opacity = 100.percent; translateX = 0.percent },
                onMenuClosed = { menuOpened = false; opacity = 0.percent; translateX = (-100).percent },
                onGooglePlay = { window.location.href = Constants.GOOGLE_PLAY_URL },
                onSwitchTheme = onSwitchTheme,
                onHeaderInfo = onHeaderInfo,
                headerTitle = headerTitle
            )
            // Body
            BodySection(
                breakpoint = breakpoint,
                darkMode = darkMode,
                mainColumn = mainColumn,
                myVM = myVM,
                menuOpened = menuOpened,
                opacity = opacity,
                translateX = translateX,
            )
            Spacer()
            // Footer
            FooterSection(
                breakpoint = breakpoint,
                darkMode = darkMode
            )
            dialogs()
        }
        if(cookiesBannerVisibleInThisPage && !cookiesSet && !cookiesExistInBrowser()) {
            CookieMessage(darkMode = darkMode)
        }
    }
}

@Composable
fun BodySection(
    breakpoint: Breakpoint,
    darkMode: Boolean,
    myVM: Any? = null,
    menuOpened: Boolean,
    opacity: CSSSizeValue<CSSUnit.percent>,
    translateX: CSSSizeValue<CSSUnit.percent>,
    mainColumn: @Composable () -> Unit,
) {
    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb

    Box(
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.TopCenter
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(surfacePrimary)
                .maxWidth(Constants.PAGE_WIDTH.px),
            contentAlignment = Alignment.TopCenter
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth(
                        when(breakpoint) {
                            Breakpoint.LG -> 95.percent
                            Breakpoint.XL -> 90.percent
                            else -> 100.percent
                        }
                    )
                    .fillMaxHeight()
                    .backgroundColor(surfacePrimary),
            ) {
                when(breakpoint) {
                    Breakpoint.ZERO, Breakpoint.SM -> {
                        Box(modifier = Modifier.fillMaxWidth()) {
                            Row(modifier = Modifier.fillMaxWidth()) {
                                mainColumn()
                            }
                            Column(
                                modifier = Modifier
                                    .fillMaxHeight()
                                    .padding(left = 20.px)
                                    .backgroundColor(surfacePrimary)
                                    .visibility(if(menuOpened) Visibility.Visible else Visibility.Hidden)
                                    .opacity(opacity)
                                    .translateX(tx = translateX)
                                    .transition(CSSTransition(property = "opacity", duration = 500.ms))
                                    .transition(CSSTransition(property = "translate", duration = 500.ms))
                            ) {
                                LeftSideColumn(
                                    modifier = Modifier.width(250.px),
                                    darkMode = darkMode,
                                )
                                Spacer()
                            }
                        }
                    }
                    Breakpoint.MD -> {
                        Box(modifier = Modifier.fillMaxSize()) {
                            Row(modifier = Modifier.fillMaxSize()) {
                                mainColumn()
                                RightSideColumn(
                                    modifier = Modifier.width(350.px),
                                    darkMode = darkMode,
                                    myVM = myVM
                                )
                            }
                            Column(
                                modifier = Modifier
                                    .fillMaxHeight()
                                    .padding(left = 20.px)
                                    .backgroundColor(surfacePrimary)
                                    .visibility(if(menuOpened) Visibility.Visible else Visibility.Hidden)
                                    .opacity(opacity)
                                    .translateX(tx = translateX)
                                    .transition(CSSTransition(property = "opacity", duration = 500.ms))
                                    .transition(CSSTransition(property = "translate", duration = 500.ms))
                            ) {
                                LeftSideColumn(
                                    modifier = Modifier.width(250.px),
                                    darkMode = darkMode,
                                )
                                Spacer()
                            }
                        }
                    }
                    else -> {
                        Row(modifier = Modifier.fillMaxWidth()) {
                            LeftSideColumn(
                                modifier = Modifier.width(250.px),
                                darkMode = darkMode,
                            )
                            mainColumn()
                            RightSideColumn(
                                modifier = Modifier.width(350.px),
                                darkMode = darkMode,
                                myVM = myVM
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun LeftSideColumn(
    modifier: Modifier,
    darkMode: Boolean,
) {
    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val borderPrimary = if(!darkMode) HalfBlack.color.rgb
    else HalfWhite.color.rgb

    Box(
        modifier = modifier.fillMaxHeight()
            .backgroundColor(surfacePrimary)
            .borderRight(width = 1.px, style = LineStyle.Solid, color = borderPrimary)
            .zIndex(2)
        ,
        contentAlignment = Alignment.TopStart
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
        ) {
            MyVerticalSpacer(20.px)
            Row(
                modifier = Modifier.fillMaxWidth().height(30.px),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                FaHouse(modifier = Modifier.color(textPrimary), size = IconSize.X1)
                MyHorizontalSpacer(4.px)
                MyLinkToSameTab(
                    text = "Inicio",
                    color = textPrimary,
                    path = Screen.Home.route,
                    fontSize = FontSize.Small,
                    fontWeight = if(window.location.pathname == Routes.HOME_ROUTE) FontWeight.SemiBold else FontWeight.Normal
                )
            }
            MyVerticalSpacer(20.px)
            Legal.values().forEach {
                Row(
                    modifier = Modifier.fillMaxWidth().height(30.px),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    when(it) {
                        Legal.PRIVACY_POLICY -> FaShieldHalved(modifier = Modifier.color(textPrimary), size = IconSize.X1)
                        Legal.COOKIES_POLICY -> FaCookieBite(modifier = Modifier.color(textPrimary), size = IconSize.X1)
                        Legal.TERMS_OF_USE -> FaBookOpenReader(modifier = Modifier.color(textPrimary), size = IconSize.X1)
                        Legal.CONTACT -> FaEnvelopeOpenText(modifier = Modifier.color(textPrimary), size = IconSize.X1)
                    }
                    MyHorizontalSpacer(4.px)
                    MyLinkToSameTab(
                        text = it.spanish,
                        color = textPrimary,
                        path = it.path,
                        fontSize = FontSize.Small,
                        fontWeight = if(window.location.pathname == it.path) FontWeight.SemiBold else FontWeight.Normal
                    )
                }
            }
            Spacer()
        }
    }
}

@Composable
fun RightSideColumn(
    modifier: Modifier,
    darkMode: Boolean,
    myVM: Any? = null,
) {
    val context = rememberPageContext()

    val surfacePrimary = if (!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val textPrimary = if (!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val borderPrimary = if (!darkMode) HalfBlack.color.rgb
    else HalfWhite.color.rgb

    Box(
        modifier = modifier.fillMaxHeight()
            .backgroundColor(surfacePrimary)
            .borderLeft(width = 1.px, style = LineStyle.Solid, color = borderPrimary),
        contentAlignment = Alignment.TopStart
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
        ) {

//            insertAd(VERTICAL_AD)

            MyVerticalSpacer(20.px)
            Row(
                modifier = Modifier.fillMaxWidth().height(30.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                MyText14(
                    text = "Examen (simulacro)",
                    color = textPrimary,
                    fontWeight = FontWeight.SemiBold,
                )
            }
            MyVerticalSpacer(15.px)
            LicenseList.getAllLicences().forEach { licenseX ->
                var fontWeight =
                    if(myVM is QuestionsVM
                        && myVM.questionState.currentLicense == licenseX
                        && HomeState.examStudy == ExamStudy.EXAM) {
                        FontWeight.SemiBold
                    } else {
                        FontWeight.Normal
                    }
                Row(
                    modifier = Modifier.fillMaxWidth().height(22.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    MyText14(
                        text = licenseX.dialogTitle,
                        color = textPrimary,
                        fontWeight = fontWeight,
                        modifier = UnderlineStyle.toModifier()
                            .onClick {
                                HomeState.apply {
                                    examStudy = ExamStudy.EXAM
                                    license = licenseX
                                    materia = Materia.GENERAL
                                    range = licenseX.rbRangeGeneralList.first()
                                    comesFromHome = false
                                }
                                // if it's questions-page (to force recomposition)
                                if(myVM is QuestionsVM) {
                                    myVM.updateComesFromHome(false)
                                }
                                context.router.navigateTo(Screen.Questions.route)
//                                context.router.navigateTo(
////                                    "/preguntas/simulacro/licencia-${licenseX.name}"
//                                    "/preguntas?examStudy=simulacro&license=licencia-${licenseX.name}"
//                                )
                            }
                            .cursor(Cursor.Pointer)
                    )
                }
            }

            MyVerticalSpacer(20.px)
            Row(
                modifier = Modifier.fillMaxWidth().height(30.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                MyText14(
                    text = "Estudiar (balotario)",
                    color = textPrimary,
                    fontWeight = FontWeight.SemiBold,
                )
            }
            MyVerticalSpacer(15.px)
            LicenseList.getAllLicences().forEach { licenseX ->
                var fontWeight =
                    if(myVM is QuestionsVM
                        && myVM.questionState.currentLicense == licenseX
                        && HomeState.examStudy == ExamStudy.STUDY) {
                        FontWeight.SemiBold
                    } else {
                        FontWeight.Normal
                    }

                Row(
                    modifier = Modifier.fillMaxWidth().height(22.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    MyText14(
                        text = licenseX.dialogTitle,
                        color = textPrimary,
                        fontWeight = fontWeight,
                        modifier = UnderlineStyle.toModifier()
                            .onClick {
                                HomeState.apply {
                                    comesFromHome = false
                                    examStudy = ExamStudy.STUDY
                                    license = licenseX
                                    materia = Materia.GENERAL
                                    range = licenseX.rbRangeGeneralList.first()
                                }

                                // if it's questions-page (to force recomposition)
                                if(myVM is QuestionsVM) {
                                    myVM.updateComesFromHome(false)
                                }
                                context.router.navigateTo(Screen.Questions.route)
//                                context.router.navigateTo(
////                                    "/preguntas/balotario/licencia-${licenseX.name}"
//                                            "/preguntas?examStudy=balotario&license=licencia-${licenseX.name}"
//
//                                )
                            }
                            .cursor(Cursor.Pointer)
                    )
                }
            }
            Spacer()
        }
    }
}