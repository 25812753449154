package com.sunday.kmplicenseperu.util

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.document

object Constants {

    const val FONT_FAMILY = "Roboto"
    const val DARK_MODE = "darkMode"
    const val PAGE_WIDTH = 2048
    const val HEADER_HEIGHT = 70
    const val FOOTER_HEIGHT = 70
    const val COLLAPSED_PANEL_HEIGHT = 100
    const val GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.sunday.license"
//    const val BASE_URL = "https://simulador-examen-licencia-conducir-peru.onrender.com"
//    const val BASE_URL = "http://localhost:8080"
}

object Res {
    object Image {
        const val logoHome = "logo.svg"
        const val logo = "/logo.svg"
    }
}

enum class Materia(
    val type: String
) {
    GENERAL("Materias Generales"),
    SPECIFIC("Materias Específicas")
}

enum class ExamStudy(
    val lower: String,
    val spanish: String,
) {
    EXAM("exam", "Examen"),
    STUDY("study", "Estudio")
}

object Id {
}
