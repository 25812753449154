package com.sunday.kmplicenseperu.sections

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.components.MyCaption
import com.sunday.kmplicenseperu.components.MyGeneralPointer
import com.sunday.kmplicenseperu.components.MyHorizontalSpacer
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.SurfaceBrand
import com.sunday.kmplicenseperu.models.Theme.SurfaceBrandDark
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.navigation.Legal
import com.sunday.kmplicenseperu.navigation.Screen
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.Cookies
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun FooterSection(
    breakpoint: Breakpoint,
    darkMode: Boolean
) {
    val surfaceBrand = if(!darkMode) SurfaceBrand.color.rgb
    else SurfaceBrandDark.color.rgb

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfaceBrand),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(surfaceBrand)
                .maxWidth(Constants.PAGE_WIDTH.px),
            contentAlignment = Alignment.Center
        ) {
            MyFooter(
                breakpoint = breakpoint,
            )
        }
    }
}

@Composable
fun MyFooter(
    breakpoint: Breakpoint
) {
    val context = rememberPageContext()
    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint > Breakpoint.MD) 80.percent
                else 90.percent
            )
            /*.minHeight(100.px)*/,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
        ) {

        MyVerticalSpacer(15.px)
        Row(modifier = Modifier.fillMaxWidth()) {
            MyCaption(
                text = "Enlaces de interés:",
                color = SurfacePrimary.color.rgb,
                fontWeight = FontWeight.SemiBold
            )
        }
        MyVerticalSpacer(10.px)

        Row(modifier = Modifier.fillMaxWidth()) {
            MyHorizontalSpacer(10.px)
            SimpleGrid(
                modifier = Modifier
                    .fillMaxWidth(),
                numColumns = numColumns(base = 1, sm = 2, lg = 4)
            ) {
                Legal.values().forEach {
                    MyGeneralPointer(
                        text = it.spanish,
                        onClick = {
                            Cookies.validateCookies()
                            context.router.navigateTo(it.path)
                        }
                    )
                }
            }
            MyHorizontalSpacer(10.px)
        }
        MyVerticalSpacer(10.px)

        MyCaption(
            text = "Copyright © 2024 • Domingo Tech",
            color = SurfacePrimary.color.rgb
        )
        MyVerticalSpacer(15.px)
    }
}