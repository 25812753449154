package com.sunday.kmplicenseperu.util

import com.sunday.kmplicenseperu.models.Theme.Black10Percent
import com.sunday.kmplicenseperu.pages.home.HomeVM
import com.sunday.kmplicenseperu.pages.questions.QuestionsVM
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.scale
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import kotlinx.browser.document
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.events.KeyboardEvent
import kotlin.js.Date

fun Modifier.studyRangeAppear(value: Boolean, height: Int): Modifier {
    return this
        .visibility(
            if (value) Visibility.Visible else Visibility.Hidden
        ).opacity(
            if (value) 100.percent else 0.percent
        ).height(
            if (value) height.px else 0.px
        ).transition(
            CSSTransition(property = "visibility", duration = 400.ms),
            CSSTransition(property = "opacity", duration = 400.ms),
            CSSTransition(property = "height", duration = 400.ms)
        )
}

fun Modifier.dialogPopUp(value: Boolean): Modifier {
    return this
        .visibility(
            if (value) Visibility.Visible else Visibility.Hidden
        ).opacity(
            if (value) 100.percent else 0.percent
        ).transition(
            CSSTransition(property = "visibility", duration = 400.ms),
            CSSTransition(property = "opacity", duration = 400.ms),
        )
}

fun Modifier.hightligh(): Modifier {
    return this
            .boxShadow(
                offsetY = 0.px,
                offsetX = 0.px,
                blurRadius = 8.px,
                spreadRadius = 6.px,
                color = Black10Percent.color.rgb
            )
            .scale(102.percent)
}

fun questionArrowKeysHandler(it: KeyboardEvent, questionsVM: QuestionsVM) {
    if (it.key == "ArrowLeft" && !questionsVM.isFirstQuestion()) {
        questionsVM.onPrevButton()
    }
    else if (it.key == "ArrowRight" && !questionsVM.isLastQuestion()) {
        questionsVM.onNextButton()
    }
    else if(it.key == "ArrowUp") {
        val arrowUp = when (questionsVM.questionState.optionSelected) {
            "" -> "a"
            "b" -> "a"
            "c" -> "b"
            "d" -> "c"
            else -> "a"
        }
        questionsVM.questionOptionClick(
            optionSelected = arrowUp,
            index = questionsVM.questionState.index
        )
    }
    else if(it.key == "ArrowDown") {
        val arrowDown = when (questionsVM.questionState.optionSelected) {
            "" -> "a"
            "a" -> "b"
            "b" -> "c"
            "c" -> "d"
            else -> "d"
        }
        questionsVM.questionOptionClick(
            optionSelected = arrowDown,
            index = questionsVM.questionState.index
        )
    }
}

fun homeEscEnterKeysHandler(it: KeyboardEvent, homeVM: HomeVM) {
    if (it.key == "Escape") {
        homeVM.updateIsEscapePressed(true)
    }
    else if (it.key == "Enter") {
        homeVM.updateIsEnterPressed(true)
    }
}
fun questionEscEnterKeysHandler(it: KeyboardEvent, questionsVM: QuestionsVM) {
    if (it.key == "Escape") {
        questionsVM.updateIsEscapePressed(true)
    }
    else if (it.key == "Enter") {
        questionsVM.updateIsEnterPressed(true)
    }
}

// this is part of Google Tag Manager requirements, it should go in all pages
fun insertGTMnoscript() {
    val body = document.body
    val noscript = document.createElement("noscript")
    val iframe = document.createElement("iframe")
    iframe.setAttribute("src", "https://www.googletagmanager.com/ns.html?id=GTM-PJQMSXRW")
    iframe.setAttribute("height", "0")
    iframe.setAttribute("width", "0")
    iframe.setAttribute("style", "display:none;visibility:hidden")
    noscript.appendChild(iframe)
    body?.insertBefore(noscript, body.firstChild)
}

const val VERTICAL_AD = "4342610625"
const val HORIZONTAL_AD = "9231975225"
const val SQUARE_AD = "7335605472"

fun insertAd(adId: String) {
    val body = document.body

    val script1 = document.createElement("script")
    script1.setAttribute("async", "true")
    script1.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2097661987147243")
    script1.setAttribute("crossorigin", "anonymous")

    val script2 = document.createElement("script")
    val insideScript2 = document.createTextNode("(adsbygoogle = window.adsbygoogle || []).push({});alert('$adId');")
    script2.appendChild(insideScript2)

    val ins = document.createElement("ins")
    ins.setAttribute("class", "adsbygoogle")
    ins.setAttribute("style", "display:block")
    ins.setAttribute("data-ad-client", "ca-pub-2097661987147243")
    ins.setAttribute("data-ad-slot", adId)
    ins.setAttribute("data-ad-format", "auto")
    ins.setAttribute("data-full-width-responsive", "true")

    body?.appendChild(script1)
    body?.appendChild(ins)
    body?.appendChild(script2)
}
