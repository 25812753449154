package com.sunday.kmplicenseperu.components

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.navigation.Legal
import com.sunday.kmplicenseperu.styles.UnderlineStyle
import com.sunday.kmplicenseperu.util.Constants
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

// FontSize: Small-13.px / Medium-16.px / Large-18.px

@Composable
fun MyTitle(
    text: String,
    color: CSSColorValue,
) {
    SpanText(
        modifier = Modifier
            .color(color)
            .fontWeight(FontWeight.Bold)
            .fontSize(FontSize.Large)
            .fontFamily(Constants.FONT_FAMILY),
        text = text
    )
}

@Composable
fun MySubtitle(
    modifier: Modifier = Modifier,
    text: String,
    color: CSSColorValue,
) {
    SpanText(
        modifier = modifier
            .color(color)
            .fontWeight(FontWeight.SemiBold)
            .fontSize(FontSize.Medium)
            .fontFamily(Constants.FONT_FAMILY),
        text = text
    )
}

@Composable
fun MyParagraph(
    text: String,
    color: CSSColorValue,
) {
    SpanText(
        modifier = Modifier
            .color(color)
            .fontWeight(FontWeight.Normal)
            .fontSize(FontSize.Medium)
            .fontFamily(Constants.FONT_FAMILY),
        text = text
    )
}

@Composable
fun MyText14(
    text: String,
    color: CSSColorValue,
    fontWeight: FontWeight = FontWeight.Normal,
    modifier: Modifier = Modifier
) {
    SpanText(
        modifier = modifier
            .color(color)
            .fontWeight(fontWeight)
            .fontSize(14.px)
            .fontFamily(Constants.FONT_FAMILY),
        text = text
    )
}

@Composable
fun MyCaption(
    text: String,
    color: CSSColorValue,
    fontWeight: FontWeight = FontWeight.Normal,
    modifier: Modifier = Modifier
) {
    SpanText(
        modifier = modifier
            .color(color)
            .fontWeight(fontWeight)
            .fontSize(FontSize.Small)
            .fontFamily(Constants.FONT_FAMILY),
        text = text
    )
}

@Composable
fun MyLinkToNewTab(
    text: String,
    color: CSSColorValue,
    path: String,
    modifier: Modifier = Modifier
) {
    Link(
        modifier = UnderlineStyle.toModifier()
            .then(modifier)
            .color(color)
            .fontWeight(FontWeight.Normal)
            .fontSize(FontSize.Medium)
            .fontFamily(Constants.FONT_FAMILY)
            .textDecorationLine(TextDecorationLine.None),
        path = path,
        text = text,
        openInternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    )
}

@Composable
fun MyLinkToSameTab(
    text: String,
    color: CSSColorValue,
    path: String,
    fontSize: FontSize = FontSize.Medium,
    fontWeight: FontWeight = FontWeight.Normal
) {
    Link(
        modifier = UnderlineStyle.toModifier()
            .color(color)
            .fontWeight(fontWeight)
            .fontSize(fontSize)
            .fontFamily(Constants.FONT_FAMILY)
            .textDecorationLine(TextDecorationLine.None),
        path = path,
        text = text,
        openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
    )
}

@Composable
fun MyHomeDialogPointer(
    darkMode: Boolean,
    text: String,
    selected: Boolean,
    onClick: () -> Unit = {}
) {
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb

    Box(
        modifier = UnderlineStyle.toModifier()
            .thenIf(
                condition = selected,
                other = Modifier
                    .border(
                        width = 2.px,
                        style = LineStyle.Solid,
                        color = Theme.BorderBrand.color.rgb
                    )
                    .borderRadius(r = 5.px)
            )
            .fillMaxWidth()
            .height(30.px)
            .width(80.px)
            .cursor(Cursor.Pointer)
            .onClick { onClick() },
        contentAlignment = Alignment.Center
    ) {
        MyParagraph(
            text = text,
            color = textPrimary
        )
    }
}

@Composable
fun MyGeneralPointer(
    text: String,
    onClick: () -> Unit = {}
) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Column(
            modifier = UnderlineStyle.toModifier()
                .cursor(Cursor.Pointer)
                .onClick { onClick() },
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MyCaption(
                text = text,
                color = Theme.SurfacePrimary.color.rgb
            )
        }
        MyVerticalSpacer(5.px)
    }
}

@Composable
fun MyVerticalSpacer(
    margin: CSSLengthOrPercentageNumericValue
) {
    Box(modifier = Modifier.margin(bottom = margin))
}
@Composable
fun MyHorizontalSpacer(
    margin: CSSLengthOrPercentageNumericValue
) {
    Box(modifier = Modifier.margin(left = margin))
}
