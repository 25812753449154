package com.sunday.kmplicenseperu.local.questions

import com.sunday.kmplicenseperu.models.Question

object A2ASpecificQuestions {

    fun getA2ASpecificQuestions() = listOf(

        Question(
            question = "El Reglamento Nacional de Administración de Transporte regula:",
            options = listOf(
                "a) El servicio de transporte terrestre y el tránsito.",
                "b) El servicio de transporte terrestre de personas y mercancías.",
                "c) Únicamente el servicio de transporte de mercancías.",
                "d) Ninguna de las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "El régimen o la actividad de fiscalización no está orientada a:",
            options = listOf(
                "a) Proteger la vida, la salud y la seguridad de las personas.",
                "b) Proteger los intereses de los usuarios y de los prestadores del servicio.",
                "c) Sancionar los incumplimientos o infracciones previstas en el Reglamento Nacional de Administración de Transporte.",
                "d) Promover la inclusión social. ",
            ),
            result = "d"
        ),

        Question(
            question = "Las sanciones por las infracciones al servicio de transporte se encuentran comprendidas en:",
            options = listOf(
                "a) El Reglamento Nacional de Administración de Transporte.",
                "b) El Texto Único Ordenado del Reglamento Nacional de Tránsito - Código de Tránsito.",
                "c) El Reglamento Nacional de Inspecciones Técnicas Vehiculares.",
                "d) Todas las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "Con relación a las obligaciones del conductor del servicio de transporte terrestre. Marque la alternativa incorrecta:",
            options = listOf(
                "a) Debe ser titular de una licencia de conducir de la clase y categoría que corresponda al vehículo que conduce.",
                "b) Debe portar su licencia de conducir vigente, así como la documentación del vehículo.",
                "c) Debe conducir solo vehículos habilitados por la autoridad competente.",
                "d) Puede oponerse a la labor de supervisión y fiscalización de la autoridad competente.",
            ),
            result = "d"
        ),

        Question(
            question = "Con relación a las obligaciones del conductor del servicio de transporte terrestre, es obligatorio que el conductor ______.",
            options = listOf(
                "a) No deba tener su licencia de conducir suspendida, retenida o cancelada.",
                "b) No deba llegar o exceder de tope máximo de 100 puntos firmes.",
                "c) No tenga impuestas dos o más infracciones Muy Graves, cinco o más infracciones Graves o una infracción Muy Grave y tres o más infracciones Graves.",
                "d) Todas las anteriores.",
            ),
            result = "d"
        ),

        Question(
            question = "La entidad competente de fiscalizar el servicio de transporte de ámbito provincial es:",
            options = listOf(
                "a) El Gobierno Regional. ",
                "b) La Municipalidad provincial.",
                "c) El Ministerio de Transportes y Comunicaciones.",
                "d) \"a\" y \"c\" son correctas. ",
            ),
            result = "b"
        ),

        Question(
            question = "Las infracciones se clasifican de acuerdo a su gradualidad en:",
            options = listOf(
                "a) Levísimas, graves y gravísimas.",
                "b) Leves, graves y muy graves.",
                "c) Leves, graves, muy graves y gravísimas.",
                "d) Levísimas, gravísimas y muy graves.",
            ),
            result = "b"
        ),

        Question(
            question = "La edad máxima para conducir vehículos destinados al servicio de transporte terrestre es de:",
            options = listOf(
                "a) 55 años. ",
                "b) 60 años. ",
                "c) 65 años. ",
                "d) 70 años. ",
            ),
            result = "d"
        ),

        Question(
            question = "El servicio de transporte especial de personas es aquel que se presta:",
            options = listOf(
                "a) Sin regularidad ni uniformidad.",
                "b) Con regularidad y uniformidad.",
                "c) Sin regularidad y con uniformidad.",
                "d) Con regularidad y sin uniformidad ",
            ),
            result = "a"
        ),

        Question(
            question = "La autorización del servicio de taxi habilita para:",
            options = listOf(
                "a) Prestar el servicio dentro de la respectiva provincia donde se otorgó la autorización.",
                "b) Prestar el servicio en todo el ámbito nacional.",
                "c) Prestar el servicio dentro de todo el departamento correspondiente a la provincia donde se otorgó la autorización.",
                "d) Ninguna de las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál de los siguientes servicios no corresponde al servicio de transporte especial de personas?",
            options = listOf(
                "a) Diferenciado. ",
                "b) Turístico. ",
                "c) De trabajadores. ",
                "d) De estudiantes. ",
            ),
            result = "a"
        ),

        Question(
            question = "Si un conductor atenta contra la integridad física del inspector durante la realización de sus funciones, será sancionado con:",
            options = listOf(
                "a) Cancelación de licencia de conducir e inhabilitación para obtener nueva licencia por tres (3) años.",
                "b) Suspensión de la licencia de conducir por tres (3) años.",
                "c) Cancelación de la licencia de conducir e inhabilitación definitiva para obtener nueva licencia. Multa de 0.5 UIT.",
                "d) Cancelación de la licencia de conducir por un (1) año e inhabilitación definitiva para obtener nueva licencia. Multa de 1 UIT.",
            ),
            result = "c"
        ),

        Question(
            question = "El vehículo que presta el servicio de transporte provincial de personas debe contar con el siguiente Seguro:",
            options = listOf(
                "a) Únicamente con el Seguro Obligatorio de Accidentes de Tránsito - SOAT.",
                "b) Únicamente con el Certificado de Accidentes de Tránsito - CAT.",
                "c) El Seguro Obligatorio de Accidentes de Tránsito - SOAT o el Certificado contra Accidentes de tránsito - CAT.",
                "d) Ninguno de los anteriores. ",
            ),
            result = "c"
        ),

        Question(
            question = "En el servicio de transporte la vigencia de la habilitación del conductor es ______.y de renovación automática una vez acreditada la vigencia de la licencia de conducir de la categoría correspondiente al vehículo habilitado.",
            options = listOf(
                "a) Anual. ",
                "b) Cada dos años. ",
                "c) Cada cinco años. ",
                "d) Ninguna de las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿A partir de qué edad el conductor del servicio de transporte deberá rendir y aprobar los exámenes médicos semestrales que establezca la Dirección General de Transporte Terrestre (DGTT)?",
            options = listOf(
                "a) 60 años. ",
                "b) 55 años. ",
                "c) 65 años. ",
                "d) No existe dicho exigencia en la norma. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si un presunto infractor paga voluntariamente dentro de los cinco días hábiles de levantada el acta de control, la multa que corresponda a la infracción imputada, ésta será reducida en:",
            options = listOf(
                "a) 20% de su monto. ",
                "b) 30% de su monto. ",
                "c) 40% de su monto. ",
                "d) 50% de su monto. ",
            ),
            result = "d"
        ),

        Question(
            question = "Cuando hablamos del «servicio de transporte especial de ámbito provincial, prestado en vehículos M1 de la clasificación vehicular, que tiene por objeto el traslado de personas desde un punto de origen hasta un punto de destino señalado por quien lo contrata.», nos estamos refiriendo al:",
            options = listOf(
                "a) Servicio de transporte de trabajadores.",
                "b) Servicio de transporte de mercancías.",
                "c) Servicio de transporte de taxi.",
                "d) Servicio de transporte turístico. ",
            ),
            result = "c"
        ),

        Question(
            question = "Es la medida preventiva que consiste en el traslado del vehículo fuera de la vía pública dispuesto por la autoridad competente y/o PNP, utilizando cualquier medio eficaz y proporcional al fin que se persigue:",
            options = listOf(
                "a) Remoción del vehículo. ",
                "b) Retención del vehículo. ",
                "c) Internamiento del vehículo. ",
                "d) Interrupción del viaje. ",
            ),
            result = "a"
        ),

        Question(
            question = "El servicio de transporte debe ser prestado a través de vehículos que:",
            options = listOf(
                "a) Se encuentren habilitados.",
                "b) Hayan aprobado la Inspección Técnica Vehicular, cuando corresponda.",
                "c) Cuenten con póliza de Seguro Obligatorio de Accidentes de Tránsito vigente o Certificado Contra Accidentes de Tránsito, según corresponda.",
                "d) Todas las anteriores. ",
            ),
            result = "d"
        ),

        Question(
            question = "¿Qué sanción le corresponde al conductor que presta el servicio sin contar con autorización otorgada por la autoridad competente o en una modalidad o ámbito diferente al autorizado?",
            options = listOf(
                "a) Multa de 1 UIT ",
                "b) Multa de 2 UIT ",
                "c) Multa de 0.5 UIT ",
                "d) Multa de 0.05 UIT ",
            ),
            result = "a"
        ),

        Question(
            question = "En cual de los siguientes supuestos no corresponde la medida preventiva de retención de la licencia de conducir, en el servicio de transporte terrestre:",
            options = listOf(
                "a) Por negarse a entregar información o documentación correspondiente al vehículo que conduce.",
                "b) Por realizar acciones destinadas a no someterse o a burlar la fiscalización.",
                "c) Por desacatar la orden de suspensión precautoria del servicio de transporte.",
                "d) Por conducir un vehículo que presta servicio de transporte, excediendo la edad máxima prevista en el Reglamento Nacional de Administración de Transporte",
            ),
            result = "c"
        ),

        Question(
            question = "La licencia de conducir de Clase A - Categoría IIA tendrá una vigencia de ______. ",
            options = listOf(
                "a) 3 años. ",
                "b) 5 años. ",
                "c) 8 años. ",
                "d) 2 años. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál es la antigüedad mínima contando con licencia de Clase A - Categoría I que se requiere para obtener la licencia de Clase A categoría IIA por recategorización?",
            options = listOf(
                "a) 2 años. ",
                "b) 4 años. ",
                "c) 5 años ",
                "d) No existe antigüedad mínima. ",
            ),
            result = "a"
        ),

        Question(
            question = "La licencia de conducir de clase A categoría IIA permite conducir los vehículos señalados en la categoría:",
            options = listOf(
                "a) I ",
                "b) III ",
                "c) IV ",
                "d) II B ",
            ),
            result = "a"
        ),

        )
}