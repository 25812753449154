package com.sunday.kmplicenseperu.local.questions

import com.sunday.kmplicenseperu.models.Question

object B2AGeneralQuestions {

    fun getB2AGeneralQuestions() = listOf(

        Question(
            question = "Con respecto a las definiciones de acera y/o de calzada, marque la respuesta correcta:",
            options = listOf(
                "a) Ambas son una parte de la vía y están destinadas a la circulación de vehículos.",
                "b) La calzada es una parte de la vía destinada a la circulación de vehículos y eventualmente al cruce de peatones y animales.",
                "c) Por lo general en la acera circulan vehículos.",
                "d) La acera es parte de la vía en la cual excepcionalmente pueden circular personas.",
            ),
            result = "b"
        ),

        Question(
            question = "Es una prerrogativa de un peatón o conductor de un vehículo para proseguir su marcha en precedencia a otro peatón o vehículo:",
            options = listOf(
                "a) Paso a desnivel.",
                "b) Paso a nivel.",
                "c) Paso Peatonal.",
                "d) Derecho de paso. ",
            ),
            result = "d"
        ),

        Question(
            question = "En una intersección y ante la señal correspondiente, ________ es un límite para que el vehículo se detenga. ",
            options = listOf(
                "a) La Línea Transversal marcada en la calzada.",
                "b) La isla.",
                "c) La Línea de Parada.",
                "d) La a y la c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Es el Reglamento que establece la clasificación y nomenclatura de las vías:",
            options = listOf(
                "a) El TUO de Tránsito.",
                "b) Reglamento Nacional de Gestión de Infraestructura Vial. ",
                "c) El Reglamento Nacional de Jerarquización Vial.",
                "d) a y b son correctas.",
            ),
            result = "c"
        ),

        Question(
            question = "La calzada, la acera y la berma son algunos de los elementos que conforman:",
            options = listOf(
                "a) El estacionamiento.",
                "b) El equipamiento de servicios necesarios.",
                "c) El Carril.",
                "d) La vía.",
            ),
            result = "d"
        ),

        Question(
            question = "No está Prohibido en la vía:",
            options = listOf(
                "a) Recoger o dejar pasajeros o carga en lugares no autorizados. ",
                "b) Destinar las calzadas a otro uso que no sea el tránsito y el estacionamiento.",
                "c) Recoger o dejar pasajeros en lugares autorizados. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "c"
        ),

        Question(
            question = "Con respecto al Manual de Dispositivos de Control del Tránsito Automotor para Calles y Carreteras, marque la respuesta correcta:",
            options = listOf(
                "a) Establece el modo de empleo de los diferentes dispositivos de control del tránsito, clasificación, funcionalidad, color, tamaño.",
                "b) Fue actualizado por última vez en el año 2016.",
                "c) Ninguna de las anteriores.",
                "d) a y b son correctas. ",
            ),
            result = "d"
        ),

        Question(
            question = "Son algunos de los dispositivos de control de tránsito automotor para calles y carreteras: ",
            options = listOf(
                "a) Señales Verticales, Marcas en el Pavimento, Espejos. ",
                "b) Señales Verticales, Marcas en el Pavimento, Radios. ",
                "c) Señales Verticales y Horizontales (marcas en el pavimento), Semáforos. ",
                "d) Espejos, radios y semáforos.",
            ),
            result = "c"
        ),

        Question(
            question = "Señale la respuesta correcta respecto de la obediencia a los dispositivos de control o regulación del tránsito.",
            options = listOf(
                "a) Los conductores y los peatones están obligados a su obediencia solo cuando la Policía Nacional asignada al Tránsito lo indique.",
                "b) Los conductores y los peatones están obligados a su obediencia, salvo instrucción en contrario de la Policía Nacional asignada al Tránsito.",
                "c) Solo los conductores están obligados a su obediencia.",
                "d) Los conductores están obligados a su obediencia, sin excepción alguna.",
            ),
            result = "b"
        ),

        Question(
            question = "Las señalares verticales se clasifican: ",
            options = listOf(
                "a) i. Reguladoras, ii. De reglamentación y iii. De advertencia. ",
                "b) i. Informativas, ii. De reglamentación y iii. Preventivas. ",
                "c) i. Reguladoras, ii. De advertencia y iii. Preventivas.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "Las señales verticales de tránsito que tienen por finalidad indicar prohibición, restricción o limitación en el uso de la vía son denominadas:",
            options = listOf(
                "a) Reguladoras.",
                "b) Informativas.",
                "c) Preventivas.",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "Las señales verticales de tránsito que tienen por finalidad advertir a los usuarios de la existencia y naturaleza de un peligro en la vía son denominadas:",
            options = listOf(
                "a) Informativas.",
                "b) Preventivas.",
                "c) Reguladoras.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "Cuál de la siguiente señal vertical es informativa:",
            image = "b2_img_13_hhh2_www3",
            options = listOf(
                "a)",
                "b)",
                "c)",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "Cuál de la siguiente señal vertical es preventiva:",
            image = "b2_img_14_hhh2_www3",
            options = listOf(
                "a)",
                "b)",
                "c)",
                "d) La a y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Cuál de la siguiente señal vertical es reguladora o de reglamentación: ",
            image = "b2_img_15_hhh2_www3",
            options = listOf(
                "a)",
                "b)",
                "c)",
                "d) Solo la b es incorrecta.",
            ),
            result = "d"
        ),

        Question(
            question = "Las señales verticales preventivas tienen, por lo general, la siguiente forma: ",
            options = listOf(
                "a) Rectangular.",
                "b) Romboidal.",
                "c) Triangulo tipo banderola.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "Las ______ tienen, por lo general, forma circular inscrita dentro de una placa rectangular en la que también esté contenida la leyenda explicativa del símbolo.",
            options = listOf(
                "a) Señales verticales reguladoras.",
                "b) Señales verticales de información.",
                "c) Señales verticales preventivas.",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "La señal vertical preventiva \"Cruz de San Andrés\" significa: ",
            options = listOf(
                "a) Paso a desnivel de autopista.",
                "b) Paso a nivel de autopista.",
                "c) Paso a nivel de línea férrea.",
                "d) Ninguna de las anteriores.",
            ),
            result = "c"
        ),

        Question(
            question = "La señal vertical reglamentaria R-6 \"Prohibido voltear a la izquierda\" significa que: ",
            options = listOf(
                "a) Está prohibido voltear a la izquierda, y por tanto también el giro en U.",
                "b) Está prohibido voltear a la izquierda, sin embargo, esta permitido el giro en U.",
                "c) No está permitido el giro a la izquierda con luz roja. ",
                "d) Ninguna de las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "La señal vertical reglamentaria R-3 significa que: ",
            image = "b2_img_20",
            options = listOf(
                "a) Nos acercamos a una zona restringida al tránsito.",
                "b) Nos aproximamos a un tramo de circulación unidireccional en el sentido indicado por la fecha.",
                "c) Debemos circular sólo en la dirección y sentido indicado por la flecha.",
                "d) Ninguna de las anteriores.",
            ),
            result = "c"
        ),

        Question(
            question = "Las señales verticales informativas tienen por objeto:",
            options = listOf(
                "a) Notificar a los usuarios de la vía de la existencia de un peligro y la naturaleza de éste.",
                "b) Advertir a los usuarios de la vía de la existencia de un peligro y la naturaleza de éste.",
                "c) Identificar las vías y guiar al usuario proporcionándole la información que pueda necesitar.",
                "d) Ninguna de las anteriores.",
            ),
            result = "c"
        ),

        Question(
            question = "En las vías, cuál es la finalidad si las marcas de pavimento son del tipo central discontinua y de color amarillo:",
            options = listOf(
                "a) Separa vías en carriles bidireccionales, en los que está permitido cruzar al otro carril para adelantar.",
                "b) Separa vías en carriles bidireccionales, en los que no está permitido cruzar al otro carril para adelantar.",
                "c) Separa vías en dos carriles del mismo sentido.",
                "d) a y b son correctas.",
            ),
            result = "a"
        ),

        Question(
            question = "Los semáforos de acuerdo a su objetivo se clasifican en: ",
            options = listOf(
                "a) Vehiculares y/o peatonales.",
                "b) Vehiculares y/o peatonales y/o especiales.",
                "c) Especiales",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "En una vía, los colores del semáforo son: ",
            options = listOf(
                "a) Rojo, Amarillo y Verde. ",
                "b) Verde, Amarillo y Anaranjado. ",
                "c) Verde, Rosado y Anaranjado. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "En una vía, los colores del semáforo están ubicados de la siguiente forma:",
            options = listOf(
                "a) Horizontalmente en el siguiente orden: Arriba el Rojo, en el medio Amarillo y en la parte inferior Verde.",
                "b) Horizontalmente en el siguiente orden: Rojo, Amarillo y Verde.",
                "c) Horizontalmente en el siguiente orden: Arriba el Verde, al medio Amarillo y en la parte inferior Rojo.",
                "d) a y b son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "El color del semáforo ámbar tiene el siguiente significado:",
            options = listOf(
                "a) Es una señal que indica prevención y los vehículos que la enfrentan deben avanzar.",
                "b) Los vehículos que enfrenten esta señal deben detenerse antes de entrar a la intersección.",
                "c) Los vehículos que han sido sorprendidos tan próximos al cruce de la intersección que ya no pueden detenerse con suficiente seguridad, deben continuar con precaución.",
                "d) b y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "El color del semáforo rojo tiene el siguiente significado:",
            options = listOf(
                "a) Los vehículos que enfrenten esta señal deben detenerse después de la línea de parada y antes del crucero peatonal.",
                "b) Indica prevención.",
                "c) Si no hay crucero peatonal, los vehículos deben detenerse antes de llegar a la intersección.",
                "d) Los vehículos pueden detenerse encima del crucero peatonal.",
            ),
            result = "c"
        ),

        Question(
            question = "Señale la respuesta correcta respecto a los cruces a nivel con vías férreas:",
            options = listOf(
                "a) Los vehículos que transitan por la vía férrea tienen preferencia de paso.",
                "b) Los que transitan por la vía que cruza la vía férrea tienen preferencia de paso.",
                "c) Los vehículos pueden cruzar por lugares distintos a los cruces a nivel establecidos.",
                "d) b y c son correctas.",
            ),
            result = "a"
        ),

        Question(
            question = "Ante la señal de color rojo del semáforo y la indicación del efectivo de la PNP asignado al control del tránsito de continuar la marcha, corresponde:",
            options = listOf(
                "a) Detenerse hasta que cambie a luz verde. ",
                "b) Continuar la marcha.",
                "c) Verificar si existe otra señal vertical que indique PARE, si es así, corresponde detenerse.",
                "d) Todas son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "Las señales luminosas o semáforos se imponen o prevalecen sobre:",
            options = listOf(
                "a) Las indicaciones del efectivo de la PNP asignado al control del Tránsito.",
                "b) Las señales horizontales.",
                "c) Las señales verticales.",
                "d) b y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Tramitar y obtener el duplicado de una licencia de conducir que se encuentre cancelada causará: ",
            options = listOf(
                "a) La suspensión de la licencia de conducir por un (1) año. ",
                "b) La suspensión de la licencia de conducir por tres (3) años.",
                "c) La inhabilitación definitiva del conductor.",
                "d) La inhabilitación por tres (3) años del conductor.",
            ),
            result = "c"
        ),

        Question(
            question = "La infracción por conducir un vehículo automotor sin licencia de conducir está sancionada con una multa de 50% UIT y sanción no pecuniaria de:",
            options = listOf(
                "a) Inhabilitación para obtener licencia de conducir por 3 años.",
                "b) Cancelación de licencia de conducir.",
                "c) Inhabilitación para obtener licencia de conducir por 2 años.",
                "d) Inhabilitación definitiva para obtener una licencia de conducir.",
            ),
            result = "a"
        ),

        Question(
            question = "Aumentar la velocidad cuando es alcanzado por otro vehículo que tiene la intención de sobrepasarlo o adelantarlo es una falta:",
            options = listOf(
                "a) Leve.",
                "b) Grave.",
                "c) Muy Grave.",
                "d) No se encuentra tipificado como infracción.",
            ),
            result = "b"
        ),

        Question(
            question = "No respetar los límites máximos o mínimos de velocidad establecidos es una falta: ",
            options = listOf(
                "a) Leve.",
                "b) Grave.",
                "c) Muy Grave.",
                "d) No se encuentra tipificado como infracción.",
            ),
            result = "c"
        ),

        Question(
            question = "Los conductores de vehículos que circulan de noche en las vías públicas urbanas, ¿Con qué luces deben circular?",
            options = listOf(
                "a) Bajas.",
                "b) Altas.",
                "c) Ninguna de las anteriores.",
                "d) Con las luces altas en las intersecciones y las luces bajas en las avenidas.",
            ),
            result = "a"
        ),

        Question(
            question = "En caso de accidente, la póliza del Seguro Obligatorio de Accidentes de Tránsito cubre los gastos médicos de:",
            options = listOf(
                "a) Máximo cinco personas.",
                "b) A la tripulación y pasajeros ocupantes y terceros no ocupantes de un vehículo automotor que se vieron afectados.",
                "c) Sólo al número de ocupantes del vehículo.",
                "d) a y b son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "Señale la respuesta correcta respecto a la circulación de un vehículo sin SOAT o CAT:",
            options = listOf(
                "a) Constituye una falta muy grave.",
                "b) Constituye una falta grave.",
                "c) Puede imponerse, además de la papeleta de infracción, una medida preventiva de retención del vehículo.",
                "d) a y c son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Según el Código de Tránsito, los conductores de vehículos, deben dar preferencia de paso a:",
            options = listOf(
                "a) Los vehículos de emergencia y vehículos oficiales cuando éstos emitan las señales audibles.",
                "b) A los usuarios que circulan por la vía preferencial.",
                "c) El reglamento no regula la preferencia de paso.",
                "d) Sola la c es incorrecta.",
            ),
            result = "d"
        ),

        Question(
            question = "Si conduciendo en la vía pública te aproximas a un vehículo de transporte escolar que se encuentra detenido recogiendo o dejando escolares ¿Cuál sería la acción a seguir?",
            options = listOf(
                "a) Detener el vehículo y no continuar la marcha hasta que haya culminado el ascenso o descenso de los escolares.",
                "b) Adelantar por el lado izquierdo.",
                "c) Tocar el claxon con precaución.",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Qué significa un triángulo rojo de seguridad colocado en la calzada?",
            options = listOf(
                "a) La presencia de un vehículo inmovilizado en la vía pública por alguna emergencia.",
                "b) Zonas de obras por reparación en la calzada.",
                "c) Que el vehículo que la enfrenta debe detenerse.",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "La tramitación y obtención del duplicado de una Licencia de Conducir que ha sido objeto de suspensión, causara una suspensión del ______ en que se encontraba suspendida.",
            options = listOf(
                "a) Doble de tiempo.",
                "b) Triple de tiempo.",
                "c) Ambas son correctas.",
                "d) No se encuentra regulado.",
            ),
            result = "a"
        ),

        Question(
            question = "Las luces intermitentes de emergencia del vehículo deben utilizarse ______.",
            options = listOf(
                "a) Para girar en las intersecciones y para advertir los cambios de carril.",
                "b) Para indicar la detención, parada o estacionamiento en zona peligrosa o la ejecución de maniobras riesgosas.",
                "c) Cuando se cambia de luz alta a luz baja.",
                "d) En cada intersección.",
            ),
            result = "b"
        ),

        Question(
            question = "Las vías de doble sentido tienen prioridad de paso en las intersecciones, respecto a las vías de un solo sentido de igual clasificación:",
            options = listOf(
                "a) No.",
                "b) Sí. ",
                "c) Depende de la intersección.",
                "d) No se encuentra regulado en el reglamento.",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué otra denominación tienen las señales \"Prohibitivas o restrictivas\"?",
                    options = listOf(
                    "a) Reguladoras.",
            "b) Preventivas.",
            "c) Informativas.",
            "d) Ninguna de las anteriores.",
        ),
        result = "a"
    ),

    Question(
    question = "Está prohibido estacionar frente a entradas de garajes, a la salida de una vía privada, en las curvas, puentes túneles, zonas estrechas de la vía y:",
    options = listOf(
    "a) En cualquier lugar que afecte la seguridad, visibilidad o fluidez del tránsito o impida observar la señalización.",
    "b) Dentro de una intersección.",
    "c) Frente a recintos militares y policiales. ",
    "d) Todas son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "¿Qué señal debe utilizar el conductor para disminuir la velocidad del vehículo cuando está en marcha?",
    options = listOf(
    "a) Luces intermitentes, como regla general.",
    "b) Antebrazo izquierdo y mano extendidos hacia abajo fuera del vehículo, en caso de fuerza mayor.",
    "c) Luces intermitentes o antebrazo izquierdo y mano extendidos hacia abajo fuera del vehículo, indistintamente.",
    "d) a y b son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "Los vehículos que hayan sufrido un desperfecto o que por un accidente resulten dañados o destruidos, no deben permanecer en la vía pública, entorpeciendo el tránsito, deben ser retirados por el conductor:",
    options = listOf(
    "a) A la brevedad posible, caso contrario serán retirados, por disposición de la autoridad competente, por cuenta del propietario.",
    "b) En un tiempo de hasta 12 horas.",
    "c) Con un plazo máximo de hasta 24 horas.",
    "d) En un plazo de 18 horas.",
    ),
    result = "a"
    ),

    Question(
    question = "De dos vehículos que se aproximen simultáneamente a una intersección no regulada, procedentes de dos vías diferentes, ¿Quién tiene preferencia de paso?",
    options = listOf(
    "a) Cualquiera de los dos.",
    "b) El que se aproxime por la derecha del otro.",
    "c) El que se aproxime por la izquierda del otro.",
    "d) El que primero toca el claxon.",
    ),
    result = "b"
    ),

    Question(
    question = "En una intersección no regulada tiene preferencia de paso: ",
    options = listOf(
    "a) El vehículo que ingresó primero a la intersección.",
    "b) El vehículo que toque la bocina primero.",
    "c) El vehículo que haga cambio de luces primero.",
    "d) Cualquiera de los dos.",
    ),
    result = "a"
    ),

    Question(
    question = "En una Rotonda tiene prioridad de paso, el vehículo que:",
    options = listOf(
    "a) Circula fuera de ella.",
    "b) Circula por ella.",
    "c) Intenta ingresar a la rotonda.",
    "d) El que toca el claxon.",
    ),
    result = "b"
    ),

    Question(
    question = "Las señales Reguladoras, ¿qué significan para los usuarios de las vías? ",
    options = listOf(
    "a) Información de guía para su desplazamiento.",
    "b) Los peligros existentes en la vía.",
    "c) Las prohibiciones o restricciones, en su utilización.",
    "d) Ninguna de los anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "El sobrepaso o adelantamiento de un vehículo en movimiento, se efectúa, salvo excepciones, por la _____ retornando el vehículo después de la maniobra a su carril original.",
    options = listOf(
    "a) Derecha.",
    "b) Izquierda.",
    "c) Berma.",
    "d) Por la derecha o izquierda.",
    ),
    result = "b"
    ),

    Question(
    question = "Si un conductor está tomando medicamentos y por ello, siente cansancio, o sueño ¿qué debe hacer?",
    options = listOf(
    "a) Manejar normalmente.",
    "b) Manejar despacio.",
    "c) Abstenerse de manejar.",
    "d) Manejar con un copiloto.",
    ),
    result = "c"
    ),

    Question(
    question = "Son documentos que deben portarse obligatoriamente, durante la conducción del vehículo, y exhibirse cuando la autoridad competente lo solicite:",
    options = listOf(
    "a) D.N.I, SOAT o CAT - según corresponda-, y tarjeta de identificación vehicular.",
    "b) Certificado de Inspección Técnica Vehicular vigente, licencias de conducir vigente.",
    "c) Contrato de compraventa del vehículo.",
    "d) Solo la c) es incorrecta.",
    ),
    result = "d"
    ),

    Question(
    question = "El conductor de un vehículo que es alcanzado por otro, que hizo conocer su Intención de adelantarlo o sobrepasarlo ¿Qué debe hacer?",
    options = listOf(
    "a) Mantener su posición y no aumentar su velocidad, hasta que el otro vehículo haya finalizado la maniobra.",
    "b) Detenerse, para facilitar el adelantamiento.",
    "c) Aumentar la velocidad para facilitar la maniobra.",
    "d) Estacionarse en el lado derecho de la vía.",
    ),
    result = "a"
    ),

    Question(
    question = "Las señales preventivas tienen por finalidad:",
    options = listOf(
    "a) Advertir.",
    "b) Indicar.",
    "c) Guiar.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "El peatón tiene derecho de paso:",
    options = listOf(
    "a) Sobre cualquier vehículo en las intersecciones de las calles no señalizadas con dispositivos de control o por la PNP.",
    "b) Respecto a los vehículos que cruzan la acera para ingresar o salir de áreas de estacionamiento.",
    "c) a y b son correctas.",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Responder verdadero o falso respecto de lo siguiente: \"El conductor de un vehículo debe dar preferencia de paso a los peatones que estén concluyendo el cruce en las intersecciones reguladas\":",
    options = listOf(
    "a) Verdadero.",
    "b) Falso.",
    "c) Depende del tipo de vehículo.",
    "d) No se encuentra regulado en el reglamento.",
    ),
    result = "a"
    ),

    Question(
    question = "Brazo y antebrazo izquierdo y mano extendidos hacia fuera del vehículo horizontalmente es la señal reglamentaria que debe hacer el conductor, en casos de fuerza mayor, para voltear:",
    options = listOf(
    "a) A la derecha.",
    "b) A la izquierda.",
    "c) En U.",
    "d) Para adelantar.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Qué acción debe efectuar el conductor que se aproxime a otro vehículo que circula en sentido contrario?",
    options = listOf(
    "A) Seguir su marcha con normalidad.",
    "b) Reducir la velocidad.",
    "c) Aumentar la velocidad.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "El uso de la luz _____, es obligatorio en carreteras.",
    options = listOf(
    "a) Baja.",
    "b) Alta.",
    "c) De posición.",
    "d) De emergencia.",
    ),
    result = "b"
    ),

    Question(
    question = "Deben usarse para indicar la detención, parada o estacionamiento en Zonas peligrosas:",
    options = listOf(
    "a) Las luces de Posición.",
    "b) Las luces Direccionales.",
    "c) Las luces Intermitentes.",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "En los tramos de una vía con pendiente pronunciada que permita la circulación de un solo vehículo, el vehículo que asciende respecto al vehículo que desciende:",
    options = listOf(
    "a) No tiene preferencia de paso.",
    "b) Tiene preferencia de paso. ",
    "c) Debe detenerse.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "La detención de la marcha de un vehículo en caso de emergencia, implica que el conductor hará uso de ______, y antes de reiniciar la marcha, deberá cerciorarse que puede hacerlo sin riesgo.",
    options = listOf(
    "a) Las luces de posición.",
    "b) Las luces intermitentes.",
    "c) Las luces de freno.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Cuál de las siguientes alternativas corresponde asumir al conductor de un vehículo que llega a una vía preferencial?",
    options = listOf(
    "a) Tiene derecho de paso para su incorporación a la vía preferencial.",
    "b) Debe ceder el paso e incorporarse cuando la vía esté despejada.",
    "c) Debe mantener su velocidad.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "Al cambiar de dirección, un conductor debe: ",
    options = listOf(
    "a) Señalizar toda la maniobra.",
    "b) Ceder el derecho de paso a los demás vehículos y a los peatones.",
    "c) a y b son correctas. ",
    "d) Solo la b) es correcta.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Se puede conducir un vehículo con el motor en punto neutro?",
    options = listOf(
    "a) Algunas veces.",
    "b) No, está prohibido.",
    "c) Sí, está permitido.",
    "d) No se encuentra regulado en el reglamento.",
    ),
    result = "b"
    ),

    Question(
    question = "Según el Reglamento Nacional de Tránsito, en los cruces de ferrocarril a nivel sin barreras ni semáforos: El conductor del vehículo debe asegurarse que no se aproxime un tren y la velocidad precautoria debe ser:",
    options = listOf(
    "a) Mayor a 70 Km/h.",
    "b) Menor o igual a 20 Km/h.",
    "c) Mayor o igual a 50Km/h.",
    "d) 60 Km/h. ",
    ),
    result = "b"
    ),

    Question(
    question = "Para girar a la izquierda, en caso de un imprevisto, debe hacerse la siguiente señal:",
    options = listOf(
    "a) Brazo, antebrazo izquierdo y mano extendidos horizontalmente hacia afuera del vehículo.",
    "b) Antebrazo izquierdo y mano extendidos hacia arriba fuera del vehículo, haciendo ángulo recto con el brazo.",
    "c) Brazo, antebrazo derecho y mano extendidos hacia afuera del vehículo.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Antebrazo izquierdo y mano extendidos hacia arriba fuera del vehículo, haciendo un ángulo recto con el brazo, significa que el vehículo:",
    options = listOf(
    "a) Volteará a la izquierda.",
    "b) Volteará a la derecha.",
    "c) Disminuirá la velocidad.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "La señal reguladora de \"PARE\" tiene la forma de un: ",
    options = listOf(
    "a) Octágono.",
    "b) Círculo.",
    "c) Hexágono",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Para girar a la derecha, en caso de un imprevisto, el conductor debe hacer la siguiente señal: ",
    options = listOf(
    "a) Brazo, antebrazo izquierdo y mano extendidos hacia afuera del vehículo. ",
    "b) Antebrazo izquierdo y mano extendidos hacia arriba fuera del vehículo, formando un ángulo recto con el brazo. ",
    "c) Brazo, antebrazo derecho y mano extendidos hacia afuera del vehículo. ",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "A cuál de las alternativas corresponde la definición: \"Es la vía fuera del ámbito Urbano, destinada a la circulación de vehículos y eventualmente peatones y animales\":",
    options = listOf(
    "a) Camino.",
    "b) Carretera.",
    "c) Berma.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "En las intersecciones semaforizada o controladas por efectivos de la PNP o por señales oficiales:",
    options = listOf(
    "a) El peatón tiene derecho de paso respecto a los vehículos que giren a la derecha o a la izquierda, con la luz verde.",
    "b) El vehículo siempre tiene derecho de paso. ",
    "c) a y b son correctas.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Cuál de las alternativas corresponde la definición: \"Es el área común de calzadas que se cruzan o convergen\". ",
    options = listOf(
    "a) Intersección.",
    "b) Calle. ",
    "c) Cruce a nivel.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "En caso de accidente de tránsito con daños personales a terceros, la Policía Nacional del Perú retendrá la licencia de conducir del conductor por el plazo máximo de ______,a fin de determinar la inobservancia de las normas de tránsito.",
    options = listOf(
    "a) 48 horas.",
    "b) 72 horas.",
    "c) 24 horas.",
    "d) 7 días.",
    ),
    result = "b"
    ),

    Question(
    question = "Corresponde la retención de la licencia de conducir cuando:",
    options = listOf(
    "a) En caso de accidente de tránsito con daños personales a terceros.",
    "b) Cuando el conductor haya llegado al tope máximo de doscientos (200) o más puntos firmes.",
    "c) Por la comisión de infracción sobre la que deba recaer la sanción de suspensión, cancelación definitiva e inhabilitación del titular de la Licencia de Conducir.",
    "d) a y c son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "Respecto a las medidas preventivas establecidas en el T.U.O de Tránsito marque la respuesta correcta:",
    options = listOf(
    "a) Tiene como objeto salvaguardar la seguridad y la vida de los usuarios de la vía.",
    "b) Es una medida impuesta junto con la papeleta de tránsito, cuando corresponda.",
    "c) La retención de la licencia de conducir y el internamiento del vehículo son medidas preventivas.",
    "d) Todas son correctas. ",
    ),
    result = "d"
    ),

    Question(
    question = "¿Cómo se denomina la medida preventiva, mediante el cual un vehículo es retirado de su ubicación en caso constituya un peligro o un obstáculo para el tránsito y la seguridad pública?",
    options = listOf(
    "a) Retención del vehículo.",
    "b) Remoción del vehículo.",
    "c) Internamiento del vehículo.",
    "d) Traslado del vehículo hacia el domicilio del propietario.",
    ),
    result = "b"
    ),

    Question(
    question = "Es el acto de inmovilización del vehículo en la Comisaría de la jurisdicción dispuesto por la Policía Nacional del Perú.",
    options = listOf(
    "a) Retención del vehículo.",
    "b) Remoción del vehículo.",
    "c) Internamiento del vehículo.",
    "d) Traslado del vehículo hacia el domicilio del propietario.",
    ),
    result = "a"
    ),

    Question(
    question = "La medida preventiva de retención del vehículo se aplica por un plazo máximo de: ",
    options = listOf(
    "a) 12 horas.",
    "b) 24 horas.",
    "c) 48 horas.",
    "d) 36 horas.",
    ),
    result = "b"
    ),

    Question(
    question = "La medida preventiva de internamiento del vehículo se aplica:",
    options = listOf(
    "a) Por la comisión de una infracción sobre la que expresamente recaiga esta medida en el Cuadro de Tipificación, Sanciones y Medidas Preventivas aplicables a las Infracciones al Tránsito Terrestre.",
    "b) Cuando el conductor se fuga abandonando el vehículo.",
    "c) Cuando no se hayan superado las faltas o deficiencias que motivaron la medida preventiva de retención del vehículo dentro del plazo máximo de veinticuatro (24) horas.",
    "d) Todas las anteriores.",
    ),
    result = "d"
    ),

    Question(
    question = "La medida preventiva de internamiento de un vehículo en el depósito vehicular culmina cuando: ",
    options = listOf(
    "a) Se subsane o se supere la deficiencia que la motivó, cuando corresponda. ",
    "b) Cuando se cancele la multa en los casos que de acuerdo con la norma, la sanción a imponer así lo prevea. ",
    "c) Se pague los derechos por permanencia en el depósito vehicular y remolque del vehículo. ",
    "d) Todas las anteriores.",
    ),
    result = "d"
    ),

    Question(
    question = "El conductor será sancionado con la suspensión de su licencia de conducir por ______, si acumula 100 puntos en un período de 24 meses, contados a partir de la fecha en que quedó firme en sede administrativa, la sanción vigente más antigua.",
    options = listOf(
    "a) 12 meses.",
    "b) 6 meses.",
    "c) 24 meses.",
    "d) 18 meses.",
    ),
    result = "b"
    ),

    Question(
    question = "Las infracciones leves que se encuentran tipificadas en el Texto Único Ordenado del Reglamento Nacional de Tránsito, cuántos puntos generan:",
    options = listOf(
    "a) De 1 a 40 puntos.",
    "b) De 1 a 20 puntos.",
    "c) De 1 a 15 puntos.",
    "d) De 1 a 25 puntos.",
    ),
    result = "b"
    ),

    Question(
    question = "Las infracciones graves que se encuentran tipificadas en el Texto Único Ordenado del Reglamento Nacional de Tránsito, cuántos puntos generan:",
    options = listOf(
    "a) De 20 a 50 puntos.",
    "b) De 16 a 50 puntos.",
    "c) De 15 a 30 puntos.",
    "d) De 1 a 20 puntos.",
    ),
    result = "a"
    ),

    Question(
    question = "Las infracciones muy graves que se encuentran tipificadas en el Texto Único Ordenado del Reglamento Nacional de Tránsito, generan de:",
    options = listOf(
    "a) De 20 a 50 puntos.",
    "b) De 1 a 30 puntos.",
    "c) De 50 a 100 puntos.",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Los puntos generados por cada infracción tendrán una vigencia de ______, contados desde la fecha en que quede firme la sanción en sede administrativa.",
    options = listOf(
    "a) 12 meses.",
    "b) 24 meses.",
    "c) 36 meses.",
    "d) 48 meses.",
    ),
    result = "b"
    ),

    Question(
    question = "El Texto Único Ordenado del Reglamento Nacional de Tránsito regula.",
    options = listOf(
    "a) El uso de las vías privadas terrestres, en cuanto se relacionan con el tránsito.",
    "b) El uso de las vías públicas terrestres, en cuanto se relacionan con el tránsito.",
    "c) El uso de las vías públicas y privadas, en cuanto se relacionan con el tránsito.",
    "d) El servicio de transporte terrestre de personas y mercancías en los diferentes ámbitos.",
    ),
    result = "b"
    ),

    Question(
    question = "Es parte de la vía destinada al uso de peatones:",
    options = listOf(
    "a) Berma.",
    "b) Calzada.",
    "c) Acera.",
    "d) Cuneta.",
    ),
    result = "c"
    ),

    Question(
    question = "es parte de la vía destinada a la circulación de vehículos y eventualmente al cruce de peatones y animales:",
    options = listOf(
    "a) Berma.",
    "b) Calzada.",
    "c) Acera.",
    "d) Cuneta.",
    ),
    result = "b"
    ),

    Question(
    question = "Es parte de una carretera o camino contigua a la calzada no habilitada para la circulación de vehículo: ",
    options = listOf(
    "a) Berma.",
    "b) Calzada.",
    "c) Acera.",
    "d) Cuneta.",
    ),
    result = "a"
    ),

    Question(
    question = "Es una Zanja al lado del camino o carretera destinada a recibir aguas pluviales:",
    options = listOf(
    "a) Berma.",
    "b) Calzada.",
    "c) Acera.",
    "d) Cuneta.",
    ),
    result = "d"
    ),

    Question(
    question = "Se entiende por carril a la:",
    options = listOf(
    "a) Parte de la vía destinada a la circulación de los vehículos y eventualmente al cruce de los peatones.",
    "b) Parte de la calzada destinada al tránsito de una fila de vehículos.",
    "c) Vía rural destinada a la circulación de vehículos, peatones y animales.",
    "d) Parte de una carretera o camino contigua a la calzada.",
    ),
    result = "b"
    ),

    Question(
    question = "Se entiende por carretera a la:",
    options = listOf(
    "a) Vías principales que se encuentran dentro del ámbito urbano destinadas a la circulación de vehículos.",
    "b) Vía fuera del ámbito urbano, destinada a la circulación de vehículos.",
    "c) Vías urbanas.",
    "d) todas las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "Se entiende por línea de parada a la:",
    options = listOf(
    "a) Línea transversal marcada en la calzada antes de la intersección que indica al conductor el límite para detener el vehículo acatando la señal correspondiente.",
    "b) Líneas que se encuentran en los lugares del estacionamiento.",
    "c) Líneas que los vehículos utilizan para embarcar y desembarcar pasajeros.",
    "d) Todas las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Se define como zona rígida al: ",
    options = listOf(
    "a) Área donde se prohíbe la circulación de vehículos.",
    "b) Área donde se prohíbe la circulación de peatones.",
    "c) Área de la vía en la que se prohíbe el estacionamiento de vehículos las 24 horas del día.",
    "d) Todas las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Cuál es la autoridad competente para aplicar las sanciones por acumulación de puntos en el ámbito urbano?",
    options = listOf(
    "a) Ministerio de Transportes y Comunicaciones.",
    "b) Policía Nacional del Perú.",
    "c) SUTRAN.",
    "d) Municipalidades Provinciales.",
    ),
    result = "d"
    ),

    Question(
    question = "¿Cuál es la autoridad que garantiza y controla la libre circulación en las vías públicas del territorio nacional?",
    options = listOf(
    "a) Los inspectores de transportes de la SUTRAN.",
    "b) Los inspectores de transportes de las Municipalidades Provinciales.",
    "c) La Policía Nacional del Perú.",
    "d) Las Municipalidades distritales.",
    ),
    result = "c"
    ),

    Question(
    question = "Son elementos de la vía: ",
    options = listOf(
    "a) La calzada, la acera y la berma.",
    "b) La cuneta y el estacionamiento.",
    "c) El separador central, el jardín y el equipamiento de servicios necesarios para su utilización.",
    "d) Todas las anteriores.",
    ),
    result = "d"
    ),

    Question(
    question = "Está prohibido en la vía: ",
    options = listOf(
    "a) Ejercer el comercio ambulatorio o estacionario. ",
    "b) Destinar las calzadas a otro uso que no sea el tránsito. ",
    "c) Recoger o dejar pasajeros en lugares no autorizados. ",
    "d) Todas las anteriores. ",
    ),
    result = "d"
    ),

    Question(
    question = "¿Cuál es la señal que tienen por finalidad guiar a los usuarios, proporcionándoles indicaciones que puedan necesitar durante su desplazamiento por la vía?",
    options = listOf(
    "a) Reglamentación.",
    "b) Advertencia.",
    "c) Información.",
    "d) Ninguna de las anteriores. ",
    ),
    result = "c"
    ),

    Question(
    question = "______ tienen, por lo general, forma rectangular, con su mayor dimensión horizontal. ",
    options = listOf(
    "a) Las señales reguladoras.",
    "b) Las señales preventivas.",
    "c) Las señales informativas.",
    "d) Ninguna de las anteriores. ",
    ),
    result = "c"
    ),

    Question(
    question = "La posición de frente ejecutada por el efectivo de la Policía Nacional del Perú, asignado al control de tránsito significa:",
    options = listOf(
    "a) Obligación de detenerse de quien así lo enfrente.",
    "b) Continuar la marcha por el carril izquierdo de la calzada.",
    "c) Continuar la marcha. ",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Las sanciones pecuniarias impuestas por la comisión de infracciones de tránsito pueden ser inscritas en las Centrales Privadas de Información de Riesgos, en los siguientes casos:",
    options = listOf(
    "a) Cuando no se paguen en el plazo establecido desde que la resolución de sanción adquirió firmeza.",
    "b) Cuando la sanción sea firme, es decir, no haya sido impugnada por el conductor infractor.",
    "c) Cuando no se paguen al día siguiente a partir del cual la resolución de sanción adquirió firmeza.",
    "d) a y b.",
    ),
    result = "d"
    ),

    Question(
    question = "En zonas urbanas, el límite máximo de velocidad en calles y jirones es de: ",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h ",
    ),
    result = "b"
    ),

    Question(
    question = "En zonas urbanas, el límite máximo de velocidad en avenidas es de:",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h ",
    ),
    result = "c"
    ),

    Question(
    question = "En zonas urbanas, el límite máximo de velocidad en vías expresas es de:",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h",
    ),
    result = "d"
    ),

    Question(
    question = "En zonas urbanas, el límite máximo de velocidad en zona escolar es de:",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h ",
    ),
    result = "a"
    ),

    Question(
    question = "En zonas urbanas, el límite máximo de velocidad en zona de hospital es de:",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h",
    ),
    result = "a"
    ),

    Question(
    question = "En carreteras, el límite máximo de velocidad para automóviles, camionetas y motocicletas es de: ",
    options = listOf(
    "a) 100 km/h ",
    "b) 120 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h ",
    ),
    result = "a"
    ),

    Question(
    question = "En caminos rurales, el límite máximo de velocidad es de:",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h ",
    ),
    result = "c"
    ),

    Question(
    question = "En las intersecciones urbanas no semaforizadas, el límite máximo de velocidad es de: ",
    options = listOf(
    "a) 30 km/h ",
    "b) 40 km/h ",
    "c) 60 km/h ",
    "d) 80 km/h ",
    ),
    result = "a"
    ),

    Question(
    question = "El límite mínimo de velocidad en zona urbana y carreteras es de: ",
    options = listOf(
    "a) 30 km/h. ",
    "b) La mitad del máximo fijado para cada tipo de vía.",
    "c) 20 km/h.",
    "d) 15 km/h.",
    ),
    result = "b"
    ),

    Question(
    question = "La sanción por conducir con presencia de alcohol en la sangre en proporción mayor a lo previsto en el Código Penal, o bajo los efectos de estupefacientes, narcóticos y/o alucinógenos comprobado con el examen respectivo o por negarse al mismo y que haya participado en un accidente de tránsito es:",
    options = listOf(
    "a) Inhabilitación para obtener una licencia de conducir por 3 años.",
    "b) Cancelación de licencia de conducir e inhabilitación definitiva para obtener una licencia de conducir.",
    "c) Multa de 100 % de la UIT (S/. 4,050.00).",
    "d) b y c son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "La sanción por conducir con presencia de alcohol en la sangre en proporción mayor a lo previsto en el Código Penal, bajo los efectos de estupefacientes, narcóticos y/o alucinógenos comprobada con el examen respectivo o por negarse al mismo es:",
    options = listOf(
    "a) Inhabilitación para obtener una licencia de conducir por 3 años.",
    "b) Suspensión de la licencia de conducir por 1 año y multa de 50% de la UIT (S/. 2,025.00).",
    "c) Suspensión de la licencia de conducir por 3 años y multa de 50% de la UIT (S/. 2,025.00).",
    "d) Ninguna es correcta.",
    ),
    result = "c"
    ),

    Question(
    question = "La sanción por conducir un vehículo automotor sin tener licencia de conducir o permiso provisional es: ",
    options = listOf(
    "a) Inhabilitación para obtener una licencia de conducir por 3 años.",
    "b) Multa de 50% de la UIT (S/. 2,025.00).",
    "c) Inhabilitación para obtener una licencia de conducir por 1 año y multa del 50% de la UIT (S/. 2,025.00).",
    "d) a y b.",
    ),
    result = "d"
    ),

    Question(
    question = "La sanción por conducir vehículos estando la licencia de conducir retenida, suspendida o estando inhabilitado para obtener licencia de conducir es:",
    options = listOf(
    "a) Suspensión de la licencia de conducir por 3 años, si ésta estuviese retenida y cancelación definitiva de la licencia de conducir, si la licencia estuviere suspendida.",
    "b) Suspensión de la licencia de conducir por 1 año, si ésta estuviese retenida y multa de 50% de la UIT (S/. 2,025.00).",
    "c) Multa de 100% de la UIT (S/. 4,050.00).",
    "d) a y c son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "Las inspecciones técnicas vehiculares se realizan según el ______ dígito de la placa única nacional de rodaje: ",
    options = listOf(
    "a) Primer.",
    "b) Último.",
    "c) Penúltimo.",
    "d) Primer y último. ",
    ),
    result = "b"
    ),

    Question(
    question = "Señale la respuesta correcta respecto a la frecuencia, antigüedad y vigencia de las inspecciones técnicas vehiculares:",
    options = listOf(
    "a) Varia dependiendo la categoría del vehículo.",
    "b) La mayoría de vehículos deben pasar la inspección técnica a partir del noveno año de antigüedad.",
    "c) La vigencia del certificado de inspección técnica puede ser 6 o 12 meses dependiendo la categoría del vehículo.",
    "d) a y c son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "Las Inspecciones Técnicas Vehiculares se realizan de acuerdo a los siguientes factores:",
    options = listOf(
    "a) Categoría de los vehículos.",
    "b) Función de los vehículos.",
    "c) Antigüedad de los vehículos.",
    "d) Todas las anteriores.",
    ),
    result = "d"
    ),

    Question(
    question = "Una observación _______ en la inspección técnica vehicular, es aquella de carácter documentario y/o técnico que no exige una nueva inspección técnica vehicular, debiendo subsanarse las observaciones efectuadas antes de la siguiente Inspección Técnica Vehicular.",
    options = listOf(
    "a) Grave.",
    "b) Muy grave.",
    "c) Leve.",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Una observación ______ en la inspección técnica vehicular, es aquella que ocasiona la desaprobación de la Inspección Técnica Vehicular, exigiendo su subsanación mediante una reinspección sobre las deficiencias consignadas dentro del plazo máximo de 30 días.",
    options = listOf(
    "a) Grave.",
    "b) Muy grave.",
    "c) Leve. ",
    "d) Ninguna de las anteriores. ",
    ),
    result = "a"
    ),

    Question(
    question = "Una observación ______, en la inspección técnica vehicular, es aquella que ocasionan la desaprobación de la Inspección Técnica Vehicular, debiéndose trasladar el vehículo al taller de mantenimiento mecánico, que determine el propietario o conductor, para la subsanación de la misma.",
    options = listOf(
    "a) Grave.",
    "b) Muy grave.",
    "c) Leve. ",
    "d) Ninguna de las anteriores. ",
    ),
    result = "b"
    ),

    Question(
    question = "¿A que se denomina Inspección Técnica Ordinaria? ",
    options = listOf(
    "a) Es la que se exige para la inmatriculación. ",
    "b) Es la que debe cumplir todo vehículo que circula por las vías. ",
    "c) Ninguna de las anteriores.",
    "d) Sólo a) y b) son correctas.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Por cuantos caracteres esta constituido el Número de Identificación Vehicular?",
    options = listOf(
    "a) 13 ",
    "b) 15 ",
    "c) 16 ",
    "d) 17 ",
    ),
    result = "d"
    ),

    Question(
    question = "El Reglamento Nacional de Vehículos, señala que el parachoques delantero y posterior: ",
    options = listOf(
    "a) Puede contar con filos angulares cortantes.",
    "b) No debe exceder el ancho del vehículo.",
    "c) Puede o no exceder el ancho del vehículo.",
    "d) Puede exceder el ancho del vehículo pero sin filos angulares cortantes.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Qué definición corresponde a luz alta en el Reglamento Nacional de Vehículos?",
    options = listOf(
    "a) Luz utilizada para alumbrar una mayor distancia de la vía por delante del vehículo, también denominada de carretera.",
    "b) Luz utilizada para alumbrar una mayor distancia de la vía, también denominada luz complementaria.",
    "c) Haz de luz abierto y de corto alcance ubicado en la parte delantera del vehículo para alumbrar la carretera en condiciones de neblina.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "El Reglamento Nacional de Vehículos define a los frenos como:",
    options = listOf(
    "a) Conjunto de elementos del vehículo que permite reducir la velocidad, detener o asegurar la parada del vehículo.",
    "b) Conjunto de elementos del vehículo que permite reducir la velocidad para detener el vehículo, sin que ello signifique asegurar la parada del vehículo.",
    "c) Conjunto de elementos del vehículo que sirve solo para detener completamente el vehículo.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "¿Cuáles son los códigos de identificación vehicular?",
    options = listOf(
    "a) Número de identificación vehicular, número de serie, número de motor.",
    "b) Número de chasis, placa de rodaje, numero de motor.",
    "c) VIN, número de chasis, placa de rodaje.",
    "d) Solo número de chasis y VIN.",
    ),
    result = "a"
    ),

    Question(
    question = "Todos los vehículos deben tener la configuración original de fábrica para el tránsito por el lado ______ de la vía. ",
    options = listOf(
    "a) Izquierdo.",
    "b) Derecho.",
    "c) Derecho o izquierdo según su configuración de fábrica.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "Los vehículos que circulan por las vías del SNTT, deben contar con carrocería diseñada para evitar salpicaduras de las ruedas y/o protegida por guardafangos:",
    options = listOf(
    "a) Sí. ",
    "b) No. ",
    "c) Depende del tamaño del vehículo. ",
    "d) No se encuentra regulado si debe contar o no.",
    ),
    result = "a"
    ),

    Question(
    question = "Los vehículos podrán contar con accesorios (defensas especiales delanteras, barras antivuelco, parrillas de techo, alerones, spoilers, viseras, estribos, etc.) siempre y cuando estos tengan bordes ______.",
    options = listOf(
    "a) Redondeados y con ángulos salientes para su visibilidad.",
    "b) Redondeados y con ciertas aristas. ",
    "c) Redondeados.",
    "d) Cuadrados o rectos, sin ángulos que sobre salgan del vehículo.",
    ),
    result = "c"
    ),

    Question(
    question = "Los vehículos podrán contar con accesorios (defensas especiales delanteras, barras antivuelco, parrillas de techo, alerones, spoilers, viseras, estribos, etc.) siempre y cuando estos no excedan más de ______ de los extremos delantero y posterior del vehículo.",
    options = listOf(
    "a) 250 mm",
    "b) 200 mm",
    "c) 100 mm",
    "d) 150 mm",
    ),
    result = "b"
    ),

    Question(
    question = "Sobre el limpiaparabrisas y lavaparabrisas es correcto: ",
    options = listOf(
    "a) Como mínimo deben cubrir solo el área frente al piloto. ",
    "b) Como mínimo deben cubrir el área frente al piloto y copiloto.",
    "c) El limpiaparabrisas debe cubrir el área del piloto y copiloto, y el lavabrisas solo el área frente al piloto.",
    "d) No esta regulado en el área mínima, solo la exigibilidad de tener un solo limpiaparabrisas para el piloto.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Cuales son las herramientas como mínimo que debe tener un vehículo para cambiar la rueda?, según el Reglamento Nacional de Vehículos:",
    options = listOf(
    "a) Gata completa que soporte al menos el 30% del peso bruto del vehículo y llave de ruedas.",
    "b) Solo se exige la gata completa que soporte al menos el 40% del peso bruto del vehículo.",
    "c) Gata completa que soporte el peso bruto del vehículo, llave de ruedas y seguro de las ruedas.",
    "d) Set de llaves hexagonales y gata completa que soporte al menos el 30% del peso neto del vehículo.",
    ),
    result = "a"
    ),

    Question(
    question = "¿A qué se consideran Vías Terrestres según el Reglamento Nacional de Vehículos?",
    options = listOf(
    "a) Sistema de vías públicas incluyendo las concesionadas, así como las privadas, por donde circulan los vehículos, a excepción de las vías férreas.",
    "b) Vía en que los vehículos y las personas sólo tienen oportunidad a ingresar o salir de ella, por los lugares y bajo las condiciones fijadas por la Autoridad competente.",
    "c) Sistema de vías públicas, incluyendo las vías férreas, sin considerar las concesionadas y las privadas.",
    "d) Vía dentro del ámbito urbano, destinada a la circulación de vehículos y peatones y eventualmente de animales.",
    ),
    result = "a"
    ),

    Question(
    question = "Quedan excluidos de la cobertura del Seguro Obligatorio de Accidentes de Tránsito, los accidentes:",
    options = listOf(
    "a) Causados en carreras de automóviles y otras competencias de vehículos motorizados.",
    "b) En lugares fuera del territorio nacional.",
    "c) En intersecciones sin señalización apropiada. ",
    "d) a y b son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "¿Que obligación tiene el conductor o propietario del vehículo de producirse un accidente con un vehículo coberturado con SOAT?",
    options = listOf(
    "a) Dar aviso a los bomberos.",
    "b) Dar aviso solo a la compañía de seguros.",
    "c) Dar aviso a la compañía de seguros y dejar constancia en la delegación de la PNP más cercana.",
    "d) Dar aviso solo a la PNP.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Qué vehículos se encuentran obligados a contratar un SOAT?",
    options = listOf(
    "a) Motorizados y no motorizados.",
    "b) Vehículos automotores con excepción de los vehículos automotores de 2 ruedas, de hasta 50 cm3.",
    "c) Todo vehículo automotor que circule por el territorio nacional.",
    "d) Todo vehículo automotor con excepción de los vehículos oficiales.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Cuál es el plazo de vigencia del SOAT? ",
    options = listOf(
    "a) 1 año ",
    "b) 6 meses ",
    "c) 4 años ",
    "d) 2 años ",
    ),
    result = "a"
    ),

    Question(
    question = "¿Es requisito que se investigue previamente el accidente para que se brinde la cobertura del SOAT o CAT?",
    options = listOf(
    "a) No.",
    "b) Solo si hay víctimas mortales.",
    "c) Solo si el conductor se encontraba en presunto estado de ebriedad.",
    "d) Sí. ",
    ),
    result = "a"
    ),

    Question(
    question = "¿Cuál es los plazos que cuenta la compañía de seguros o la AFOCAT para pagar los gastos o indemnizaciones?",
    options = listOf(
    "a) 15 días ",
    "b) 10 días ",
    "c) 5 días ",
    "d) 30 días ",
    ),
    result = "b"
    ),

    Question(
    question = "¿Ante quién denuncio la falta de pago de coberturas por parte de la compañía de seguros?",
    options = listOf(
    "a) Ante el MTC.",
    "b) Ante la SBS.",
    "c) Ante el INDECOPI.",
    "d) b y c son correctas.",
    ),
    result = "c"
    ),

    Question(
    question = "Si usted contrata un SOAT en la \"Región A\" y realiza un viaje con su vehículo a la \"Región B\", en la cual tiene un accidente de tránsito. ¿Se encuentra coberturado por el SOAT?",
    options = listOf(
    "a) No, porque el SOAT solo tiene validez para la Región en donde fue contratado.",
    "b) Es válida la cobertura solo en Regiones continuas.",
    "c) Sí, porque la cobertura del SOAT es de alcance nacional.",
    "d) Depende de las condiciones en las cuales se contrató el SOAT.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Qué vehículos se encuentran obligados a exhibir la Placa Única de Rodaje? ",
    options = listOf(
    "a) Todos los vehículos automotores.",
    "b) Vehículos de transporte terrestre con excepción de los remolques y semiremolques.",
    "c) Todo vehículo que circule por las vías públicas, con excepción a los semiremolques.",
    "d) Todo vehículo motorizado y no motorizado que circule en las vías públicas terrestre.",
    ),
    result = "d"
    ),

    Question(
    question = "Los remolques y semiremolques llevan la placa del vehículo automotor que los hala. ",
    options = listOf(
    "a) En ningún caso.",
    "b) Sí. ",
    "c) Depende del tipo de estructura que se remolca. ",
    "d) Solo los remolques con un peso bruto menor de 750 Kg.",
    ),
    result = "d"
    ),

    Question(
    question = "¿Se puede añadir elementos adicionales a la placa única de rodaje? ",
    options = listOf(
    "a) No.",
    "b) Sí.",
    "c) Sí, previa autorización de la autoridad competente.",
    "d) Sí, siempre que sea de un color distinto a los elementos obligatorios de la placa única de rodaje.",
    ),
    result = "a"
    ),

    Question(
    question = "¿Qué tiempo de vigencia tiene la placa única de rodaje? ",
    options = listOf(
    "a) 15 años.",
    "b) 20 años.",
    "c) Ilimitada.",
    "d) 15 años con renovación automática.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Qué entidad realiza la entrega de la placa única de rodaje?",
    options = listOf(
    "a) SUNARP.",
    "b) SUNARP y MTC.",
    "c) MTC, a través de una Entidad Concesionaria.",
    "d) a y c son correctas.",
    ),
    result = "c"
    ),

    Question(
    question = "¿En qué lugar del vehículo deber ser colocada la placa única de rodaje?",
    options = listOf(
    "a) En la parte delantera y posterior del vehículo.",
    "b) Las planchas metálicas en la parte delantera y posterior del vehículo, y la calcomanía holográfica (tercera placa) en la parte superior derecha del parabrisas delantero.",
    "c) Las planchas metálicas en la parte delantera y posterior del vehículo, y la calcomanía holográfica (tercera placa) en la parte inferior derecha del parabrisas delantero.",
    "d) Las planchas metálicas en la parte delantera y posterior del vehículo, y la calcomanía holográfica (tercera placa) en la parte inferior derecha del parabrisas trasero.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Cuál es la primera acción a realizar en caso de un accidente de tránsito?",
    options = listOf(
    "a) Examinar a las víctimas.",
    "b) Inmovilizar a las víctimas.",
    "c) Llamar a los números de emergencia (116 Bomberos; 105 Policía).",
    "d) Todas las anteriores",
    ),
    result = "c"
    ),

    Question(
    question = "¿Cómo mantener el estado de conciencia de una persona herida en un accidente de tránsito?",
    options = listOf(
    "a) Aplicando paños de agua fría en la nuca.",
    "b) Realizando constantes preguntas; tales como: ¿Cómo te llamas?, ¿Dónde estás?, etc.",
    "c) Aplicando paños de agua fría en la frente.",
    "d) Tomando su pulso.",
    ),
    result = "b"
    ),

    Question(
    question = "Una vez contactado al número de emergencia, ¿qué acciones debe realizar con una persona herida en un accidente de tránsito?",
    options = listOf(
    "a) Girar su torso hacia la derecha. ",
    "b) Inmovilizar al herido.",
    "c) Aflojar sus ropas, correas y/o corbatas que puedan estar oprimiendo el cuello y vías respiratorias.",
    "d) b y c son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "¿Cuál es la manera correcta de verificar el pulso de una persona herida?",
    options = listOf(
    "a) Colando los dedos índice y medio sobre un lado del cuello, presionando ligeramente las arterias carótidas.",
    "b) Realizando una leve presión con la palma de la mano en la parte central del pecho del herido.",
    "c) Colocando uno de las orejas en el pecho de la víctima para escuchar los latidos del corazón.",
    "d) N.A ",
    ),
    result = "a"
    ),

    Question(
    question = "Para detener una hemorragia profusa se debe: ",
    options = listOf(
    "a) Poner una venda en la zona afectada del cuerpo.",
    "b) Lavar con agua la zona afectada del cuerpo para quitar cualquier residuo que pueda infectar la herida.",
    "c) Hacer compresión en la zona afectada.",
    "d) Todas las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Es una licencia de conducir que se obtiene por otorgamiento directo:",
    options = listOf(
    "a) Clase A Categoría II A",
    "b) Clase A Categoría III C",
    "c) Clase A Categoría II B",
    "d) Clase A Categoría I",
    ),
    result = "d"
    ),

    Question(
    question = "Es una licencia de conducir que se obtiene por recategorización:",
    options = listOf(
    "a) Clase A Categoría I",
    "b) Clase A Categoría III C",
    "c) Clase B Categoría II B",
    "d) Clase B Categoría I",
    ),
    result = "b"
    ),

    Question(
    question = "Es una licencia de conducir que se obtiene por recategorización:",
    options = listOf(
    "a) Clase A Categoría I",
    "b) Clase A Categoría II A",
    "c) Clase B Categoría II B",
    "d) Clase B Categoría I",
    ),
    result = "b"
    ),

    Question(
    question = "Es una licencia de conducir que se obtiene por recategorización:",
    options = listOf(
    "a) Clase A Categoría I",
    "b) Clase A Categoría II B",
    "c) Clase B Categoría II B ",
    "d) Clase B Categoría I",
    ),
    result = "b"
    ),

    Question(
    question = "Es una licencia de conducir que se obtiene por recategorización:",
    options = listOf(
    "a) Clase A Categoría I ",
    "b) Clase A Categoría III A ",
    "c) Clase B Categoría II B ",
    "d) Clase B Categoría I ",
    ),
    result = "b"
    ),

    Question(
    question = "Es una licencia de conducir que se obtiene por recategorización:",
    options = listOf(
    "a) Clase A Categoría I ",
    "b) Clase A Categoría III B ",
    "c) Clase B Categoría II B",
    "d) Clase B Categoría I ",
    ),
    result = "b"
    ),

    Question(
    question = "No es un requisito para la revalidación de la licencia de conducir:",
    options = listOf(
    "a) Aprobación del examen de habilidades en la conducción.",
    "b) No contar con multas pendientes de pago por infracciones de tránsito.",
    "c) Aprobación del examen de conocimientos.",
    "d) No estar suspendido o inhabilitado, según la información del Registro Nacional de Sanciones.",
    ),
    result = "a"
    ),

    Question(
    question = "Es un requisito para la revalidación de la licencia de conducir:",
    options = listOf(
    "a) No contar con multas pendientes de pago por infracciones de tránsito.",
    "b) Presentar constancia de estudios.",
    "c) Presentar certificado de antecedentes penales o judiciales.",
    "d) Acreditar experiencia previa en la conducción.",
    ),
    result = "a"
    ),

    Question(
    question = "No es un requisito para la revalidación de la licencia de conducir:",
    options = listOf(
    "a) Acreditar experiencia previa en la conducción.",
    "b) No contar con multas pendientes de pago.",
    "c) Aprobación del examen de conocimientos.",
    "d) No estar suspendido o inhabilitado, según la información del Registro Nacional de Sanciones.",
    ),
    result = "a"
    ),

    Question(
    question = "Es un requisito para la revalidación de la licencia de conducir:",
    options = listOf(
    "a) No estar suspendido o inhabilitado, según la información del Registro Nacional de Sanciones.",
    "b) Presentar récord del conductor.",
    "c) Presentar certificado de antecedentes penales o judiciales.",
    "d) Acreditar experiencia previa en la conducción.",
    ),
    result = "a"
    ),

    Question(
    question = "No es un requisito para la revalidación de la licencia de conducir:",
    options = listOf(
    "a) Presentar constancia de estudios.",
    "b) No contar con multas pendientes de pago por infracciones de tránsito.",
    "c) Aprobación del examen de conocimientos.",
    "d) No estar suspendido o inhabilitado, según la información del Registro Nacional de Sanciones.",
    ),
    result = "a"
    ),

    Question(
    question = "Es un requisito para la revalidación de la licencia de conducir:",
    options = listOf(
    "a) Certificado de Salud para licencias de conducir.",
    "b) Presentar récord del conductor.",
    "c) Presentar certificado de antecedentes penales o judiciales.",
    "d) Acreditar experiencia previa en la conducción.",
    ),
    result = "a"
    ),

    Question(
    question = "La vigencia del certificado de salud para licencias de conducir es de ______ para que el postulante concluya satisfactoriamente la obtención de todos los demás requisitos establecidos en el presente reglamento.",
    options = listOf(
    "a) 3 meses ",
    "b) 6 meses ",
    "c) 9 meses ",
    "d) 1 año",
    ),
    result = "b"
    ),

    Question(
    question = "La solicitud de revalidación de la licencia de conducir puede presentarse dentro de los ______ días calendario anteriores a la fecha de revalidación consignada en la licencia de conducir o en cualquier momento después de culminado su plazo de vigencia, sin perjuicio de la prohibición de conducción con una licencia vencida.",
    options = listOf(
    "a) 15 ",
    "b) 60 ",
    "c) 90 ",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "Como consecuencia del diagnóstico efectuado en la evaluación médica y psicológica, se pueden consignar restricciones en la licencia de conducir como las siguientes:",
    options = listOf(
    "a) Con lentes correctores externos o de contacto.",
    "b) Vehículos automotores especialmente acondicionados.",
    "c) Todas las anteriores.",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Como consecuencia del diagnóstico efectuado en la evaluación médica y psicológica, se pueden consignar restricciones en la licencia de conducir como las siguientes:",
    options = listOf(
    "a) Con dos espejos retrovisores laterales y espejo con ángulo de 180 grados.",
    "b) Con audífonos.",
    "c) Todas las anteriores.",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Es un beneficio de la conducción eficiente:",
    options = listOf(
    "a) Reducir el consumo de combustible.",
    "b) Reducir la emisión de gases contaminantes.",
    "c) Reducir la tasa de accidentes.",
    "d) Todas las anteriores.",
    ),
    result = "d"
    ),

    Question(
    question = "Según la técnica de conducción eficiente, ¿cuando se enciende el motor del vehículo?",
    options = listOf(
    "a) Antes de colocarse el cinturón de seguridad.",
    "b) Al mismo tiempo que se regula el retrovisor.",
    "c) Cuando todo este listo para partir.",
    "d) Antes de alistarte para partir.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Como se debe arrancar un vehículo? ",
    options = listOf(
    "a) Pisando el acelerador un poco.",
    "b) Sin pisar el acelerador.",
    "c) Es igual porque lo hace la electrónica.",
    "d) En ralentí.",
    ),
    result = "b"
    ),

    Question(
    question = "Tener un neumático (llanta) con una presión por debajo de la que indica el fabricante ______ un ______ consumo de combustible.",
    options = listOf(
    "a) Ocasiona/menor.",
    "b) Ocasiona/mayor.",
    "c) No ocasiona/mayor.",
    "d) Ninguna de las anteriores.",
    ),
    result = "b"
    ),

    Question(
    question = "Tener un neumático (llanta) con una presión por encima de la que indica el fabricante ______ la amortiguación propia del neumático.",
    options = listOf(
    "a) Aumenta.",
    "b) Mantiene. ",
    "c) Reduce. ",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "Un uso excesivo del freno, ______ el consumo de combustible.",
    options = listOf(
    "a) Optimiza.",
    "b) Disminuye.",
    "c) Incrementa.",
    "d) Ninguno de los anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "¿A qué se denomina conducción agresiva?",
    options = listOf(
    "a) Imprudencia al conducir.",
    "b) Continuas aceleraciones y frenadas bruscas. ",
    "c) Conducir en estricto cumplimiento de la normativa.",
    "d) a y b son correctas.",
    ),
    result = "b"
    ),

    Question(
    question = "Tener un neumático (llanta) con una presión por debajo de la que indica el fabricante ______ un ______ desgaste.",
    options = listOf(
    "a) Ocasiona/mayor.",
    "b) Ocasiona/menor. ",
    "c) No ocasiona/mayor. ",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Marque la respuesta correcta respecto del Tacómetro de un vehículo:",
    options = listOf(
    "a) Indica la velocidad del giro del motor en revoluciones por minuto (RPM) y refleja su funcionamiento.",
    "b) Su vigilancia permite que el conductor realice los cambios de marcha en los rangos recomendados.",
    "c) Ayuda a reducir el consumo de combustible.",
    "d) Todas las anteriores.",
    ),
    result = "d"
    ),

    Question(
    question = "Según el proyecto Planificación ante el Cambio Climático del Perú, el sector transportes es la fuente más importante de ______, específicamente el ______.",
    options = listOf(
    "a) Emisiones de CO2/Transporte Terrestre.",
    "b) Ingresos de divisas para el país/Transporte Acuático.",
    "c) Emisiones de CO2/Transporte Acuático.",
    "d) Ninguna de las anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "Es una técnica de conducción eficiente: ",
    options = listOf(
    "a) Evitar circular en neutro, sobre todo en bajadas.",
    "b) Pisar a fondo el acelerador.",
    "c) Mantener una velocidad constante evitando aceleraciones fuertes y frenadas bruscas.",
    "d) Solo la b es incorrecta",
    ),
    result = "d"
    ),

    Question(
    question = "Marque la respuesta correcta respecto a las siguientes premisas:",
    options = listOf(
    "a) La seguridad de las personas es más importante que la conducción eficiente.",
    "b) Debe mantenerse la velocidad constante aunque esto implique avanzar rápidamente en esquinas y ante el cruce de peatones.",
    "c) Siempre se debe priorizar la seguridad antes que la economía del combustible.",
    "d) a y c son correctas.",
    ),
    result = "d"
    ),

    Question(
    question = "Si se encuentra detenido por más de 1 minuto se recomienda.",
    options = listOf(
    "a) Apagar el motor del vehículo.",
    "b) Poner el motor del vehículo en neutro.",
    "c) Poner en ralentí el vehículo. ",
    "d) Ninguna de los anteriores.",
    ),
    result = "a"
    ),

    Question(
    question = "La baja presión de los neumáticos de un vehículo:",
    options = listOf(
    "a) Disminuirá el rozamiento y el calentamiento de los neumáticos.",
    "b) Aumentará el rozamiento y el calentamiento de los neumáticos.",
    "c) Disminuirá el rozamiento y aumentará el enfriamiento de los neumáticos.",
    "d) Reducirá la velocidad.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Qué es importante para un correcto mantenimiento básico del vehículo? ",
    options = listOf(
    "a) Llevar frecuentemente el vehículo al taller.",
    "b) Que el conductor sepa realizar ciertas reparaciones del vehículo.",
    "c) Realizar una inspección visual y controles básicos diarios.",
    "d) Llevar al taller del concesionario autorizado.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Qué indica la viscosidad de un aceite?",
    options = listOf(
    "a) La capacidad de pegarse sobre las superficies a lubricar. ",
    "b) La mayor o menor facilidad para pasar por las canalizaciones. ",
    "c) La capacidad de no descomponerse y permanecer inalterable con el tiempo. ",
    "d) Su capacidad higroscópica.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Qué es un cuenta revoluciones en el panel de instrumentos?",
    options = listOf(
    "a) Un indicador del número de vueltas que da el motor por una unidad de tiempo.",
    "b) Un indicador del número de vueltas que da el neumático por una unidad de tiempo.",
    "c) Un indicador del número de vueltas que da el diferencial por una unidad de tiempo.",
    "d) Un indicador del número de vueltas que da la caja de cambios por una unidad de tiempo.",
    ),
    result = "a"
    ),

    Question(
    question = "¿Cómo se deben ajustar las tuercas de las ruedas? ",
    options = listOf(
    "a) No tiene orden.",
    "b) De forma cruzada.",
    "c) En secuencia.",
    "d) De atrás hacia delante.",
    ),
    result = "b"
    ),

    Question(
    question = "Con el vehículo en funcionamiento, que indica la luz roja en forma de batería:",
    options = listOf(
    "a) Que la batería esta descargada.",
    "b) El alternador dejo de funcionar.",
    "c) Que la batería esta desconectada.",
    "d) Que la batería se encuentra con mucha carga.",
    ),
    result = "b"
    ),

    Question(
    question = "El refrigerante circula a través del motor impulsado por: ",
    options = listOf(
    "a) Bomba de agua. ",
    "b) Presión de aire. ",
    "c) Pistón de vacío. ",
    "d) Bomba de alimentación.",
    ),
    result = "a"
    ),

    Question(
    question = "¿Qué entiende por panel de instrumentos?",
    options = listOf(
    "a) Señales de control en el tablero. ",
    "b) Indicadores luminosos al alcance de la vista. ",
    "c) Todas las anteriores. ",
    "d) Ninguna de las anteriores.",
    ),
    result = "c"
    ),

    Question(
    question = "¿Los vehículos de categoría L deben contar con dispositivos para descansar los pies del conductor y de las personas transportadas?",
    options = listOf(
    "a) Sí.",
    "b) No.",
    "c) Depende de la carrocería del vehículo de categoría L.",
    "d) Solo deben contar con dispositivos para descansar los pies del conductor, no siendo obligatorio para las personas transportadas.",
    ),
    result = "a"
    ),

    Question(
    question = "¿La Luz baja de un vehículo de categoría L debe ser de color?",
    options = listOf(
    "a) Solo Blanco.",
    "b) Solo Amarillo.",
    "c) Cualquier color siempre que no sean centellantes.",
    "d) Blanco o Amarillo.",
    ),
    result = "c"
    ),

    Question(
    question = "Según la clasificación vehicular, un vehículo automotor de tres ruedas se clasifica en la(s) categoría(s):",
    options = listOf(
    "a) L1, L2 y L3. ",
    "b) L2, L4 y L5 ",
    "c) L3 y L5 ",
    "d) L2, L3 y L5 ",
    ),
    result = "b"
    ),

    Question(
    question = "¿A qué distancia como mínimo debe ser audible el sonido de la bocina para los vehículos de categoría L? ",
    options = listOf(
    "a) 100 metros ",
    "b) 50 metros ",
    "c) 30 metros",
    "d) No existe mínimo siempre que estén dentro de los límites máximos permisibles para emisiones sonoras.",
    ),
    result = "b"
    ),

    Question(
    question = "¿Qué color esta permitido para la luz alta adicional en un vehículo de categoría L? ",
    options = listOf(
    "a) Solo Blanco.",
    "b) Blanco o Amarillo.",
    "c) Blanco, Amarillo o Azul.",
    "d) Solo Amarillo.",
    ),
    result = "b"
    ),

    Question(
    question = "La licencia de conducir clase B categoría II C permite conducir vehículos señalados en la categoría … ",
    options = listOf(
    "a) B II a ",
    "b) B II b ",
    "c) A I ",
    "d) Sólo a) y b) ",
    ),
    result = "d"
    ),

    Question(
    question = "La licencia de conducir clase B categoría II B autoriza a conducir vehículos de la categoría …. ",
    options = listOf(
    "a) L3 ",
    "b) L4",
    "c) L5",
    "d) Sólo a) y b)",
    ),
    result = "d"
    ),

    Question(
    question = "La licencia de conducir Clase B categoría II A, autoriza conducir vehículos de la categoría …. ",
    options = listOf(
    "a) L1",
    "b) L2",
    "c) L5 ",
    "d) Sólo a) y b) ",
    ),
    result = "d"
    ),

    Question(
    question = "Las licencias de conducir de Clase B tienen una vigencia de …. ",
    options = listOf(
    "a) 3 años ",
    "b) 5 años ",
    "c) 8 años ",
    "d) 2 años ",
    ),
    result = "b"
    ),

    Question(
    question = "El grado de instrucción requerido para obtener una licencia de conducir B-II A es: ",
    options = listOf(
    "a) Primaria incompleta.",
    "b) Primaria completa.",
    "c) Secundaria incompleta.",
    "d) Secundaria completa.",
    ),
    result = "b"
    ),

    Question(
    question = "Es un requisito para la obtención de una licencia de conducir B-II A:",
    options = listOf(
    "a) Tener una edad mínima de 18 años.",
    "b) Presentar constancia de estudios que acredite que el postulante culminó la secundaria. ",
    "c) Presentar una declaración jurada de no tener antecedentes penales ni judiciales.",
    "d) Presentar certificado de no tener antecedentes penales ni judiciales.",
    ),
    result = "a"
    ),

    )
}