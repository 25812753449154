package com.sunday.kmplicenseperu.components

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.Black10Percent
import com.sunday.kmplicenseperu.models.Theme.HalfBlack
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimaryDark
import com.sunday.kmplicenseperu.models.Theme.TextPrimary
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.models.Theme.White10Percent
import com.sunday.kmplicenseperu.pages.StudyRange
import com.sunday.kmplicenseperu.pages.home.HomeState
import com.sunday.kmplicenseperu.pages.home.HomeVM
import com.sunday.kmplicenseperu.pages.questions.QuestionsVM
import com.sunday.kmplicenseperu.styles.MainButtonStyle
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.ExamStudy
import com.sunday.kmplicenseperu.util.Materia
import com.sunday.kmplicenseperu.util.studyRangeAppear
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.disabled
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Button

@Composable
fun MyDialog(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint,
    darkMode: Boolean,
    dialogContent: @Composable () -> Unit
) {
    val surfacePrimary = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb
    val shadowColor = if(!darkMode) Black10Percent.color.rgb
    else White10Percent.color.rgb

    val width = when(breakpoint) {
        Breakpoint.ZERO -> 90.percent
        Breakpoint.SM -> 80.percent
        Breakpoint.MD -> 60.percent
        Breakpoint.LG -> 40.percent
        Breakpoint.XL -> 30.percent
    }

    Box(
        modifier = modifier.fillMaxSize()
            .width(100.vw) // viewport-width
            .height(100.vh) // viewport-height
            .position(Position.Fixed)
            .zIndex(19),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(HalfBlack.color.rgb)
        )
        Column(
            modifier = Modifier
                .width(width)
                .padding(all = 24.px)
                .backgroundColor(surfacePrimary)
                .borderRadius(r = 10.px)
                .fontFamily(Constants.FONT_FAMILY)
                .boxShadow(
                    offsetY = 0.px,
                    offsetX = 0.px,
                    blurRadius = 8.px,
                    spreadRadius = 6.px,
                    color = shadowColor
                )
        ) {
            dialogContent()
        }
    }
}

@Composable
fun MyLicenseDialogContent(
    homeVM: HomeVM,
    licenseType: License,
    darkMode: Boolean,
    onDismiss: () -> Unit,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val rbRangeGeneralList = licenseType.rbRangeGeneralList
    val rbRangeSpecificList = licenseType.rbRangeSpecificList

    if(homeVM.homeState.isEscapePressed && homeVM.homeState.showLicenseDialog) {
        onDismiss()
        homeVM.updateIsEscapePressed(false)
    }
    if(homeVM.homeState.isEnterPressed && homeVM.homeState.showLicenseDialog) {
        onSubmit()
        homeVM.updateIsEnterPressed(false)
    }

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = licenseType.dialogTitle,
            color = textPrimary,
        )
    }
    MyVerticalSpacer(20.px)
    /* Body */
    val list = licenseType.dialogBody.split("\n")
    list.forEach {
        MyParagraph(
            text = it,
            color = textPrimary
        )
    }
    MyVerticalSpacer(10.px)
    /* What to do? */
    MySubtitle(
        text = "* ¿Qué quieres hacer?",
        color = textPrimary
    )
    MyVerticalSpacer(10.px)
    /* Exam-Study choice */
    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        MyHomeDialogPointer(
            darkMode = darkMode,
            text = ExamStudy.EXAM.spanish,
            selected = homeVM.homeState.isExamSelected,
            onClick = {
                homeVM.updateIsExamSelected()
            }
        )
        MyHomeDialogPointer(
            darkMode = darkMode,
            text = ExamStudy.STUDY.spanish,
            selected = homeVM.homeState.isStudySelected,
            onClick = {
                homeVM.updateIsStudySelected(rbRangeGeneralList.first())
            }
        )
    }
    MyVerticalSpacer(10.px)

    /* Study range height */
    val subtitleHeight = 28
    val generalHeight = if(rbRangeGeneralList.isNotEmpty()) 68 else 0
    val specificHeight = if(rbRangeSpecificList.isNotEmpty()) 68 else 0
    val studyRangeHeight = subtitleHeight + generalHeight + specificHeight

    /* Study range options */
    StudyRange(
        modifier = Modifier
            .fillMaxWidth()
            .studyRangeAppear(homeVM.homeState.showStudyRange, studyRangeHeight),
        homeVM = homeVM,
        rbRangeGeneralList = rbRangeGeneralList,
        rbRangeSpecificList = rbRangeSpecificList,
        darkMode = darkMode
    )

    /* Divider */
    HorizontalDivider(modifier = Modifier.fillMaxWidth().opacity(0.5))
    MyVerticalSpacer(20.px)
    /* Buttons */
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        DismissButton(
            darkMode = darkMode,
            buttonText = "Cancelar",
            onDismiss = onDismiss
        )
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Iniciar",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun MyHomeHeaderInfoDialogContent(
    homeVM: HomeVM,
    darkMode: Boolean,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    if(homeVM.homeState.isEscapePressed && homeVM.homeState.showHeaderInfoDialog) {
        onSubmit()
        homeVM.updateIsEscapePressed(false)
    }
    if(homeVM.homeState.isEnterPressed && homeVM.homeState.showHeaderInfoDialog) {
        onSubmit()
        homeVM.updateIsEnterPressed(false)
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyTitle(
            text = "Información sobre esta pantalla",
            color = textPrimary
        )
    }
    MyVerticalSpacer(20.px)

    Column(
        modifier = Modifier.fillMaxWidth(),
    ) {
        MySubtitle(
            text = "* Tarjetas",
            color = textPrimary
        )
        MyVerticalSpacer(10.px)
        MyParagraph(
            text = "- Elige una tarjeta y tendrás 2 opciones:",
            color = textPrimary
        )
        Row(modifier = Modifier.fillMaxWidth()) {
            MyHorizontalSpacer(10.px)
            MyParagraph(
                text = "a) Simulacro del examen.",
                color = textPrimary
            )
        }
        Row(modifier = Modifier.fillMaxWidth()) {
            MyHorizontalSpacer(10.px)
            MyParagraph(
                text = "b) Estudiar el balotario.",
                color = textPrimary
            )
        }
        MyVerticalSpacer(10.px)

        MySubtitle(
            text = "* Detalle",
            color = textPrimary
        )
        MyVerticalSpacer(10.px)
        Row(modifier = Modifier.fillMaxWidth()) {
            MyHorizontalSpacer(10.px)
            MyParagraph(
                text = "a) El examen consta de 40 preguntas aleatorias tomadas del balotario.",
                color = textPrimary
            )
        }
        Row(modifier = Modifier.fillMaxWidth()) {
            MyHorizontalSpacer(10.px)
            MyParagraph(
                text = "b) El balotario puede constar de preguntas de 'Materias Generales' y 'Materias Específicas'.",
                color = textPrimary
            )
        }
        MyVerticalSpacer(10.px)
    }
    MyVerticalSpacer(20.px)

    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Cerrar",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun MyQuestionsHeaderInfoDialogContent(
    questionsVM: QuestionsVM,
    darkMode: Boolean,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val license = HomeState.license
    val examStudy = HomeState.examStudy
    val range = HomeState.range
    val materia = HomeState.materia

    val isExam = examStudy == ExamStudy.EXAM
    val examDetails = "- 40 preguntas aleatorias del balotario." +
            "\n- Total de tiempo: 40 minutos." +
            "\n- Mínimo para aprobar: 35 correctas."

    val studyDetails = "- Las preguntas son de ${materia.type}." +
            "\n- Click en 'Solución' para ver la respuesta." +
            "\n- No se controla el tiempo."

    if(questionsVM.questionState.isEscapePressed && questionsVM.questionState.showHeaderInfoDialog) {
        onSubmit()
        questionsVM.updateIsEscapePressed(false)
    }
    if(questionsVM.questionState.isEnterPressed && questionsVM.questionState.showHeaderInfoDialog) {
        onSubmit()
        questionsVM.updateIsEnterPressed(false)
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyTitle(
            text = if(isExam) "Simulacro de Examen" else "Preguntas $range del balotario",
            color = textPrimary
        )
        MyTitle(
            text = license.dialogTitle,
            color = textPrimary
        )
    }
    MyVerticalSpacer(20.px)

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Column(
        ) {
            MySubtitle(
                text = "* Detalle",
                color = textPrimary
            )
            MyVerticalSpacer(10.px)

            val list = if(isExam) examDetails.split("\n") else studyDetails.split("\n")
            list.forEach {
                Row(modifier = Modifier.fillMaxWidth()) {
                    MyHorizontalSpacer(10.px)
                    MyParagraph(
                        text = it,
                        color = textPrimary
                    )
                }
            }
            MyVerticalSpacer(10.px)
            MySubtitle(
                text = "* Uso del teclado",
                color = textPrimary
            )
            MyVerticalSpacer(10.px)

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                MyHorizontalSpacer(10.px)
                Column() {
                    Row() {
                        MyHorizontalSpacer(27.px)
                        DirectionalButton(direction = "∧")
                    }
                    MyVerticalSpacer(2.px)
                    Row() {
                        DirectionalButton(direction = "<")
                        MyHorizontalSpacer(2.px)
                        DirectionalButton(direction = "∨")
                        MyHorizontalSpacer(2.px)
                        DirectionalButton(direction = ">")
                    }
                }
                MyHorizontalSpacer(10.px)
                Column() {
                    MySubtitle(
                        text = "· Izquierda-Derecha",
                        color = textPrimary
                    )
                    Row(modifier = Modifier.fillMaxWidth()) {
                        MyHorizontalSpacer(10.px)
                        MyParagraph(
                            text = "Navegar entre preguntas",
                            color = textPrimary
                        )
                    }
                    MySubtitle(
                        text = "· Arriba-Abajo",
                        color = textPrimary
                    )
                    Row(modifier = Modifier.fillMaxWidth()) {
                        MyHorizontalSpacer(10.px)
                        MyParagraph(
                            text = "Navegar entre opciones",
                            color = textPrimary
                        )
                    }
                }
            }
        }

    }
    MyVerticalSpacer(20.px)

    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Continuar",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun MyPauseDialogContent(
    questionsVM: QuestionsVM,
    darkMode: Boolean,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val total = questionsVM.userOptions.count()
    val answered = questionsVM.userOptions.toList().count { it != "" }
    val notAnswered = questionsVM.userOptions.toList().count { it == "" }

    if(questionsVM.questionState.isEscapePressed && questionsVM.questionState.showPauseDialog) {
        onSubmit()
        questionsVM.updateIsEscapePressed(false)
    }
    if(questionsVM.questionState.isEnterPressed && questionsVM.questionState.showPauseDialog) {
        onSubmit()
        questionsVM.updateIsEnterPressed(false)
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyTitle(text = "El Examen está en pausa", color = textPrimary)
    }
    MyVerticalSpacer(20.px)

    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Center
    ) {
        Column() {
            MySubtitle(text = "Total de preguntas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "Contestadas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "No contestadas:", color = textPrimary)
        }
        MyHorizontalSpacer(10.px)
        Column(modifier = Modifier.width(40.px), horizontalAlignment = Alignment.End) {
            MyParagraph(text = "$total", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$answered", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$notAnswered", color = textPrimary)
        }
    }

    MyVerticalSpacer(20.px)
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Continuar",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun MyFinishDialogContent(
    questionsVM: QuestionsVM,
    timer: String,
    darkMode: Boolean,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val correct = questionsVM.examUserAnswers.toList().count { it }
    val incorrect = questionsVM.examUserAnswers.toList().count { !it }

    val title = if(timer == "40:00") "El tiempo ha terminado" else "Resultado"
    val result = if(correct >= 35) "Aprobaste el examen" else "No aprobaste el examen"

    if(questionsVM.questionState.isEscapePressed && questionsVM.questionState.showFinishDialog) {
        onSubmit()
        questionsVM.updateIsEscapePressed(false)
    }
    if(questionsVM.questionState.isEnterPressed && questionsVM.questionState.showFinishDialog) {
        onSubmit()
        questionsVM.updateIsEnterPressed(false)
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyTitle(text = title, color = textPrimary)
        MyTitle(text = result, color = textPrimary)
    }
    MyVerticalSpacer(20.px)

    Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
        Column() {
            MySubtitle(text = "Total de preguntas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "Respuestas Correctas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "Respuestas Incorrectas:", color = textPrimary)
        }
        MyHorizontalSpacer(10.px)
        Column(modifier = Modifier.width(40.px), horizontalAlignment = Alignment.End) {
            MyParagraph(text = "${correct + incorrect}", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$correct", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$incorrect", color = textPrimary)
        }
    }

    MyVerticalSpacer(20.px)
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Ir al Inicio",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun MyProgressDialogContent(
    questionsVM: QuestionsVM,
    darkMode: Boolean,
    onDismiss: () -> Unit,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    val correct = questionsVM.studyUserAnswers.toList().count { it == true }
    val incorrect = questionsVM.studyUserAnswers.toList().count { it == false }
    val notAnswered = questionsVM.studyUserAnswers.toList().count { it == null }

    if(questionsVM.questionState.isEscapePressed && questionsVM.questionState.showProgressDialog) {
        onDismiss()
        questionsVM.updateIsEscapePressed(false)
    }
    if(questionsVM.questionState.isEnterPressed && questionsVM.questionState.showProgressDialog) {
        onSubmit()
        questionsVM.updateIsEnterPressed(false)
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyTitle(text = "Progreso del estudio", color = textPrimary)
    }
    MyVerticalSpacer(20.px)

    Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
        Column() {
            MySubtitle(text = "Total de preguntas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "Respuestas Correctas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "Respuestas Incorrectas:", color = textPrimary)
            MyVerticalSpacer(5.px)
            MySubtitle(text = "No Contestadas:", color = textPrimary)
        }
        MyHorizontalSpacer(10.px)
        Column(modifier = Modifier.width(40.px), horizontalAlignment = Alignment.End) {
            MyParagraph(text = "${correct + incorrect + notAnswered}", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$correct", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$incorrect", color = textPrimary)
            MyVerticalSpacer(5.px)
            MyParagraph(text = "$notAnswered", color = textPrimary)
        }
    }

    MyVerticalSpacer(20.px)
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        DismissButton(
            darkMode = darkMode,
            buttonText = "Continuar con el estudio",
            onDismiss = onDismiss
        )
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Ir al Inicio",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun My35MinDialogContent(
    questionsVM: QuestionsVM,
    darkMode: Boolean,
    onSubmit: () -> Unit,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    if(questionsVM.questionState.isEscapePressed && questionsVM.questionState.show35minDialog) {
        onSubmit()
        questionsVM.updateIsEscapePressed(false)
    }
    if(questionsVM.questionState.isEnterPressed && questionsVM.questionState.show35minDialog) {
        onSubmit()
        questionsVM.updateIsEnterPressed(false)
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyTitle(
            text = "Te quedan 5 minutos",
            color = textPrimary
        )
    }
    MyVerticalSpacer(20.px)
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        SubmitButton(
            darkMode = darkMode,
            buttonText = "Continuar",
            onSubmit = onSubmit
        )
    }
}

@Composable
fun MyChangeRangeDialogContent(
    questionsVM: QuestionsVM,
    darkMode: Boolean,
) {
    val textPrimary = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val license = questionsVM.questionState.currentLicense
    val rbRangeGeneralList = license.rbRangeGeneralList
    val rbRangeSpecificList = license.rbRangeSpecificList

//    if(homeVM.homeState.isEscapePressed && homeVM.homeState.showLicenseDialog) {
//        onDismiss()
//        homeVM.updateIsEscapePressed(false)
//    }
//    if(homeVM.homeState.isEnterPressed && homeVM.homeState.showLicenseDialog) {
//        onSubmit()
//        homeVM.updateIsEnterPressed(false)
//    }

    MyVerticalSpacer(20.px)
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = license.dialogTitle,
            color = textPrimary,
        )
    }
    MyVerticalSpacer(20.px)

    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        MySubtitle(
            text = "* Elige un rango de preguntas",
            color = textPrimary
        )
        MyVerticalSpacer(10.px)

        if(rbRangeGeneralList.isNotEmpty()) {
            Row(modifier = Modifier.fillMaxWidth()) {
                MyHorizontalSpacer(20.px)
                MySubtitle(
                    text = Materia.GENERAL.type,
                    color = textPrimary
                )
            }
            MyVerticalSpacer(10.px)
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                rbRangeGeneralList.forEach {
                    MyHomeDialogPointer(
                        darkMode = darkMode,
                        text = it,
                        selected = it == questionsVM.questionState.currentRange,
                        onClick = {
                            HomeState.apply {
                                materia = Materia.GENERAL
                                range = it
                            }
                            questionsVM.updateRange()
                            questionsVM.updateShowChangeRangeDialog(false)
                        }
                    )
                }
            }
        }

        if(rbRangeSpecificList.isNotEmpty()) {
            MyVerticalSpacer(10.px)
            Row(modifier = Modifier.fillMaxWidth()) {
                MyHorizontalSpacer(20.px)
                MySubtitle(
                    text = Materia.SPECIFIC.type,
                    color = textPrimary
                )
            }
            MyVerticalSpacer(10.px)
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                rbRangeSpecificList.forEach {
                    MyHomeDialogPointer(
                        darkMode = darkMode,
                        text = it,
                        selected = it == questionsVM.questionState.currentRange,
                        onClick = {
                            HomeState.apply {
                                materia = Materia.SPECIFIC
                                range = it
                            }
                            questionsVM.updateRange()
                            questionsVM.updateShowChangeRangeDialog(false)
                        }
                    )
                }
            }
        }
    }
    MyVerticalSpacer(20.px)
    /* Buttons */
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        DismissButton(
            darkMode = darkMode,
            buttonText = "Cancelar",
            onDismiss = { questionsVM.updateShowChangeRangeDialog(false) }
        )
    }
}

@Composable
fun DismissButton(
    darkMode: Boolean,
    buttonText: String,
    onDismiss: () -> Unit,
    surface: CSSColorValue = if(!darkMode) SurfacePrimary.color.rgb
    else SurfacePrimaryDark.color.rgb,
    textColor: CSSColorValue = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb,
    lineStyle: LineStyle = LineStyle.Solid
) {
    val borderPrimary = if(!darkMode) Theme.BorderPrimary.color.rgb
    else Theme.BorderPrimaryDark.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Button(
            attrs = MainButtonStyle.toModifier()
                .onClick { onDismiss() }
                .height(40.px)
                .border(width = 1.px, style = lineStyle, color = borderPrimary)
                .borderRadius(r = 5.px)
                .backgroundColor(surface)
                .cursor(Cursor.Pointer)
                .toAttrs()
        ) {
            MyParagraph(text = buttonText, color = textColor)
        }
    }
}

@Composable
fun SubmitButton(
    darkMode: Boolean,
    buttonText: String,
    onSubmit: () -> Unit,
    surface: CSSColorValue = if(!darkMode) Theme.SurfaceBlue.color.rgb
    else Theme.SurfaceBlueDark.color.rgb,
    textColor: CSSColorValue = if(!darkMode) TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
) {
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Button(
            attrs = MainButtonStyle.toModifier()
                .onClick { onSubmit() }
                .height(40.px)
                .border(width = 0.px)
                .borderRadius(r = 5.px)
                .backgroundColor(surface)
                .cursor(Cursor.Pointer)
                .toAttrs()
        ) {
            MyParagraph(text = buttonText, color = textColor)
        }
    }
}

@Composable
fun DirectionalButton(direction: String) {
    Button(
        attrs = Modifier
            .size(25.px)
            .borderRadius(r = 4.px)
            .border(width = 1.px, style = LineStyle.Solid, color = Theme.BorderPrimary.color.rgb)
            .backgroundColor(SurfacePrimary.color.rgb)
            .disabled(true)
            .toAttrs()
    ) {
        MyParagraph(text = direction, color = TextPrimary.color.rgb)
    }
}
