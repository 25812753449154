package com.sunday.kmplicenseperu.pages

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.components.NotFound
import com.varabyte.kobweb.core.Page

//@Page(routeOverride = "/{a}")
//@Composable
//fun Redirecting1() {
//    NotFound()
//}

@Page(routeOverride = "/{a}/{b}")
@Composable
fun Redirecting2() {
    NotFound()
}

@Page(routeOverride = "/{a}/{b}/{c}")
@Composable
fun Redirecting3() {
    NotFound()
}

@Page(routeOverride = "/{a}/{b}/{c}/{d}")
@Composable
fun Redirecting4() {
    NotFound()
}