package com.sunday.kmplicenseperu.local.questions

import com.sunday.kmplicenseperu.models.Question

object B2BGeneralQuestions {

    fun getB2BGeneralQuestions() = listOf(

        Question(
            question = "El grado de instrucción requerido para obtener una licencia de conducir B-II B es: ",
            options = listOf(
                "a) Primaria incompleta.",
                "b) Primaria completa.",
                "c) Secundaria incompleta.",
                "d) Secundaria completa.",
            ),
            result = "b"
        ),

        Question(
            question = "Es un requisito para la obtención de una licencia de conducir B-II B:",
            options = listOf(
                "a) Tener una edad mínima de 18 años.",
                "b) Presentar constancia de estudios que acredite que el postulante culminó la secundaria. ",
                "c) Presentar una declaración jurada de no tener antecedentes penales ni judiciales.",
                "d) Presentar certificado de no tener antecedentes penales ni judiciales.",
            ),
            result = "a"
        ),
    )
}