package com.sunday.kmplicenseperu.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.navigation.Routes
import com.sunday.kmplicenseperu.util.Constants
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.get

@Composable
fun NotFound() {
    val darkMode by remember { mutableStateOf(localStorage[Constants.DARK_MODE].toBoolean()) }

    val surfacePrimary = if(!darkMode) Theme.SurfacePrimary.color.rgb
    else Theme.SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb

    Column(
        modifier = Modifier.fillMaxSize().backgroundColor(surfacePrimary),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyParagraph("Página no encontrada, redireccionando.", textPrimary)
    }
    window.location.href = window.location.origin + Routes.HOME_ROUTE
}