package com.sunday.kmplicenseperu.models

import com.sunday.kmplicenseperu.models.CustomColor.Amber200
import com.sunday.kmplicenseperu.models.CustomColor.Amber800
import com.sunday.kmplicenseperu.models.CustomColor.Black10PercentAlpha
import com.sunday.kmplicenseperu.models.CustomColor.Black50PercentAlpha
import com.sunday.kmplicenseperu.models.CustomColor.Blue200
import com.sunday.kmplicenseperu.models.CustomColor.Blue500
import com.sunday.kmplicenseperu.models.CustomColor.Blue700
import com.sunday.kmplicenseperu.models.CustomColor.Gray200
import com.sunday.kmplicenseperu.models.CustomColor.Gray300
import com.sunday.kmplicenseperu.models.CustomColor.Gray50
import com.sunday.kmplicenseperu.models.CustomColor.Gray700
import com.sunday.kmplicenseperu.models.CustomColor.Gray800
import com.sunday.kmplicenseperu.models.CustomColor.Gray900
import com.sunday.kmplicenseperu.models.CustomColor.Green200
import com.sunday.kmplicenseperu.models.CustomColor.Green500
import com.sunday.kmplicenseperu.models.CustomColor.Green800
import com.sunday.kmplicenseperu.models.CustomColor.Red200
import com.sunday.kmplicenseperu.models.CustomColor.Red500
import com.sunday.kmplicenseperu.models.CustomColor.Red800
import com.sunday.kmplicenseperu.models.CustomColor.White10PercentAlpha
import com.sunday.kmplicenseperu.models.CustomColor.White50PercentAlpha
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val color: CustomColor
) {
    /* SURFACE */
    SurfaceBrand(Blue500), SurfaceBrandDark(Blue700),
    SurfacePrimary(Gray50), SurfacePrimaryDark(Gray700),
    SurfaceSecondary(Gray300), SurfaceSecondaryDark(Gray900),
    SurfaceBlue(Blue200), SurfaceBlueDark(Blue500),
    SurfaceAmber(Amber200), SurfaceAmberDark(Amber800),
    SurfaceGreen(Green200), SurfaceGreenDark(Green800),
    SurfaceRed(Red200), SurfaceRedDark(Red800),

    /* BORDER */
    BorderPrimary(Gray800), BorderPrimaryDark(Gray200),
    BorderCorrect(Green500), /*BorderCorrectDark(Gray200),*/
    BorderIncorrect(Red500), /*BorderIncorrectDark(Gray200),*/
    BorderBrand(Blue500),

    /* TEXT */
    TextPrimary(Gray900), TextPrimaryDark(Gray200),
    TextBlue(Blue500), TextBlueDark(Blue200),

    /* OTHERS */
    HalfBlack(Black50PercentAlpha),
    HalfWhite(White50PercentAlpha),
    Black10Percent(Black10PercentAlpha),
    White10Percent(White10PercentAlpha),
}

enum class CustomColor(
    val hex: String,
    val rgb: CSSColorValue
) {
    Gray50(hex = "#FAFAFA", rgb = rgb( 250, 250, 250)),
    Gray200(hex = "#EEEEEE", rgb = rgb(238, 238, 238)),
    Gray300(hex = "#E0E0E0", rgb = rgb(224, 224, 224)),
    Gray700(hex = "#616161", rgb = rgb(97, 97, 97)),
    Gray800(hex = "#424242", rgb = rgb(66, 66, 66)),
    Gray900(hex = "#212121", rgb = rgb(33, 33, 33)),

    Blue200(hex = "#90CAF9", rgb = rgb(144, 202, 249)),
    Blue500(hex = "#2196F3", rgb = rgb(33, 150, 243)),
    Blue700(hex = "#1976D2", rgb = rgb(25, 118, 210)),

    Amber200(hex = "#FFE082", rgb = rgb(255, 224, 130)),
    Amber800(hex = "#FF8F00", rgb = rgb(255, 143, 0)),

    Green200(hex = "#A5D6A7", rgb = rgb(165, 214, 167)),
    Green500(hex = "#4CAF50", rgb = rgb(76, 175, 80)),
    Green800(hex = "#2E7D32", rgb = rgb(46, 125, 50)),

    Red200(hex = "#EF9A9A", rgb = rgb(239, 154, 154)),
    Red500(hex = "#F44336", rgb = rgb(244, 67, 54)),
    Red800(hex = "#C62828", rgb = rgb(198, 40, 40)),

    Black50PercentAlpha(hex = "#000000", rgb = rgba(0, 0, 0, 0.5)),
    White50PercentAlpha(hex = "#FFFFFF", rgb = rgba(255, 255, 255, 0.5)),
    Black10PercentAlpha(hex = "#000000", rgb = rgba(0, 0, 0, 0.1)),
    White10PercentAlpha(hex = "#FFFFFF", rgb = rgba(255, 255, 255, 0.1)),
}