package com.sunday.kmplicenseperu.pages.questions

import com.sunday.kmplicenseperu.local.LocalImpl
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.models.Question
import com.sunday.kmplicenseperu.pages.home.HomeState
import com.sunday.kmplicenseperu.util.ExamStudy
import com.sunday.kmplicenseperu.util.Materia

data class QuestionsState(
    var size: Int = setSize(),
    var index: Int = 0,
    var questionNumber: Int = setQuestionNumber(),
    var optionSelected: String = "",
    var questions: List<Question> = setQuestions(),
    var userOptions: MutableList<String> = setUserOptions(),
    var examUserAnswers: MutableList<Boolean> = setExamUserAnswers(),
    var studyUserAnswers: MutableList<Boolean?> = setStudyUserAnswers(),

    var currentLicense: License = setLicense(),
    var currentExamStudy: ExamStudy = setExamStudy(),
    var currentRange: String? = setRange(),
    var currentMateria: Materia = setMateria(),
    var comesFromHome: Boolean? = setComesFromHome(),
//    var comesFromHome: Boolean = true,

    var showHeaderInfoDialog: Boolean = true,
    var showPauseDialog: Boolean = false,
    var showFinishDialog: Boolean = false,
    var showProgressDialog: Boolean = false,
    var show35minDialog: Boolean = false,
    var showChangeRangeDialog: Boolean = false,
    var isSolutionEnabled: Boolean = false,

    var isEscapePressed: Boolean = false,
    var isEnterPressed: Boolean = false,
)

/* QUESTIONS-STATE SETTER FUNCTIONS */

fun setSize(): Int {
    return if(HomeState.examStudy == ExamStudy.EXAM) {
        40
    } else {
        val initRange = HomeState.range!!.split("-")[0].toInt()
        val endRange = HomeState.range!!.split("-")[1].toInt()
        endRange - (initRange-1)
    }
}

fun setQuestionNumber(): Int {
    return if(HomeState.examStudy == ExamStudy.EXAM) {
        1
    } else {
        HomeState.range!!.split("-").first().toInt()
    }
}

fun setQuestions(): List<Question> {
    println("setQuestions()")
    val tempQuestions = LocalImpl.getQuestions(HomeState.license)
    val questions =
    if(HomeState.examStudy == ExamStudy.EXAM) {
        getRandom40(tempQuestions)
    } else {
        getQuestionsInRange(HomeState.license, tempQuestions, HomeState.range!!, HomeState.materia)
    }
    return questions
}

fun setUserOptions() = MutableList(setSize()) { "" }
fun setExamUserAnswers() = MutableList(setSize()) { false }
fun setStudyUserAnswers(): MutableList<Boolean?> = MutableList(setSize()) { null }
fun setLicense() = HomeState.license
fun setExamStudy() = HomeState.examStudy
fun setRange() = HomeState.range
fun setMateria() = HomeState.materia
fun setComesFromHome() = HomeState.comesFromHome

/* SECONDARY FUNCTIONS */

private fun getRandom40(questions: List<Question>): List<Question> {
    return questions.toMutableList().shuffled().take(40)
}

private fun getQuestionsInRange(
    license: License,
    questions: List<Question>,
    range: String,
    materia: Materia
): List<Question> {
    val materiaQuestions = when (materia) {
        Materia.GENERAL -> questions.dropLast(license.matEsp)
        Materia.SPECIFIC -> questions.drop(license.matGen)
    }
    val initialRange = range.split("-")[0].toInt() - 1
    val endRange = range.split("-")[1].toInt() - 1
    return materiaQuestions.slice(initialRange..endRange)
}