package com.sunday.kmplicenseperu.pages.questions

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.runtime.toMutableStateList
import com.sunday.kmplicenseperu.models.Timer
import com.sunday.kmplicenseperu.pages.home.HomeState
import com.sunday.kmplicenseperu.util.ExamStudy

class QuestionsVM {

    var questionState by mutableStateOf(QuestionsState())
        private set
    var timer by mutableStateOf(Timer())
        private set

    var questions = questionState.questions.toMutableStateList()
        private set
    var userOptions = questionState.userOptions.toMutableStateList()
        private set
    var examUserAnswers = questionState.examUserAnswers.toMutableStateList()
        private set
    var studyUserAnswers = questionState.studyUserAnswers.toMutableStateList()
        private set

    fun updateQuestionsState() {
        questionState = questionState.copy(
            size = setSize(),
            questionNumber = setQuestionNumber(),
            currentExamStudy = setExamStudy(),
            currentLicense = setLicense(),
            currentMateria = setMateria(),
            currentRange = setRange(),
            comesFromHome = true,
            showHeaderInfoDialog = true
        )
        questions.clear()
        userOptions.clear()
        examUserAnswers.clear()
        studyUserAnswers.clear()
        questions.addAll(setQuestions())
        userOptions.addAll(setUserOptions())
        examUserAnswers.addAll(setExamUserAnswers())
        studyUserAnswers.addAll(setStudyUserAnswers())
        resetTimer()
        onInfo(pauseTimer = true)
    }

    fun updateRange() {
        questionState = questionState.copy(
            size = setSize(),
            questionNumber = setQuestionNumber(),
            currentMateria = setMateria(),
            currentRange = setRange(),
        )
        questions.clear()
        userOptions.clear()
        examUserAnswers.clear()
        studyUserAnswers.clear()
        questions.addAll(setQuestions())
        userOptions.addAll(setUserOptions())
        examUserAnswers.addAll(setExamUserAnswers())
        studyUserAnswers.addAll(setStudyUserAnswers())
    }

    fun updateComesFromHome(value: Boolean) {
        questionState = questionState.copy(
            comesFromHome = value
        )
    }

    fun questionOptionClick(optionSelected: String, index: Int) {
        questionState = questionState.copy(
            optionSelected = optionSelected
        )
        userOptions[index] = optionSelected
    }
    fun updateWithPrevioulySelected(optionSelected: String) {
        questionState = questionState.copy(
            optionSelected = optionSelected
        )
    }
    fun jumpToIndex(index: Int, questionNumber: Int) {
        questionState = questionState.copy(
            index = index,
            questionNumber = questionNumber,
            optionSelected = ""
        )
    }

    fun updateShowHeaderInfoDialog(value: Boolean) {
        questionState = questionState.copy(
            showHeaderInfoDialog = value
        )
    }
    fun updateShowPauseDialog(value: Boolean) {
        questionState = questionState.copy(
            showPauseDialog = value
        )
    }
    fun updateShowFinishDialog(value: Boolean) {
        questionState = questionState.copy(
            showFinishDialog = value
        )
    }
    fun updateShowProgressDialog(value: Boolean) {
        questionState = questionState.copy(
            showProgressDialog = value
        )
    }
    fun updateShow35minDialog(value: Boolean) {
        questionState = questionState.copy(
            show35minDialog = value
        )
    }
    fun updateShowChangeRangeDialog(value: Boolean) {
        questionState = questionState.copy(
            showChangeRangeDialog = value
        )
    }
    fun anyDialogDisplayed(): Boolean {
        return (questionState.showHeaderInfoDialog
                || questionState.showPauseDialog
                || questionState.showFinishDialog
                || questionState.showProgressDialog
                || questionState.show35minDialog)
    }

    fun updateIsEscapePressed(value: Boolean) {
        questionState = questionState.copy(
            isEscapePressed = value
        )
    }
    fun updateIsEnterPressed(value: Boolean) {
        questionState = questionState.copy(
            isEnterPressed = value
        )
    }

    fun isFirstQuestion(): Boolean {
        var isFirst = false
        if(HomeState.examStudy == ExamStudy.EXAM) {
            if(questionState.questionNumber == 1) isFirst = true
        }
        else {
            if(questionState.questionNumber == HomeState.range!!.split("-")[0].toInt()) isFirst = true
        }
        return isFirst
    }
    fun isLastQuestion(): Boolean {
        var isLast = false
        if(HomeState.examStudy == ExamStudy.EXAM) {
            if(questionState.questionNumber == 40) isLast = true
        }
        else {
            if(questionState.questionNumber == HomeState.range!!.split("-")[1].toInt()) isLast = true
        }
        return isLast
    }

    fun onPrevButton() {
        questionState = questionState.copy(
            index = questionState.index.dec(),
            questionNumber = questionState.questionNumber.dec(),
            optionSelected = ""
        )
    }
    fun onPauseButton() {
        updateShowPauseDialog(true)
        onInfo(pauseTimer = true)
    }
    fun onSolutionButton() {
        questionState = questionState.copy(
            isSolutionEnabled = !questionState.isSolutionEnabled
        )
    }
    fun onFinishButton() {
        onInfo(pauseTimer = true)
        examUserAnswers.clear()
        val originalResult = questionState.questions.map { q -> q.result }.toList()
        updateShowFinishDialog(true)

        originalResult.forEachIndexed { index, it ->
            examUserAnswers.add( it == userOptions.toList()[index] )
        }
    }
    fun onProgressButton() {
        studyUserAnswers.clear()
        val originalResult = questionState.questions.map { q -> q.result }.toList()
        updateShowProgressDialog(true)

        originalResult.forEachIndexed { index, it ->
            if(userOptions.toList()[index] == "") { // user didn't select an option
                studyUserAnswers.add( null )
            } else {
                studyUserAnswers.add( it == userOptions.toList()[index] )
            }
        }
    }
    fun onNextButton() {
        questionState = questionState.copy(
            index = questionState.index.inc(),
            questionNumber = questionState.questionNumber.inc(),
            optionSelected = ""
        )
    }
    fun onChangeRange() {
        questionState = questionState.copy(
            showChangeRangeDialog = !questionState.showChangeRangeDialog
        )
    }

    /* TIMER FUNCTIONS */

    fun increaseTimer(ticks: Int) {
        timer = timer.copy(ticks = ticks)
    }

    fun formatTimer(ticks: Int): String {
        val seconds = (ticks % 60)
        val minutes = ((ticks / 60) % 60)
        val stringSeconds = if(seconds < 10) "0$seconds" else "$seconds"
        val stringMinutes = if(minutes < 10) "0$minutes" else "$minutes"
        return "$stringMinutes:$stringSeconds"
    }

    fun onInfo(pauseTimer: Boolean) {
        timer = timer.copy(
            pauseTimer = pauseTimer
        )
    }

    fun resetTimer() {
        timer = timer.copy(ticks = 0)
    }
}