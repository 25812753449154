package com.sunday.kmplicenseperu.local.questions

import com.sunday.kmplicenseperu.models.Question

object A1GeneralQuestions {

    fun getA1LocalQuestions() = listOf(

        Question(
            question = "Está permitido en la vía:",
            options = listOf(
                "a) Recoger o dejar pasajeros o carga en cualquier lugar.",
                "b) Dejar animales sueltos o situarlos de forma tal que obstaculicen solo un poco el tránsito.",
                "c) Recoger o dejar pasajeros en lugares autorizados.",
                "d) Ejercer el comercio ambulatorio o estacionario. ",
            ),
            result = "c"
        ),

        Question(
            question = "Respecto de los dispositivos de control o regulación del tránsito: ",
            options = listOf(
                "a) Solo los peatones están obligados a su obediencia.",
                "b) Los conductores y los peatones están obligados a su obediencia, salvo instrucción de la Policía Nacional del Perú asignada al tránsito que indique lo contrario.",
                "c) Solo los conductores están obligados a su obediencia.",
                "d) Los conductores están obligados a su obediencia, aun cuando la Policía Nacional del Perú asignada al tránsito pueda indicar lo contrario.",
            ),
            result = "b"
        ),

        Question(
            question = "La señal vertical reglamentaria R-6 \"Prohibido voltear a la izquierda\" significa que:",
            options = listOf(
                "a) Está prohibido voltear a la izquierda y, por lo tanto también está prohibido el giro en U.",
                "b) Está prohibido voltear a la izquierda, sin embargo, está permitido el giro en U.",
                "c) El único sentido de desplazamiento es continuar de frente.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "La señal vertical reglamentaria R-3 significa que:",
            image = "r_3",
            options = listOf(
                "a) Nos acercamos a una zona restringida al tránsito.",
                "b) Está permitido adelantar vehículos.",
                "c) El único sentido de desplazamiento es continuar de frente.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "En las vías, las marcas en el pavimento que son del tipo central discontinua y de color amarillo significan que:",
            options = listOf(
                "a) Está permitido cruzar al otro carril para el adelantamiento vehicular, si es que es seguro hacerlo.",
                "b) No está permitido cruzar al otro carril para el adelantamiento vehicular.",
                "c) Se está reduciendo el ancho de la calzada de la vía por donde se circula.",
                "d) Se está frente a un lugar de cruce peatonal. ",
            ),
            result = "a"
        ),

        Question(
            question = "El color ámbar o amarillo del semáforo significa que: ",
            options = listOf(
                "a) Los vehículos deben avanzar.",
                "b) Los vehículos deben detenerse.",
                "c) Los vehículos deben acelerar la marcha.",
                "d) Los vehículos deben detenerse antes de ingresar a la intersección si su velocidad y ubicación lo permiten; de lo contrario, deberán cruzar y despejar la intersección.",
            ),
            result = "d"
        ),

        Question(
            question = "Los colores del semáforo tienen el siguiente significado: rojo: ______; ámbar o amarillo: ______; verde: ______.",
            options = listOf(
                "a) Detención - prevención - paso.",
                "b) Detención - paso con prevención - circulación rápida.",
                "c) Disminución de la velocidad - prevención - paso rápido.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Qué indica una flecha verde en un semáforo vehicular?",
            options = listOf(
                "a) Se puede continuar con precaución únicamente en la dirección de la flecha y desde el carril que esta flecha controla.",
                "b) No está permitida la circulación en el sentido que indica la flecha.",
                "c) Se debe respetar únicamente la luz circular.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "La señal vertical reglamentaria R-53:",
            image = "r_53",
            options = listOf(
                "a) Prohibe estacionar.",
                "b) Prohibe al conductor detener el vehículo dentro del área de la intersección.",
                "c) Prohibe la carga y descarga.",
                "d) Prohibe la circulación de vehículos motorizados. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si llega a una intersección y visualiza el semáforo con una flecha roja hacia la izquierda y la luz circular verde prendidas al mismo tiempo, la acción correcta es:",
            options = listOf(
                "a) Avanzar en cualquier sentido, ya que la luz circular está en verde.",
                "b) Avanzar, pero el giro a la izquierda está prohibido por la flecha roja.",
                "c) Avanzar únicamente hacia la izquierda, pues continuar de frente está prohibido.",
                "d) Ninguna de las alternativas es correcta.",
            ),
            result = "b"
        ),

        Question(
            question = "Si llega a una intersección donde el semáforo muestra una luz intermitente, qué afirmación es correcta:",
            options = listOf(
                "a) Si la luz intermitente es roja, ésta es equivalente a un “CEDA EL PASO”",
                "b) Si la luz intermitente es ámbar, tiene preferencia, debiendo reducir la velocidad y continuar con precaución.",
                "c) Si la luz intermitente es verde, ésta es equivalente a un “PARE”.",
                "d) Ninguna de las alternativas es correcta.",
            ),
            result = "b"
        ),

        Question(
            question = "¿La luz intermitente roja es igual que una señal de PARE? ",
            options = listOf(
                "a) Verdad.",
                "b) Es verdad siempre y cuando también se encuentre un Policía de Tránsito indicando la señal de Pare.",
                "c) Falso.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "Al aproximarse a una intersección con giro permitido a la izquierda, la conducta correcta es:",
            options = listOf(
                "a) Girar desde cualquier carril.",
                "b) Colocarse en el carril derecho, luego girar con precaución.",
                "c) Colocarse en el carril más despejado de tráfico, luego girar con precaución.",
                "d) Hacer la señal de volteo a la izquierda con las luces direccionales, ubicar con antelación el vehículo en el carril de circulación de la izquierda y girar con precaución.",
            ),
            result = "d"
        ),

        Question(
            question = "Al cambiar de carril en una vía de un solo sentido con múltiples carriles, ¿cuál es la conducta correcta?",
            options = listOf(
                "a) Se deben encender las luces direccionales primero, buscar una brecha y realizar el cambio de carril con precaución.",
                "b) Se debe encontrar una brecha, luego cambiar de carril con precaución; no es necesario el uso de luces direccionales para cambios de carril.",
                "c) Se debe advertir utilizando el claxon, identificar una brecha y realizar el cambio de carril con precaución.",
                "d) Está prohibido el cambio de carril en vías de un solo sentido.",
            ),
            result = "a"
        ),

        Question(
            question = "Respecto a los cruces a nivel con vías férreas, señale la afirmación correcta:",
            options = listOf(
                "a) Los vehículos que transitan por la vía férrea tienen preferencia de paso sobre los que transitan por la vía que la cruza.",
                "b) Los vehículos que transitan por la vía que cruza la línea férrea tienen preferencia de paso sobre los que transitan por la vía férrea.",
                "c) El vehículo que llegue primero tiene preferencia.",
                "d) Tiene preferencia el conductor que viene por la derecha del otro.",
            ),
            result = "a"
        ),

        Question(
            question = "Ante la señal de color rojo del semáforo y la indicación de continuar la marcha del efectivo de la Policía Nacional del Perú asignado al control del tránsito, corresponde:",
            options = listOf(
                "a) Detenerse hasta que cambie a luz verde. ",
                "b) Continuar la marcha. ",
                "c) Estar prevenido. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "Está prohibido estacionar un vehículo: ",
            options = listOf(
                "a) En las curvas. ",
                "b) Dentro de una intersección.",
                "c) Frente a la entrada de garajes y de recintos militares o policiales.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "La señal vertical reglamentaria (R-29):",
            image = "r_29",
            options = listOf(
                "a) Prohibe el uso de la bocina en vías urbanas.",
                "b) Prohibe el uso de la bocina en carreteras.",
                "c) Prohibe el uso de la bocina. ",
                "d) Prohibe hacer ruido. ",
            ),
            result = "c"
        ),

        Question(
            question = "Se le impondrá el pago de una multa y no podrá obtener la licencia de conducir por 3 años a la persona que:",
            options = listOf(
                "a) Conduzca un vehículo automotor sin tener licencia de conducir.",
                "b) Conduzca un vehículo que no cuente con el equipamiento para brindar una máxima comodidad a sus ocupantes.",
                "c) Conduzca un vehículo sin contar con el SOAT. ",
                "d) a y c son correctas ",
            ),
            result = "a"
        ),

        Question(
            question = "En el supuesto que se encuentre manejando y un vehículo que tiene la intención de sobrepasarlo o adelantarlo lo alcance, ¿qué debería hacer usted?",
            options = listOf(
                "a) Debe aumentar la velocidad para no dejar que el otro vehículo lo pase.",
                "b) No debe aumentar la velocidad hasta que el vehículo lo sobrepase.",
                "c) Debe disminuir drásticamente la velocidad de su vehículo.",
                "d) Debe detener su vehículo. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál de las siguientes afirmaciones es correcta?",
            options = listOf(
                "a) El conductor debe respetar los límites máximos y mínimos de velocidad establecidos.",
                "b) El conductor debe respetar únicamente los límites máximos de velocidad, pues no existen límites mínimos.",
                "c) El conductor puede conducir a la velocidad que desee, siempre que lo haga de manera prudente.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Qué luces debe utilizar un conductor que circula en las vías públicas urbanas por la noche? ",
            options = listOf(
                "a) Bajas. ",
                "b) Altas.",
                "c) Luces altas en las intersecciones y bajas en las avenidas.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "En caso de accidentes, el SOAT cubre los daños que sufren: ",
            options = listOf(
                "a) Solo los ocupantes del vehículo.",
                "b) Los ocupantes y terceros no ocupantes del vehículo. ",
                "c) Solo terceros afectados. ",
                "d) Solo el conductor del vehículo. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál de los siguientes seguros es exigible para conducir un vehículo automotor?",
            options = listOf(
                "a) Cualquier tipo de seguro de accidentes personales que comercialicen las empresas de seguro.",
                "b) Cualquier tipo de seguro vehicular, siempre que cubra a los ocupantes del vehículo y terceros afectados por un accidente de tránsito.",
                "c) El Seguro Obligatorio de Accidentes de Tránsito - SOAT.",
                "d) No es obligatorio contar con un seguro.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Cuándo es obligatorio darle preferencia de paso a un vehículo de emergencia o vehículo oficial?",
            options = listOf(
                "a) Cuando emita señales visibles.",
                "b) Cuando emita señales audibles.",
                "c) Cuando emita señales audibles y visibles.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si por el carril por donde está conduciendo se aproxima a un vehículo de transporte escolar que se encuentra detenido, recogiendo o dejando escolares ¿Qué debe hacer?",
            options = listOf(
                "a) Detener el vehículo y no continuar la marcha hasta que haya culminado el ascenso o descenso de los escolares.",
                "b) Adelantar por el lado izquierdo, pero con precaución.",
                "c) Tocar el claxon para alertar que está pasando. ",
                "d) Adelantar muy despacio. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Qué significa un triángulo rojo de seguridad colocado en la calzada?",
            options = listOf(
                "a) La presencia de un vehículo inmovilizado en la vía pública por alguna circunstancia.",
                "b) Zona de obras por reparación en la calzada.",
                "c) Que el vehículo que lo enfrenta debe detenerse. ",
                "d) a y c son correctas. ",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal vertical reglamentaria P-22C indica que:",
            image = "p_22c",
            options = listOf(
                "a) Está permitido adelantar. ",
                "b) Se aproxima un carril adicional.",
                "c) Está permitido cambiar de carril.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "Las luces intermitentes de emergencia del vehículo deben utilizarse obligatoriamente:",
            options = listOf(
                "a) Para girar en las intersecciones y para advertir los cambios de carril.",
                "b) Para indicar la detención, parada o estacionamiento en zona peligrosa o la ejecución de maniobras riesgosas.",
                "c) Durante toda la circulación del vehículo. ",
                "d) En cada intersección. ",
            ),
            result = "b"
        ),

        Question(
            question = "En intersecciones que no tienen señales de Pare, Ceda el Paso o Semáforo, ¿las vías de doble sentido tienen prioridad de paso respecto a las vías de un solo sentido de igual clasificación?",
            options = listOf(
                "a) No. ",
                "b) Sí. ",
                "c) Depende de la intersección.",
                "d) No se encuentra regulado en el reglamento. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si usted se aproxima a una señal de PARE colocada verticalmente o pintada en la vía, la acción correcta es:",
            options = listOf(
                "a) Disminuir su velocidad y cederle el paso a los vehículos que circulan por la transversal.",
                "b) Disminuir su velocidad y pasar con cuidado.",
                "c) Sobreparar y pasar rápidamente.",
                "d) Parar por completo, ceder el paso a los usuarios que tengan preferencia y luego continuar con precaución.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Cuál es la diferencia entre las señales P-2A y P-1A?",
            image = "p_2a_p_1a_www3",
            options = listOf(
                "a) No hay diferencia, se usan indistintamente.",
                "b) La señal P-2A se utiliza en situaciones de mayor riesgo.",
                "c) La señal P-1A advierte la presencia de curva pronunciada a la derecha mientras que la P-2A advierte la presencia de curva suave a la derecha.",
                "d) La señal P-2A advierte la presencia de curva pronunciada a la derecha mientras que la P-1A advierte la presencia de curva suave a la derecha.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Qué indica la señal R-30F?",
            image = "r_30f",
            options = listOf(
                "a) Tener precaución con vehículos lentos.",
                "b) Regula la velocidad máxima permitida en curvas.",
                "c) Regula la velocidad máxima en zonas rurales.",
                "d) No se encuentra regulada en el reglamento. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué señal debe utilizar el conductor para disminuir la velocidad o detener el vehículo cuando está en marcha? ",
            options = listOf(
                "a) Luces direccionales.",
                "b) Solo el antebrazo izquierdo y mano extendidos hacia abajo fuera del vehículo.",
                "c) Luces intermitentes y, en caso de fuerza mayor, utilizar el antebrazo izquierdo y mano extendidos hacia abajo fuera del vehículo, haciendo ángulo recto con el brazo.",
                "d) Luces bajas. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si dos vehículos se aproximan simultáneamente a una intersección no regulada (sin señalización) procedentes de vías diferentes, ¿quién tiene preferencia de paso?",
            image = "interseccion_www2",
            options = listOf(
                "a) Cualquiera de los dos. ",
                "b) El que se aproxime por la derecha del otro.",
                "c) El que se aproxime por la izquierda del otro.",
                "d) El que haga sonar la bocina primero. ",
            ),
            result = "b"
        ),

        Question(
            question = "En una intersección no regulada (sin señalización) tiene preferencia de paso:",
            options = listOf(
                "a) El vehículo que ingresó primero a la intersección.",
                "b) El vehículo que haga sonar la bocina primero.",
                "c) El vehículo que haga cambio de luces primero. ",
                "d) Cualquier vehículo. ",
            ),
            result = "a"
        ),

        Question(
            question = "En una rotonda, tiene prioridad de paso el vehículo que: ",
            options = listOf(
                "a) Quiere ingresar a la rotonda. ",
                "b) Circula por ella. ",
                "c) Acelera primero. ",
                "d) Hace sonar la bocina. ",
            ),
            result = "b"
        ),

        Question(
            question = "El sobrepaso o adelantamiento de un vehículo en movimiento se efectúa, salvo excepciones, por la ______ retornando el vehículo después de la maniobra a su carril original.",
            options = listOf(
                "a) Derecha. ",
                "b) Izquierda. ",
                "c) Berma. ",
                "d) Por la derecha o izquierda. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si un conductor está tomando medicamentos y por ello siente sueño ¿qué debe hacer? ",
            options = listOf(
                "a) Manejar normalmente. ",
                "b) Manejar despacio. ",
                "c) Abstenerse de manejar. ",
                "d) Manejar con un copiloto. ",
            ),
            result = "c"
        ),

        Question(
            question = "Son documentos que deben portarse obligatoriamente, durante la conducción del vehículo, y exhibirse cuando la autoridad competente lo solicite:",
            options = listOf(
                "a) Documento de identidad, SOAT vigente (puede ser virtual) y tarjeta de identificación vehicular.",
                "b) Certificado de Inspección Técnica Vehicular y contrato de compraventa del vehículo.",
                "c) Contrato de compraventa del vehículo.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "a"
        ),

        Question(
            question = "En los tramos de una vía con pendiente pronunciada, que permite la circulación de un solo vehículo, se debe de tener en cuenta que el vehículo que asciende respecto al vehículo que desciende:",
            options = listOf(
                "a) No tiene preferencia de paso.",
                "b) Tiene preferencia de paso.",
                "c) Debe detenerse. ",
                "d) Debe retroceder. ",
            ),
            result = "b"
        ),

        Question(
            question = "Al cambiar de dirección, un conductor debe:",
            options = listOf(
                "a) Señalizar toda la maniobra hasta su culminación.",
                "b) Cambiar de dirección y luego señalizar.",
                "c) No existe ninguna obligación.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Se puede conducir un vehículo con el motor en punto neutro? ",
            options = listOf(
                "a) Sí, pero solo si está bajando una pendiente. ",
                "b) No, está prohibido. ",
                "c) Sí, en caso de que el conductor no sea novato.",
                "d) No se encuentra regulado en la norma. ",
            ),
            result = "b"
        ),

        Question(
            question = "Para girar o cambiar de carril hacia la derecha, el conductor debe utilizar las luces direccionales y, en casos de fuerza mayor, la siguiente señal manual:",
            options = listOf(
                "a) Brazo, antebrazo izquierdo y mano extendidos hacia afuera del vehículo.",
                "b) Antebrazo izquierdo y mano extendidos hacia arriba fuera del vehículo, formando un ángulo recto con el brazo.",
                "c) Brazo, antebrazo derecho y mano extendidos hacia afuera del vehículo.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si la licencia de conducir no se encuentra vigente, los vehículos que autoriza a conducir dicha licencia:",
            options = listOf(
                "a) No podrán ser conducidos.",
                "b) Podrán ser conducidos únicamente en zonas urbanas.",
                "c) Podrán ser conducidos hasta por 90 días posteriores a la pérdida de vigencia de la licencia.",
                "d) Podrán ser conducidos con normalidad hasta que renueve su licencia.",
            ),
            result = "a"
        ),

        Question(
            question = "De acuerdo con el sistema de control de licencias de conducir por puntos:",
            options = listOf(
                "a) Determinadas infracciones suman puntos.",
                "b) Una infracción puede sumar o disminuir puntos, a elección del infractor.",
                "c) Determinadas infracciones restan puntos.",
                "d) Ninguna de las alternativas es correcta.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál es la consecuencia de acumular 100 puntos en la licencia de conducir en un periodo de 24 meses?",
            options = listOf(
                "a) Suspensión de licencia de conducir.",
                "b) Anulación de la Placa Única Nacional de Rodaje.",
                "c) Inhabilitación definitiva para obtener una licencia de conducir.",
                "d) Internamiento vehicular. ",
            ),
            result = "a"
        ),

        Question(
            question = "Se entiende por carril a la:",
            options = listOf(
                "a) Parte de la vía destinada a la circulación de peatones.",
                "b) Parte de la calzada destinada al tránsito de una fila de vehículos.",
                "c) Vía rural destinada a la circulación de peatones y animales.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "Se entiende por línea de parada a:",
            options = listOf(
                "a) La línea transversal marcada en la calzada antes de la intersección, que indica al conductor el límite para detener el vehículo.",
                "b) Las líneas que se encuentran en los lugares del estacionamiento.",
                "c) El lugar utilizado para embarcar y desembarcar pasajeros.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal vertical reglamentaria P-17A, indica:",
            image = "p_17a",
            options = listOf(
                "a) Reducción de la calzada al lado derecho.",
                "b) Reducción de la calzada al lado izquierdo.",
                "c) Reducción de la calzada en ambos lados.",
                "d) Ensanchamiento de la calzada en ambos lados. ",
            ),
            result = "c"
        ),

        Question(
            question = "En caso de encontrar marcación de doble línea amarilla compuesta por un trazo continuo y otro trazo discontinuo en una vía de doble sentido, ¿qué se debe hacer?",
            options = listOf(
                "a) Se puede adelantar en ambos sentidos.",
                "b) No está permitido adelantar en ningún sentido.",
                "c) Respetar la línea que está de su lado (si es continua, no adelantar; si es discontinua, está permitido adelantar).",
                "d) Respetar la línea que está de su lado (si es discontinua, no adelantar; si es continua, está permitido adelantar).",
            ),
            result = "c"
        ),

        Question(
            question = "Se define como zona rígida al: ",
            options = listOf(
                "a) Área donde se prohíbe la circulación de vehículos.",
                "b) Área donde se prohíbe la circulación de peatones.",
                "c) Área de la vía en la que se prohíbe el estacionamiento de vehículos.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "c"
        ),

        Question(
            question = "La posición de frente o de espaldas ejecutada por el efectivo de la Policía Nacional del Perú asignado al control de tránsito significa:",
            options = listOf(
                "a) Obligación de detenerse de quien así lo enfrente.",
                "b) Continuar la marcha por el carril izquierdo de la calzada.",
                "c) Continuar la marcha. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "Siempre que no exista una señal de límite de velocidad, en zonas urbanas el límite máximo de velocidad en calles y jirones es de: ",
            options = listOf(
                "a) 30km/h. ",
                "b) 40km/h. ",
                "c) 60km/h. ",
                "d) 80km/h. ",
            ),
            result = "b"
        ),

        Question(
            question = "Siempre que no exista una señal de límite de velocidad, en zonas urbanas el límite máximo de velocidad en avenidas es de: ",
            options = listOf(
                "a) 30km/h. ",
                "b) 40km/h. ",
                "c) 60km/h. ",
                "d) 80km/h. ",
            ),
            result = "c"
        ),

        Question(
            question = "Siempre que no exista una señal de límite de velocidad, en zonas urbanas el límite máximo de velocidad en vías expresas es de: ",
            options = listOf(
                "a) 60km/h. ",
                "b) 70km/h. ",
                "c) 80km/h. ",
                "d) 100km/h. ",
            ),
            result = "c"
        ),

        Question(
            question = "Siempre que no exista una señal de límite de velocidad en zonas urbanas, el límite máximo de velocidad en zona escolar es de: ",
            options = listOf(
                "a) 20km/h. ",
                "b) 30km/h. ",
                "c) 35km/h. ",
                "d) 50km/h. ",
            ),
            result = "b"
        ),

        Question(
            question = "Siempre que no exista una señal de límite de velocidad en carreteras, el límite máximo de velocidad para automóviles, camionetas y motocicletas es de:",
            options = listOf(
                "a) 80km/h. ",
                "b) 90km/h. ",
                "c) 100km/h. ",
                "d) 110km/h. ",
            ),
            result = "c"
        ),

        Question(
            question = "Siempre que no exista una señal de límite de velocidad mínima, el límite mínimo de velocidad en zona urbana y en carreteras es de: ",
            options = listOf(
                "a) 30km/h.",
                "b) La mitad de la velocidad máxima establecida para cada tipo de vía.",
                "c) 20 km/h. ",
                "d) 15 km/h. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál es la sanción por conducir con presencia de alcohol en la sangre en proporción mayor a lo previsto en el Código Penal, o bajo los efectos de estupefacientes, narcóticos y/o alucinógenos comprobados con el examen respectivo, o por negarse al mismo y que haya participado en un accidente de tránsito?",
            options = listOf(
                "a) Multa. ",
                "b) Cancelación de licencia de conducir.",
                "c) Suspensión de la licencia de conducir.",
                "d) Multa, cancelación de la licencia de conducir e inhabilitación definitiva para obtener una licencia de conducir.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Cuál es la sanción si en un operativo de alcoholemia usted es intervenido y se comprueba que ha consumido alcohol por encima del límite legal, o está conduciendo bajo los efectos de estupefacientes, narcóticos y/o alucinógenos comprobada con el examen respectivo?",
            options = listOf(
                "a) Una multa. ",
                "b) La suspensión de la licencia de conducir.",
                "c) Multa y suspensión de la licencia de conducir por 3 años.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La frecuencia de la inspección técnica de un vehículo particular es: ",
            options = listOf(
                "a) Cada medio año. ",
                "b) Cada año. ",
                "c) Cada dos años. ",
                "d) Cada tres años. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿A partir de qué año de antigüedad debe realizarse la inspección técnica de un vehículo particular para transporte de personas de hasta 9 asientos incluido el conductor de la categoría M1?",
            options = listOf(
                "a) A partir del segundo año contado desde el año siguiente de su fabricación.",
                "b) A partir del cuarto año contado desde el año siguiente de su fabricación.",
                "c) A partir del quinto año contado desde el año siguiente de su fabricación.",
                "d) No está sujeto a inspección técnica. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál es el plazo de vigencia del SOAT? ",
            options = listOf(
                "a) 1 año. ",
                "b) 6 meses. ",
                "c) 4 años. ",
                "d) 2 años. ",
            ),
            result = "a"
        ),

        Question(
            question = "Si ocurre un accidente de tránsito, ¿qué obligación tiene el conductor, el propietario del vehículo o el prestador del servicio de transporte?",
            options = listOf(
                "a) Dar aviso a los bomberos.",
                "b) Dar aviso solo a la compañía de seguros.",
                "c) Dar aviso a la compañía de seguros y dejar constancia en la delegación de la Policía Nacional del Perú más cercana.",
                "d) Dar aviso únicamente a la Policía Nacional del Perú. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si una licencia de conducir consigna alguna restricción, es correcto afirmar que:",
            options = listOf(
                "a) Dicha restricción es meramente informativa.",
                "b) Es una obligación cumplir con la restricción.",
                "c) Incumplir la restricción no genera un riesgo para la seguridad vial.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La señal preventiva P-33A, significa:",
            image = "p_33a",
            options = listOf(
                "a) Señal de curva sinuosa. ",
                "b) Señal de proximidad a un badén.",
                "c) Señal de proximidad de un reductor de velocidad tipo resalto.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si su vehículo deja de funcionar o comienza a tener problemas y usted trata de moverlo al costado de la autopista, debe:",
            options = listOf(
                "a) Activar las luces intermitentes de emergencia.",
                "b) Parar en su posición actual.",
                "c) Salir del vehículo rápidamente.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Está permitido usar la bocina de su vehículo para advertir al conductor del vehículo que circula delante, que será adelantado?",
            options = listOf(
                "a) Sí, siempre y cuando el sonido no sea estridente.",
                "b) Sí, salvo prohibición expresa mediante la correspondiente señal.",
                "c) No, está prohibido. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si observa que se aproxima una ambulancia sin las luces especiales encendidas y sin sirena, es correcto afirmar que:",
            options = listOf(
                "a) No estamos obligados a darle preferencia de paso.",
                "b) Estamos obligados a darle prioridad de paso por ser una ambulancia.",
                "c) Está informando que está en servicio de urgencia.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "Si se encuentra en una intersección y se enciende la luz verde del semáforo y observa que en la calle transversal hay vehículos o personas despejando la intersección, ¿qué debe hacer?",
            options = listOf(
                "a) No iniciar la marcha hasta que el vehículo o las personas terminen de cruzar.",
                "b) Tocar el claxon para que se apuren en pasar.",
                "c) Bajar del vehículo y reclamar la falta al infractor.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "En señalética vial, el color ______ en el pavimento es utilizado para carriles de tráfico en sentido opuesto y el color ______ en el pavimento es utilizado como separador de carriles de tráfico en el mismo sentido.",
            options = listOf(
                "a) Amarillo - Blanco. ",
                "b) Blanco - Amarillo. ",
                "c) Azul - Rojo. ",
                "d) Rojo - Amarillo. ",
            ),
            result = "a"
        ),

        Question(
            question = "Si una fila de escolares cruza la calzada fuera del crucero peatonal, ¿qué acción se debe tomar? ",
            options = listOf(
                "a) Advertir con el claxon. ",
                "b) Advertir a viva voz. ",
                "c) Parar y ceder el paso. ",
                "d) Continuar la marcha lentamente. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si se aproxima a una zona escolar, ¿qué acción debe realizar?",
            options = listOf(
                "a) No tiene ninguna obligación si no hay señalización.",
                "b) Disminuir la velocidad a 40 Km/h.",
                "c) Disminuir la velocidad a 30 Km/h.",
                "d) Disminuir la velocidad a 35 km/h. ",
            ),
            result = "c"
        ),

        Question(
            question = "Tienen el objetivo de notificar a los usuarios las limitaciones, prohibiciones o restricciones en el uso de la vía.",
            options = listOf(
                "a) Señales reguladoras o de reglamentación. ",
                "b) Señales preventivas. ",
                "c) Señales informativas. ",
                "d) Señales horizontales. ",
            ),
            result = "a"
        ),

        Question(
            question = "Tienen el propósito de advertir a los usuarios sobre la existencia y naturaleza de un peligro en la vía.",
            options = listOf(
                "a) Señales reguladoras o de reglamentación. ",
                "b) Señales preventivas. ",
                "c) Señales informativas. ",
                "d) Señales horizontales. ",
            ),
            result = "b"
        ),

        Question(
            question = "Es una infracción de tránsito: ",
            options = listOf(
                "a) No detenerse totalmente en una señal de PARE.",
                "b) Arrojar, depositar o abandonar objetos o sustancias en la vía pública que dificulten la circulación.",
                "c) Utilizar la bocina para llamar la atención en forma innecesaria.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Qué debería hacer el conductor al acercarse a una señal de “CEDA EL PASO” en una intersección?",
            options = listOf(
                "a) Ceder el paso a los vehículos de emergencia.",
                "b) Mantener la velocidad y avanzar con cuidado.",
                "c) Disminuir la velocidad, parar si es necesario y ceder el paso a los peatones o vehículos que circulan por la vía transversal.",
                "d) Parar totalmente y luego avanzar con cuidado. ",
            ),
            result = "c"
        ),

        Question(
            question = "No se debe conducir un vehículo: ",
            options = listOf(
                "a) Si no se cuenta con el SOAT.",
                "b) En retroceso, salvo las excepciones que establece la norma.",
                "c) Si no se tiene la licencia para el tipo de vehículo que se quiere conducir.",
                "d) Todas son correctas. ",
            ),
            result = "d"
        ),

        Question(
            question = "¿Qué debe hacer si se aproxima a una intersección sin semáforo y sin presencia de la Policía de Tránsito, y observa que un peatón está cruzando por el paso peatonal?",
            options = listOf(
                "a) Disminuir la velocidad y pasar con cuidado.",
                "b) Detener el vehículo y ceder el paso al peatón.",
                "c) Continuar porque usted tiene la prioridad.",
                "d) Incrementar la velocidad para adelantar el cruce el peatón.",
            ),
            result = "b"
        ),

        Question(
            question = "Sobre el uso del cinturón de seguridad en los vehículos que se encuentran en circulación, es correcto afirmar que:",
            options = listOf(
                "a) El uso del cinturón de seguridad es obligatorio para las personas que ocupen los asientos delanteros.",
                "b) En los asientos posteriores el uso del cinturón de seguridad es obligatorio en todos los vehículos cuando los tengan incorporados de fábrica y en los casos en que, de acuerdo a las normas se encuentren obligados a tenerlos.",
                "c) El uso del cinturón de seguridad no solo es obligatorio para el conductor.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Si en una vía de dos carriles con tránsito en un mismo sentido usted se desplaza lentamente en su vehículo, debe circular por el carril de la ______ y los vehículos que circulen a mayor velocidad deben hacerlo por el carril de la ______.",
            options = listOf(
                "a) Izquierda / Derecha. ",
                "b) Derecha / Derecha. ",
                "c) Derecha / Izquierda. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Sobre el uso de la bocina del vehículo, es correcto afirmar que:",
            options = listOf(
                "a) El conductor debe tratar de no utilizar la bocina para llamar la atención en forma innecesaria.",
                "b) El uso de la bocina para llamar la atención en forma innecesaria no es una infracción al tránsito.",
                "c) El conductor únicamente debe utilizar la bocina para evitar situaciones peligrosas.",
                "d) a y b son correctas. ",
            ),
            result = "c"
        ),

        Question(
            question = "Una línea blanca continua en el sentido longitudinal de una vía, que se coloca en el pavimento, le indica al conductor:",
            options = listOf(
                "a) Que se puede adelantar, debido a que la línea no es amarilla continua.",
                "b) Que está prohibido cruzar la línea y no hay excepciones.",
                "c) Que está prohibido pasar al otro lado de la línea con algunas excepciones.",
                "d) Que es zona de peatones.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Cuál es el número máximo de personas que puede transportar un vehículo particular?",
            options = listOf(
                "a) Es igual al número de asientos señalados en la tarjeta de identificación vehicular, con la excepción de niños en brazos en los asientos posteriores.",
                "b) No existe prohibición sobre un número máximo de personas que se puede transportar.",
                "c) La restricción sobre un número máximo de personas que se puede transportar se aplica para los asientos delanteros, para los asientos posteriores no hay restricción.",
                "d) Se puede transportar a todas las personas que entren en el vehículo, sin restricción, siempre y cuando no obstaculicen la visibilidad del conductor y todos sean de la misma familia.",
            ),
            result = "a"
        ),

        Question(
            question = "La acción correcta al abastecer de combustible su vehículo, es:",
            options = listOf(
                "a) Asegurarse de que todos los pasajeros permanezcan a bordo del vehículo.",
                "b) Abstenerse de fumar tanto el conductor como sus acompañantes.",
                "c) Mantener encendido el motor del vehículo.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "Mientras usted está conduciendo su vehículo automotor, ¿puede llevar sujeto a su auto al conductor de una bicicleta mientras éste la conduce?",
            options = listOf(
                "a) Sí, siempre y cuando el conductor de la bicicleta se sujete a la parte posterior del auto, pero nunca a la parte lateral de éste.",
                "b) Sí, siempre y cuando se prevean todas las medidas de seguridad respectivas.",
                "c) Sí, siempre y cuando esta acción sea realizada a una velocidad no mayor a 20km/h. ",
                "d) No, es una acción prohibida y quien la cometa puede ser sancionado con una multa.",
            ),
            result = "d"
        ),

        Question(
            question = "Si usted desea realizar una competencia de carreras entre su vehículo y otro vehículo motorizado; para ello puede utilizar:",
            options = listOf(
                "a) Las vías aledañas del parque del distrito en el cual reside, en el horario de 11:00 pm. a 5:00 am.",
                "b) Un circuito de carrera, autódromo o pista de aceleración autorizado por la autoridad competente.",
                "c) Las vías aledañas del parque del distrito en el cual reside, siempre y cuando tenga el permiso municipal correspondiente.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "Señale cuáles de las siguientes conductas constituye una infracción al tránsito:",
            options = listOf(
                "a) Tener la puerta, capot o maletera del vehículo abierta, cuando el vehículo está en marcha.",
                "b) Conducir un vehículo lentamente por el carril de la izquierda causando congestión.",
                "c) Conducir un vehículo con el motor en punto neutro o apagado.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Las marcas en el pavimento constituyen un elemento indispensable para la operación vehicular, pues su función es:",
            options = listOf(
                "a) Reemplazar a la señalización vertical cuando ésta no se encuentra en la vía, por tal motivo son colocadas donde no existe señales verticales.",
                "b) Guiar a los usuarios únicamente en las vías que presentan peligros.",
                "c) Reglamentar la circulación, así como advertir y guiar a los usuarios de la vía.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "c"
        ),

        Question(
            question = "Las marcas en el pavimento de color ______ complementan las señales informativas, como por ejemplo las zonas de estacionamiento para personas con movilidad reducida.",
            options = listOf(
                "a) Blanco. ",
                "b) Azul. ",
                "c) Rojo. ",
                "d) Gris. ",
            ),
            result = "b"
        ),

        Question(
            question = "La línea central de color amarillo en el pavimento es continua cuando:",
            options = listOf(
                "a) No está permitido cruzar al otro carril.",
                "b) Está permitido cruzar al otro carril para el adelantamiento.",
                "c) Se trata de una vía de doble sentido de circulación, que permite cruzar al otro carril.",
                "d) Separa corrientes de tráfico en el mismo sentido. ",
            ),
            result = "a"
        ),

        Question(
            question = "Si un conductor que circula por el carril derecho de una vía se encuentra con las flechas inclinadas que se muestran en la figura, su conducta correcta es:",
            image = "flechas_inclinadas_hhh3_www2",
            options = listOf(
                "a) Continuar la circulación por el carril en que se encuentra.",
                "b) Adelantar al vehículo que se encuentra delante de él y que circula por el carril izquierdo.",
                "c) No adelantar al vehículo que tiene adelante y que circula por el carril izquierdo y continuar por su carril.",
                "d) Cambiarse al carril izquierdo con precaución. ",
            ),
            result = "d"
        ),

        Question(
            question = "Si durante la conducción vehicular, un efectivo de la Policía de Tránsito le solicita al conductor someterse a una prueba de alcoholemia; la acción correcta del conductor es:",
            options = listOf(
                "a) Someterse a la prueba de alcoholemia, ya que está obligado a ello ante la solicitud del efectivo de la Policía de Tránsito.",
                "b) Someterse o negarse a la prueba de alcoholemia, ya que no constituye una obligación del conductor realizarse dicha prueba.",
                "c) Negarse a la prueba de alcoholemia, ya que únicamente es exigible si ha participado en un accidente de tránsito.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál de las siguientes conductas no es una infracción de tránsito?",
            options = listOf(
                "a) Llevar las placas de rodaje en el lugar que no corresponde.",
                "b) Seguir a los vehículos de emergencia y vehículos oficiales para avanzar más rápidamente.",
                "c) Arrojar objetos en la vía pública que dificulten la circulación.",
                "d) Detenerse totalmente en una señal de PARE cuando no hay peatones y/o vehículos circulando por la vía transversal.",
            ),
            result = "d"
        ),

        Question(
            question = "Si un conductor sale de su propiedad y tiene que cruzar la acera e ingresar a una vía, la conducta correcta es:",
            options = listOf(
                "a) Dar preferencia de paso solo a los vehículos que circulan por la vía, pero no a los peatones.",
                "b) Dar preferencia de paso a los vehículos que circulan por la vía y a los peatones que circulan por la acera.",
                "c) Tocar el claxon para advertir a los peatones que circulen por la acera, que se detengan.",
                "d) Salir rápidamente, a fin de evitar accidentes. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué significa una línea continua blanca pintada entre el carril de la derecha y la berma de una carretera?",
            options = listOf(
                "a) Un carril para motocicletas.",
                "b) Una línea que divide el área peatonal y el área de vehículos.",
                "c) Una línea que puede cruzarse para rebasar en caso de congestión.",
                "d) Que no se debe conducir atravesándola, a menos que haya una situación de emergencia.",
            ),
            result = "d"
        ),

        Question(
            question = "Si maneja de noche, hubiera neblina y tuviera luces rompenieblas, debe utilizar:",
            options = listOf(
                "a) Las luces bajas junto con las rompenieblas. ",
                "b) Solo las luces altas. ",
                "c) Solo las luces bajas. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "En el caso representado en el siguiente gráfico ¿el vehículo rojo puede rebasar al azul?",
            image = "azul_rojo_www2",
            options = listOf(
                "a) Puede hacerlo si no viene un vehículo en el sentido contrario.",
                "b) Puede hacerlo solo si, el vehículo en sentido contrario está lejos y alcanza hacer la maniobra con seguridad.",
                "c) No puede hacerlo. ",
                "d) Puede hacerlo por la izquierda. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (R-17), significa:",
            image = "r_17",
            options = listOf(
                "a) Prohibido estacionar. ",
                "b) Prohibida la circulación de vehículos automotores.",
                "c) Prohibida la circulación de autos particulares.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si usted se encuentra conduciendo su vehículo por una vía y antes de cruzar la intersección se encuentra con la señal R-4, esta le indica:",
            image = "r_4",
            options = listOf(
                "a) Que la calle está clausurada.",
                "b) Que está por ingresar a una vía de sentido contrario y no debe entrar.",
                "c) Que debe parar totalmente antes de poder ingresar.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-36), significa:",
            image = "p_36",
            options = listOf(
                "a) Proximidad de un túnel. ",
                "b) Superficie deslizante. ",
                "c) Prender las luces bajas.",
                "d) Mantener la distancia entre vehículos por seguridad.",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (R-14), significa:",
            image = "r_14",
            options = listOf(
                "a) Circular por el carril de la derecha.",
                "b) Circular por el carril central.",
                "c) Circular solo en el sentido indicado por la flecha.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si usted circula por una vía y se encuentra con la señal (R-11a), ésta le indica:",
            image = "r_11a",
            options = listOf(
                "a) Que es una vía de tres carriles de un solo sentido.",
                "b) Que es una vía de tres carriles y usted puede utilizar solo uno de ellos.",
                "c) Que es una vía de tres carriles y usted puede utilizar los dos de la derecha.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (R-30c), significa:",
            image = "r_30c",
            options = listOf(
                "a) Que la velocidad máxima de la vía es de 50 km/h.",
                "b) Que la velocidad mínima de la vía es de 50 km/h.",
                "c) Que al salir de la vía por donde está circulando, la velocidad máxima es 50 km/h.",
                "d) Que al salir de la vía por donde está circulando, la velocidad mínima es 50 km/h.",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (R-5-4), significa:",
            image = "r_5_4",
            options = listOf(
                "a) Que la vía no continúa y los conductores deben girar a la izquierda.",
                "b) Que la intersección contempla giros tangentes a la izquierda en ambos sentidos.",
                "c) Que la intersección está en mantenimiento y que por el momento solo se puede girar a la izquierda.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (R-9), significa:",
            image = "r_9",
            options = listOf(
                "a) Que no está permitido girar en U.",
                "b) Que está permitido el giro en U.",
                "c) Que si desea puede girar a la izquierda.",
                "d) Ninguna de las alternativas es correcta.",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (R-5-2), significa:",
            image = "r_5_2",
            options = listOf(
                "a) Que la vía solo permite girar a la izquierda.",
                "b) Prevención por bifurcación de la vía.",
                "c) Que el carril por donde circula permite girar a la izquierda o seguir de frente.",
                "d) No es una señal válida en el Perú. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (R-20), significa:",
            image = "r_20",
            options = listOf(
                "a) Que el peatón antes de cruzar debe mirar si se acercan autos por la derecha.",
                "b) Que el conductor que viene por la derecha debe tener cuidado con los peatones.",
                "c) Que los peatones deben circular por la izquierda.",
                "d) Que los peatones deben circular por la derecha. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (R-40), significa:",
            image = "r_40",
            options = listOf(
                "a) Crucero tipo cebra. ",
                "b) Control policial. ",
                "c) Pavimento deslizante. ",
                "d) Circular con luces bajas. ",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (R-48), significa:",
            image = "r_48",
            options = listOf(
                "a) Los peatones deben circular por la derecha y los camiones por la izquierda",
                "b) Los peatones deben tener cuidado con los camiones.",
                "c) Zona de carga y descarga. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (R-49), significa:",
            image = "r_49",
            options = listOf(
                "a) Está permitido cambiar de carril por la izquierda y por la derecha.",
                "b) Se debe mantener la distancia de seguridad entre vehículos.",
                "c) Está permitido cambiar de carril por la izquierda para adelantar.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (R-50), significa:",
            image = "r_50",
            options = listOf(
                "a) Que si solo hay un carril tiene preferencia de paso el conductor que llegó primero.",
                "b) Que si solo hay un carril tiene preferencia el conductor que está mirando la señal.",
                "c) Que si solo hay un carril no tiene preferencia el que está mirando la señal y debe darle paso al del sentido contrario.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La señal (R-5-1), es:",
            image = "r_5_1",
            options = listOf(
                "a) Una señal informativa. ",
                "b) Una señal preventiva. ",
                "c) Una señal de obligación. ",
                "d) No es una señal válida en el Perú. ",
            ),
            result = "c"
        ),

        Question(
            question = "La señal (P-3a), le indica al conductor que:",
            image = "p_3a",
            options = listOf(
                "a) Hay una curva y contracurva a la izquierda.",
                "b) Hay una curva y contracurva a la derecha.",
                "c) Hay una curva y contracurva pronunciada a la derecha.",
                "d) Hay una curva y contracurva pronunciada a la izquierda.",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-5-1a), le advierte al conductor que:",
            image = "p_5_1a",
            options = listOf(
                "a) Se aproxima a una curva y contra-curva a la izquierda.",
                "b) Se aproxima a una curva y contra-curva a la derecha.",
                "c) Se aproxima a un camino sinuoso a la derecha.",
                "d) Se aproxima a un camino sinuoso a la izquierda. ",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (P-61), le advierte al conductor que:",
            image = "p_61",
            options = listOf(
                "a) El sentido del tránsito es el que indica la flecha.",
                "b) Se aproxima a una reducción de la vía en ambos sentidos.",
                "c) Está circulando por una curva horizontal.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-34), le advierte al conductor que:",
            image = "p_34",
            options = listOf(
                "a) La vía está en mal estado y tiene baches. ",
                "b) Se aproxima a un resalto. ",
                "c) Se aproxima a un rompemuelles. ",
                "d) Se aproxima a un badén. ",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (P-60), es:",
            image = "p_60",
            options = listOf(
                "a) Una señal turística. ",
                "b) Una señal informativa. ",
                "c) Una señal preventiva. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-46), indica:",
            image = "p_46",
            options = listOf(
                "a) Existencia de una ciclovía.",
                "b) Autorización para el cruce de ciclistas. ",
                "c) Ciclistas en la vía. ",
                "d) Cercanía de una ciclovía. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-46a), indica:",
            image = "p_46a",
            options = listOf(
                "a) Que los ciclistas deben usar la ciclovía.",
                "b) Que nos aproximamos a un cruce de ciclovía.",
                "c) Que la ciclovía es solo para los ciclistas.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-46b), indica:",
            image = "p_46b_hhh2",
            options = listOf(
                "a) Que los ciclistas deben detenerse en ese punto.",
                "b) Que nos aproximamos a un cruce de ciclovía.",
                "c) Que la ciclovía es solo para los ciclistas.",
                "d) La ubicación de un cruce de ciclistas. ",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (P-48), indica:",
            image = "p_48",
            options = listOf(
                "a) Vía es de uso exclusivo de los peatones.",
                "b) Zona con presencia de peatones.",
                "c) Ubicación de un cruce escolar.",
                "d) Ninguna de las alternativas es correcta.",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-48a), indica:",
            image = "p_48a",
            options = listOf(
                "a) Vía es de uso exclusivo de peatones.",
                "b) Proximidad a un cruce peatonal.",
                "c) Ubicación de un cruce escolar.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-48b), indica:",
            image = "p_48b_hhh2",
            options = listOf(
                "a) Ubicación de un cruce escolar.",
                "b) Proximidad a una calzada.",
                "c) Ubicación de un cruce peatonal.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-49), indica:",
            image = "p_49",
            options = listOf(
                "a) Zona escolar. ",
                "b) Proximidad a un cruce peatonal. ",
                "c) Zona transitada. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal (P-49a), indica:",
            image = "p_49a",
            options = listOf(
                "a) Zona escolar. ",
                "b) Proximidad a un cruce escolar.",
                "c) Ubicación de un cruce escolar.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-49b), indica:",
            image = "p_49b_hhh2",
            options = listOf(
                "a) Zona transitada. ",
                "b) Proximidad a un cruce peatonal.",
                "c) Ubicación de un cruce escolar",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-50), indica:",
            image = "p_50",
            options = listOf(
                "a) Zona de deportes. ",
                "b) Proximidad a campo deportivo.",
                "c) Proximidad a zona urbana. ",
                "d) Niños jugando. ",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (P-51), indica:",
            image = "p_51",
            options = listOf(
                "a) Zona de parqueo de vehículos pesados. ",
                "b) Proximidad a zona rural. ",
                "c) Maquinaria agrícola en la vía.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-53), indica:",
            image = "p_53",
            options = listOf(
                "a) Proximidad a un establo. ",
                "b) Proximidad a zona agrícola. ",
                "c) Animales en la vía. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-55), indica:",
            image = "p_55",
            options = listOf(
                "a) Semáforo malogrado. ",
                "b) Proximidad a un semáforo. ",
                "c) Semáforos en ola verde. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-58), le indica:",
            image = "p_58",
            options = listOf(
                "a) Que usted se aproxima a una señal de PARE.",
                "b) Que usted debe parar donde está la señal P-58.",
                "c) Que se trata de un PARE, pero puede seguir adelante.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal (P-59), le indica:",
            image = "p_59",
            options = listOf(
                "a) Que usted se aproxima a una señal de CEDA EL PASO.",
                "b) Que usted debe ceder el paso donde está la señal P59.",
                "c) Que se trata de un PARE, pero puede seguir adelante.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal (P-41), le indica:",
            image = "p_41",
            options = listOf(
                "a) Que usted se aproxima a una zona de camiones.",
                "b) Que usted debe encender sus luces pues se aproxima a una zona con poca visibilidad.",
                "c) Que usted se aproxima a un túnel.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-45), indica:",
            image = "p_45",
            options = listOf(
                "a) Proximidad a un aeropuerto.",
                "b) Proximidad a una pista de aviones.",
                "c) Vuelo de aviones a baja altura.",
                "d) Aviones que generan ruido. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-52), le indica:",
            image = "p_52",
            options = listOf(
                "a) Que usted debe dar prioridad de paso a las ambulancias.",
                "b) Que usted debe dar prioridad de paso a los bomberos.",
                "c) Que se aproxima a una salida de vehículos de bomberos.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (P-66), le indica:",
            image = "p_66",
            options = listOf(
                "a) Que se aproxima un desierto.",
                "b) Que se aproxima una zona donde hay ráfagas de viento lateral.",
                "c) Que se acerca a una zona de arenamiento en la vía.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-66a), le indica:",
            image = "p_66a",
            options = listOf(
                "a) Que se aproxima una tormenta.",
                "b) Que está pasando por una zona donde hay ráfagas de viento lateral.",
                "c) Que se acerca a una zona de arenamiento en la vía.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si al conducir su vehículo se encuentra con la señal vertical que se muestra, usted debe entender que:",
            image = "vertical_1_hhh2_www2",
            options = listOf(
                "a) La vía está interrumpida y debe tomar el camino alternativo que muestra la señal.",
                "b) En la siguiente intersección está prohibido girar a la izquierda y por lo tanto, si desea seguir esa ruta debe tomar el camino alternativo que muestra la señal.",
                "c) En la siguiente intersección está prohibido girar a la derecha y por lo tanto, si desea seguir esa ruta debe tomar el camino alternativo que muestra la señal.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si al conducir su vehículo se encuentra con la señal vertical que se muestra, usted debe entender que:",
            image = "vertical_2_www2",
            options = listOf(
                "a) Si quiere girar a la izquierda debe pasar la intersección y dar la vuelta a la manzana.",
                "b) En la siguiente intersección está prohibido girar a la izquierda y por lo tanto, si desea seguir esa ruta debe hacerlo una cuadra antes.",
                "c) En esa dirección hay una zona de parqueo.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal (I-14), significa:",
            image = "i_14",
            options = listOf(
                "a) Señal de hostal. ",
                "b) Señal de hospedaje. ",
                "c) Señal de hospital. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (I-31), significa:",
            image = "i_31",
            options = listOf(
                "a) Proximidad de una bahía de taxis.",
                "b) Proximidad a un estacionamiento permitido.",
                "c) Proximidad de una zona de parqueo para vecinos.",
                "d) Proximidad de un estacionamiento para emergencias.",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (I-9), significa:",
            image = "i_9",
            options = listOf(
                "a) Zona de control policial. ",
                "b) Zona de control de aduanas. ",
                "c) Zona militar. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (I-18), se utiliza para indicar:",
            image = "i_18",
            options = listOf(
                "a) Cercanía a una ferretería. ",
                "b) Cercanía a un servicio mecánico. ",
                "c) Cercanía a un grifo. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (I-19), se utiliza para indicar:",
            image = "i_19",
            options = listOf(
                "a) Cercanía a un servicio mecánico. ",
                "b) Cercanía a una tienda. ",
                "c) Cercanía a un grifo. ",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La siguiente señal (I-20), se utiliza para indicar:",
            image = "i_20",
            options = listOf(
                "a) Cercanía a un servicio mecánico.",
                "b) Cercanía a una zona donde debe circular con cadenas en las llantas.",
                "c) Cercanía a un grifo. ",
                "d) Cercanía a una llantería. ",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (R-16a), se utiliza para indicar:",
            image = "r_16a",
            options = listOf(
                "a) Fin de la restricción de circulación de automotores.",
                "b) Fin de la restricción de prohibido adelantar.",
                "c) Fin de la restricción de circulación en doble sentido.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "El comportamiento del conductor como usuario de la vía, debe estar orientado a: ",
            options = listOf(
                "a) Valor por la vida.",
                "b) Respeto por la integridad física del resto de los usuarios.",
                "c) Respeto a la autoridad encargada de la vigilancia y disciplina de tráfico.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "Son considerados usuarios vulnerables de la vía y por tanto merecen especial protección:",
            options = listOf(
                "a) Peatones, niños, adultos mayores, personas con movilidad reducida, ciclistas.",
                "b) Únicamente los peatones y ciclistas.",
                "c) Únicamente los peatones y niños.",
                "d) Todas las alternativas son correctas, con excepción de los ciclistas.",
            ),
            result = "a"
        ),

        Question(
            question = "La siguiente señal (P-61), muestra:",
            image = "p_61",
            options = listOf(
                "a) Flechas retroreflectivas que indican peligro.",
                "b) Delineadores de curva, que guían al conductor.",
                "c) Advertencia al conductor sobre la proximidad de un puente.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si usted es titular de una licencia particular A-I, está autorizado a conducir el siguiente vehículo: ",
            options = listOf(
                "a) Camión, tipo volquete. ",
                "b) Ómnibus, tipo panorámico. ",
                "c) Mototaxi. ",
                "d) Automóvil, tipo sedán. ",
            ),
            result = "d"
        ),

        Question(
            question = "Mientras la persona conduce, le está permitido:",
            options = listOf(
                "a) Compartir su asiento con un niño, siempre y cuando éste sea menor de un año de edad.",
                "b) Que otra persona tome el control de la dirección del vehículo, siempre y cuando sea por un breve periodo de tiempo.",
                "c) Conducir con una mano sobre el volante de dirección, cuando es necesario accionar algún comando del vehículo, como realizar los cambios de velocidad.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "El conductor está ______ a ______ a las pruebas que le solicite el Efectivo de la Policía Nacional del Perú, asignado al control del tránsito, para determinar su estado de intoxicación por alcohol, drogas, estupefacientes u otros tóxicos.",
            options = listOf(
                "a) En su derecho - negarse.",
                "b) Facultado - rechazar.",
                "c) Obligado - someterse.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Ante un conductor con evidente discapacidad física, la cual no figura en el rubro de restricciones de su licencia de conducir, procede:",
            options = listOf(
                "a) Que la Policía de Tránsito intervenga a dicho conductor y que la autoridad que expidió la licencia de conducir ordene su reexaminación.",
                "b) Que la autoridad competente expida un permiso excepcional para dicho conductor.",
                "c) Que la Policía de Tránsito expida un permiso excepcional para dicho conductor.",
                "d) Que la Policía de Tránsito otorgue a dicho conductor un plazo prudencial para que continúe conduciendo.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Una persona con discapacidad física puede obtener una licencia de conducir particular?",
            options = listOf(
                "a) Sí, siempre y cuando dicha discapacidad pueda ser superada con algún corrector que establezca alguna de las restricciones que prevé la norma vigente.",
                "b) No, está terminantemente prohibido.",
                "c) Depende del criterio del centro de emisión de la licencia de conducir.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Puede una persona menor de edad conducir un vehículo? ",
            options = listOf(
                "a) Nunca. ",
                "b) Sí, siempre y cuando vaya acompañada de una personas mayor de edad y titular de una licencia de conducir.",
                "c) No, salvo que conduzca un vehículo de dimensión reducida.",
                "d) Sí, siempre y cuando sea mayor de 16 años y cuente con licencia de conducir o permiso provisional, conforme a lo dispuesto en el Reglamento Nacional del Sistema de Emisión de Licencias de Conducir.",
            ),
            result = "d"
        ),

        Question(
            question = "Es una obligación general de tránsito que, los ______ circulen respetando los mensajes de ______, las instrucciones de los Efectivos de la Policía de Tránsito y el mandato de las normas legales y reglamentarias correspondientes.",
            options = listOf(
                "a) Peatones - los letreros instalados en las vías públicas.",
                "b) Los usuarios de la vía pública - los dispositivos de.",
                "c) Vehículos - los medios de comunicación.",
                "d) Menores de edad - los adultos mayores. ",
            ),
            result = "b"
        ),

        Question(
            question = "Marque la afirmación incorrecta:",
            options = listOf(
                "a) Está prohibido arrojar, depositar o abandonar objetos en la vía pública, o cualquier otro obstáculo que pueda dificultar la circulación.",
                "b) El conductor debe mantener el vehículo que conduce con el combustible necesario para evitar detenciones en la vía.",
                "c) Los vehículos deben circular en vías urbanas con las luces bajas las 24 horas del día.",
                "d) En caso de haber agua en la calzada, el conductor de un vehículo debe tomar las precauciones, para evitar que ésta pueda mojar la acera y los peatones.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Si al conducir por una avenida se encuentra con una señal en la vía que indica un límite máximo de 50 km/h, sin embargo, conforme a lo dispuesto en la norma el límite máximo de velocidad en dicha vía es de 60 km/h; usted:",
            options = listOf(
                "a) Debe obedecer la señal de velocidad instalada en la vía.",
                "b) Puede ir a una velocidad máxima de 60 km/h.",
                "c) Debe ir a una velocidad máxima de 60 km/h.",
                "d) Debe ir a una velocidad entre 50 y 60 km/h. ",
            ),
            result = "a"
        ),

        Question(
            question = "Sobre la conducción en retroceso, marque la opción correcta:",
            options = listOf(
                "a) Está prohibida, salvo en casos estrictamente justificados.",
                "b) Nunca debe realizarse. ",
                "c) Está permitida. ",
                "d) Está permitida, salvo en carreteras.",
            ),
            result = "a"
        ),

        Question(
            question = "El uso de la luz ______ es obligatorio en ______, debiendo cambiar por luz ______ momentos previos al cruce con otro vehículo que circule en sentido contrario.",
            options = listOf(
                "a) Baja - carreteras - alta. ",
                "b) Alta - vías urbanas - baja. ",
                "c) Rompenieblas - carreteras - baja. ",
                "d) Alta - carretereras - baja. ",
            ),
            result = "d"
        ),

        Question(
            question = "Indique la conducta permitida:",
            options = listOf(
                "a) El estacionamiento de un vehículo a la salida de salas de espectáculos en funcionamiento.",
                "b) El estacionamiento de un vehículo de emergencia en un lugar no permitido, si ello fuera imprescindible.",
                "c) El estacionamiento de un vehículo después de 1 metro de un paso peatonal.",
                "d) El estacionamiento de un vehículo sobre las aceras. ",
            ),
            result = "b"
        ),

        Question(
            question = "Se considera el abandono de un vehículo cuando:",
            options = listOf(
                "a) El vehículo está estacionado en un lugar permitido en la vía pública, pero sin conductor por un tiempo mayor de 72 horas.",
                "b) El vehículo está estacionado en un lugar permitido en la vía pública, pero sin conductor por un tiempo mayor de 96 horas.",
                "c) El vehículo está estacionado en un lugar permitido en la vía pública, pero sin conductor y por un tiempo mayor de 48 horas.",
                "d) La norma de tránsito no establece nada sobre el abandono del vehículo.",
            ),
            result = "c"
        ),

        Question(
            question = "Al transportar carga en su vehículo, usted debe:",
            options = listOf(
                "a) Asegurarse, en caso la carga sobresalga lateralmente de la carrocería, esté bien sujeta.",
                "b) Evitar todo riesgo de caída de la carga que sobresale lateralmente de la carrocería.",
                "c) Tratar que la carga no sea arrastrada.",
                "d) Asegurarse que la carga no sobrepase las dimensiones de la carrocería.",
            ),
            result = "d"
        ),

        Question(
            question = "Sobre la emisión vehicular de sustancias contaminantes, marque la opción correcta: ",
            options = listOf(
                "a) Está permitida. ",
                "b) Está prohibida, en un índice superior al límite máximo que permite la norma.",
                "c) Está prohibida en el ámbito urbano.",
                "d) La norma no regula sobre ello. ",
            ),
            result = "b"
        ),

        Question(
            question = "La autoridad competente, ______ puede prohibir o restringir ______ en determinadas vías públicas.",
            options = listOf(
                "a) Cuando la situación lo justifique - la circulación o estacionamiento de vehículos.",
                "b) Aun cuando la situación lo justifique no - circulación o estacionamiento de vehículos.",
                "c) No se encuentra facultada ni - circulación de vehículos",
                "d) Únicamente con el consentimiento del conductor - circulación de vehículos.",
            ),
            result = "a"
        ),

        Question(
            question = "Si al conducir en una carretera de noche se aproxima un vehículo en sentido contrario; usted debe:",
            options = listOf(
                "a) Cambiar de luces bajas a luces altas",
                "b) Encender las luces de emergencia.",
                "c) Cambiar de luces altas a luces bajas.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "La detención de un vehículo debe efectuarse:",
            options = listOf(
                "a) En el sentido contrario a la circulación y en el carril izquierdo de la vía.",
                "b) En el sentido de la circulación y en el carril derecho de la vía, utilizando las luces altas.",
                "c) En el sentido de la circulación y en el carril izquierdo de la vía, utilizando las luces intermitentes.",
                "d) En el sentido de la circulación y en el carril derecho de la vía, utilizando las luces intermitentes.",
            ),
            result = "d"
        ),

        Question(
            question = "En caso de un accidente de tránsito con daños personales y/o materiales los participantes deben:",
            options = listOf(
                "a) Acudir a la estación de bomberos. ",
                "b) Llamar a un familiar. ",
                "c) Solicitar la intervención de la autoridad policial.",
                "d) Abandonar el lugar donde ocurrió el accidente. ",
            ),
            result = "c"
        ),

        Question(
            question = "El conductor antes de iniciar la conducción de un vehículo no está obligado a:",
            options = listOf(
                "a) Portar la tarjeta de identificación vehicular del vehículo que conduce.",
                "b) Portar y exhibir la Placa Única de Rodaje.",
                "c) Constatar que el vehículo tiene SOAT vigente.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "d"
        ),

        Question(
            question = "La ______ faculta y autoriza la circulación del vehículo por la vía pública, identifica el bien, y, por ende, al titular responsable de las acciones que deriven de su propiedad.",
            options = listOf(
                "a) Tarjeta de identificación vehicular. ",
                "b) Municipalidad provincial.",
                "c) La Superintendencia Nacional de Aduanas y de Administración Tributaria - SUNAT.",
                "d) Placa Única Nacional de Rodaje. ",
            ),
            result = "d"
        ),

        Question(
            question = "A fin de determinar la presencia de alcohol o sustancias estupefacientes en el conductor, el efectivo de la Policía de Tránsito puede exigirle al momento de la intervención:",
            options = listOf(
                "a) Que realice pruebas de coordinación y equilibrio. ",
                "b) Uso del alcoholímetro. ",
                "c) Prueba de alcoholemia. ",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "¿El Reglamento Nacional de Tránsito considera la reincidencia en las infracciones de tránsito?",
            options = listOf(
                "a) No, el conductor puede cometer la misma infracción varias veces y la sanción será la misma.",
                "b) Sí, cuando el conductor comete la misma infracción dentro de los 12 meses será sancionado con el doble de la multa.",
                "c) Solo en los casos en que se cometan infracciones gravísimas.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Después de qué tiempo de haber cometido la misma infracción se llama reincidencia y es sancionada con el doble de la multa establecida?",
            options = listOf(
                "a) 06 meses. ",
                "b) 12 meses. ",
                "c) 24 meses. ",
                "d) 35 meses. ",
            ),
            result = "b"
        ),

        Question(
            question = "Si a un conductor infractor le suspendieron su licencia de conducir, éste se encuentra facultado para:",
            options = listOf(
                "a) Tramitar el duplicado de su licencia de conducir.",
                "b) Tramitar la revalidación de su licencia de conducir.",
                "c) Tramitar la recategorización de su licencia de conducir.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "d"
        ),

        Question(
            question = "Las ______ tienen por función informar a los usuarios sobre los servicios generales existentes próximos a la vía, tales como teléfono, hospedaje, restaurante, primeros auxilios, estación de combustibles, talleres, y otros.",
            options = listOf(
                "a) Señales de localización. ",
                "b) Señales de salida inmediata.",
                "c) Señales de servicios generales. ",
                "d) Señales ordinarias. ",
            ),
            result = "c"
        ),

        Question(
            question = "¿Constituye una infracción tramitar el duplicado de una licencia de conducir que se encuentra retenida?",
            options = listOf(
                "a) No constituye una infracción.",
                "b) Solo cuando la licencia de conducir ha sido retenida por la comisión de una infracción muy grave.",
                "c) Sí, constituye una infracción.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "c"
        ),

        Question(
            question = "Las marcas en el pavimento constituyen la señalización ______ y se emplean para ______ la circulación. ",
            options = listOf(
                "a) Horizontal - reglamentar. ",
                "b) Vertical - reglamentar. ",
                "c) Más importante - mejorar.",
                "d) Menos importante - adornar. ",
            ),
            result = "a"
        ),

        Question(
            question = "Ciclista es a ciclovía como: ",
            options = listOf(
                "a) Conductor - acera. ",
                "b) Conductor - calzada. ",
                "c) Conductor - berma. ",
                "d) Peatón - autopista. ",
            ),
            result = "b"
        ),

        Question(
            question = "La siguiente señal (P-15), se utiliza para advertir al conductor:",
            image = "p_15",
            options = listOf(
                "a) La proximidad a una vía de tres carriles.",
                "b) La proximidad a un huracán.",
                "c) La proximidad a un camino sinuoso.",
                "d) La proximidad de una intersección rotatoria (óvalo o rotonda).",
            ),
            result = "d"
        ),

        Question(
            question = "La siguiente señal (P-31a), indica:",
            image = "p_31a",
            options = listOf(
                "a) La proximidad del final de la vía.",
                "b) La proximidad de una vía asfaltada.",
                "c) La proximidad de una pendiente leve.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "Es una línea transversal a la calzada, que indica al conductor que debe detener completamente el vehículo, no debiendo sobrepasar el inicio de la indicada línea:",
            options = listOf(
                "a) Línea de carril. ",
                "b) Línea de borde de calzada. ",
                "c) Línea de pare. ",
                "d) Todas las alternativas son correctas.",
            ),
            result = "c"
        ),

        Question(
            question = "Los semáforos son:",
            options = listOf(
                "a) Dispositivos de control del tránsito que tienen por finalidad regular y controlar el tránsito vehicular, motorizado y no motorizado, y el peatonal, a través de las indicaciones de las luces respectivas.",
                "b) Dispositivos de control del tránsito que tienen por finalidad regular y controlar únicamente el tránsito vehicular motorizado y peatonal, a través de las indicaciones de luces.",
                "c) Señales de color rojo, verde y amarillo, que tienen como único fin regular la corriente vehicular.",
                "d) Artefactos que emiten luces de colores y cuyo único fin es regular la corriente de vehículos motorizados y peatones.",
            ),
            result = "a"
        ),

        Question(
            question = "La conducción requiere un alto nivel de atención, pues existen distracciones que pueden ocasionar accidentes de tránsito, como por ejemplo:",
            options = listOf(
                "a) Preocupaciones. ",
                "b) Uso del teléfono celular. ",
                "c) Manipulación de la radio mientras se conduce.",
                "d) Todas las alternativas son correctas.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Influye la somnolencia en la capacidad de conducir?",
            options = listOf(
                "a) Sí, pues el conductor tomará decisiones lentas que lo inducirán a cometer errores.",
                "b) Sí, ya que el conductor está de mal genio.",
                "c) No, siempre que la conducción sea realizada lentamente.",
                "d) No, siempre y cuando la conducción sea realizada con un acompañante.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál es la acción correcta del conductor, según las normas de tránsito, en la situación que plantea el siguiente gráfico?",
            image = "accion_correcta",
            options = listOf(
                "a) Girar rápidamente a la derecha antes que el peatón cruce la calzada.",
                "b) Dar preferencia de paso al peatón para que cruce la calzada.",
                "c) Girar lentamente a la derecha antes que el peatón cruce la calzada.",
                "d) Tocar el claxon para que el peatón no cruce la calzada.",
            ),
            result = "b"
        ),

        Question(
            question = "¿En cuál de las siguientes opciones, los factores mencionados contribuyen a una colisión vehicular?",
            options = listOf(
                "a) Pavimento seco, somnolencia, frenos desgastados.",
                "b) Pavimento húmedo, neumáticos desgastados, cansancio.",
                "c) Reductores de velocidad, falta de atención, uso del teléfono celular.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "b"
        ),

        Question(
            question = "La ______ es la parte de una carretera o camino contiguo a la calzada, no habilitada para la circulación de vehículos y destinada eventualmente a la detención de vehículos en emergencia y circulación de peatones.",
            options = listOf(
                "a) Berma. ",
                "b) Autopista. ",
                "c) Acera. ",
                "d) Demarcación. ",
            ),
            result = "a"
        ),

        Question(
            question = "Es una parte de la vía destinada a la circulación de vehículos y eventualmente al cruce de peatones y animales. ",
            options = listOf(
                "a) La calzada. ",
                "b) La acera. ",
                "c) La berma. ",
                "d) Ninguna de las alternativas es correcta.",
            ),
            result = "a"
        ),

        Question(
            question = "Es una parte de la vía destinada al uso de peatones: ",
            options = listOf(
                "a) La acera. ",
                "b) La calzada. ",
                "c) La berma. ",
                "d) La autopista. ",
            ),
            result = "a"
        ),

        Question(
            question = "Son señales que regulan el tránsito:",
            options = listOf(
                "a) Las bocinas y las marcas en la calzada o señales horizontales.",
                "b) Las bocinas y las señales verticales.",
                "c) Las señales verticales, las marcas en la calzada o señales horizontales y las bocinas.",
                "d) Las señales verticales y las marcas en la calzada o señales horizontales.",
            ),
            result = "d"
        ),

        Question(
            question = "Los vehículos deben ser conducidos con:",
            options = listOf(
                "a) Puertas y capot cerrados, permitiéndose la maletera entreabierta para casos especiales.",
                "b) Puertas, capot y maletera cerrados.",
                "c) Puertas, capot y maletera cerrados, salvo autorización especial.",
                "d) Capot y maletera cerrados, permitiéndose dos puertas entreabiertas para casos especiales.",
            ),
            result = "b"
        ),

        Question(
            question = "El conductor que en una vía urbana va a girar a la izquierda, a la derecha o en U debe hacer la señal respectiva con la luz direccional, por lo menos:",
            options = listOf(
                "a) 2 segundos antes de realizar la maniobra.",
                "b) 1 metro antes de realizar la maniobra.",
                "c) 20 metros antes de realizar la maniobra.",
                "d) 3 metros antes de realizar la maniobra. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si usted está conduciendo por una carretera y va girar a la izquierda, debe realizar la señal respectiva con la luz direccional por lo menos:",
            options = listOf(
                "a) 2 segundos antes de realizar la maniobra.",
                "b) 2 metros antes de realizar la maniobra.",
                "c) 30 metros antes de realizar la maniobra.",
                "d) 1 metro antes de realizar la maniobra. ",
            ),
            result = "c"
        ),

        Question(
            question = "¿La licencia particular de la Clase y Categoría A-I, permite conducir motos? ",
            options = listOf(
                "a) Sí. ",
                "b) No. ",
                "c) Únicamente en el ámbito urbano.",
                "d) Únicamente en carreteras.",
            ),
            result = "b"
        ),

        Question(
            question = "El siguiente gráfico muestra:",
            image = "vertical_horizontal_www3",
            options = listOf(
                "a) Señalización de tránsito vertical y horizontal en una zona escolar.",
                "b) Únicamente señalización de tránsito vertical en una zona escolar.",
                "c) Únicamente señalización de tránsito horizontal en una zona escolar.",
                "d) Ninguna de las alternativas es correcta. ",
            ),
            result = "a"
        ),

        Question(
            question = "Las ______ canalizadoras, tiene por función conformar las islas canalizadoras del tránsito automotor en una ______. ",
            options = listOf(
                "a) Islas - vía. ",
                "b) Líneas - intersección. ",
                "c) Vías - intersección. ",
                "d) Todas las alternativas son correctas.",
            ),
            result = "b"
        ),

        Question(
            question = "Si al conducir en una intersección se encuentra con las siguientes marcas en el pavimento (malla ortogonal de color amarillo), significa: ",
            image = "malla_amarilla",
            options = listOf(
                "a) Que no puede detener el vehículo dentro del área de intersección.",
                "b) Que se están realizando trabajos de emergencia en el área demarcada.",
                "c) Que únicamente puede detener el vehículo dentro del área de intersección.",
                "d) Que es una isla de refugio peatonal. ",
            ),
            result = "a"
        ),

        Question(
            question = "De acuerdo al siguiente gráfico, es correcto afirmar que:",
            image = "auto_camion_hhh2",
            options = listOf(
                "a) La zona que permite adelantar inicia con las líneas amarillas continuas.",
                "b) Los conductores pueden estacionarse al empezar las líneas continuas.",
                "c) La zona de no adelantar inicia con las líneas amarillas continuas.",
                "d) El conductor del camión puede estacionarse antes de empezar las líneas continuas.",
            ),
            result = "c"
        )
    )
}