package com.sunday.kmplicenseperu.models

import com.sunday.kmplicenseperu.models.License.A1
import com.sunday.kmplicenseperu.models.License.A2A
import com.sunday.kmplicenseperu.models.License.A2B
import com.sunday.kmplicenseperu.models.License.A3A
import com.sunday.kmplicenseperu.models.License.A3B
import com.sunday.kmplicenseperu.models.License.A3C
import com.sunday.kmplicenseperu.models.License.B2A
import com.sunday.kmplicenseperu.models.License.B2B
import com.sunday.kmplicenseperu.models.License.B2C

enum class License(
    val cardTitle: String,
    val image: String,
    val matGen: Int,
    val matEsp: Int,
    val dialogTitle: String,
    val dialogBody: String,
    val rbRangeGeneralList: List<String>,
    val rbRangeSpecificList: List<String>,
) {
    A1("A-I (A1)", "a1_auto", 200, 0, DialogTitle.A1, DialogBody.A1, RbRangeGeneralList.A1, RbRangeSpecificList.A1),
    A2A("A-IIA (A2A)", "a2a_taxi", 200, 24, DialogTitle.A2A, DialogBody.A2A, RbRangeGeneralList.A2A, RbRangeSpecificList.A2A),
    A2B("A-IIB (A2B)", "a2b_minibus", 200, 70, DialogTitle.A2B, DialogBody.A2B, RbRangeGeneralList.A2B, RbRangeSpecificList.A2B),
    A3A("A-IIIA (A3A)", "a3a_omnibus", 200, 72, DialogTitle.A3A, DialogBody.A3A, RbRangeGeneralList.A3A, RbRangeSpecificList.A3A),
    A3B("A-IIIB (A3B)", "a3b_camion", 200, 71, DialogTitle.A3B, DialogBody.A3B, RbRangeGeneralList.A3B, RbRangeSpecificList.A3B),
    A3C("A-IIIC (A3C)", "a3c", 200, 139, DialogTitle.A3C, DialogBody.A3C, RbRangeGeneralList.A3C, RbRangeSpecificList.A3C),
    B2A("B-IIA (B2A)", "b2a_motoneta", 204, 0, DialogTitle.B2A, DialogBody.B2A, RbRangeGeneralList.B2A, RbRangeSpecificList.B2A),
    B2B("B-IIB (B2B)", "b2b_moto", 204, 0, DialogTitle.B2B, DialogBody.B2B, RbRangeGeneralList.B2B, RbRangeSpecificList.B2B),
    B2C("B-IIC (B2C)", "b2c_mototaxi", 204, 40, DialogTitle.B2C, DialogBody.B2C, RbRangeGeneralList.B2C, RbRangeSpecificList.B2C);
}

object LicenseList {
    fun getAllLicences() = getALicenses() + getBLicenses()
    fun getALicenses() = listOf(A1, A2A, A2B, A3A, A3B, A3C)
    fun getBLicenses() = listOf(B2A, B2B, B2C)
}

private object DialogTitle {
    const val A1  = "Clase A - Categoría I"
    const val A2A = "Clase A - Categoría IIA"
    const val A2B = "Clase A - Categoría IIB"
    const val A3A = "Clase A - Categoría IIIA"
    const val A3B = "Clase A - Categoría IIIB"
    const val A3C = "Clase A - Categoría IIIC"
    const val B2A = "Clase B - Categoría IIA"
    const val B2B = "Clase B - Categoría IIB"
    const val B2C = "Clase B - Categoría IIC"
}

private object DialogBody {
    const val A1  = "- Licencia de uso particular (no profesional)." +
            "\n- Permite manejar: sedanes, coupé, hatchback, convertibles, station-wagon, SUV, areneros, pick-up y furgones." +
            "\n- Es necesaria para obtener las demás licencias de Clase A."

    const val A2A = "- Ésta es una licencia profesional."+
            "\n- Permite manejar los mismos vehículos que A1."+
            "\n- Además, Transporte de pasajeros como: taxis, buses, ambulancias y Transporte interprovincial."+
            "\n- Requisitos: Mínimo 2 años con el brevete A1 y mínimo 21 años de edad."

    const val A2B = "- Ésta es una licencia profesional."+
            "\n- Permite manejar los mismos vehículos que A1 y A2A."+
            "\n- Además, Microbuses de hasta 16 asientos y 4 toneladas de peso bruto y Minibuses de hasta 33 asientos y 7 toneladas de peso bruto."+
            "\n- Requisitos: Mínimo 3 años con el brevete A1 o 1 año con el brevete A2A y mínimo 21 años de edad."

    const val A3A  = "- Ésta es una licencia profesional."+
            "\n- Permite manejar los mismos vehículos que A1, A2A, A2B."+
            "\n- Además, vehiculos con más de 6 toneladas como omnibuses urbanos, interurbanos, panorámicos y articulados."+
            "\n- Requisitos: Mínimo 2 años con el brevete A2B y mínimo 24 años de edad."

    const val A3B = "- Esta es una licencia profesional."+
            "\n- Permite manejar los mismos vehículos que A1, A2A, A2B, pero no A3A."+
            "\n- Además, vehículos de chasis cabinado, remolques, gruas, cargobus, plataforma, baranda y volquetes."+
            "\n- Requisitos: Mínimo 2 años con el brevete A2B y mínimo 24 años de edad."

    const val A3C = "- Esta es una licencia profesional."+
            "\n- Permite manejar todos los vehículos de la Categoría A: A1, A2A, A2B, A3A, A3B."+
            "\n- Requisitos: Mínimo 4 años con el brevete A2B o 1 año con el brevete A3A o A3B y mínimo 27 años de edad."

    const val B2A = "- No tiene requisitos previos."+
            "\n- Permite manejar bicimotos para transportar pasajeros o mercancías."

    const val B2B = "- No tiene requisitos previos."+
            "\n- Permite manejar los mismos vehículos que B2A."+
            "\n- Además, Motocicletas (2 ruedas) o Motocicletas con Sidecar (3 ruedas) para transportar pasajeros o mercancías."

    const val B2C = "- No tiene requisitos previos."+
            "\n- Permite manejar los mismos vehículos que B2A y B2B."+
            "\n- Además, Mototaxis o Moto-carga destinados al transporte de pasajeros o mercancías."
}

private object RbRangeGeneralList {
    val A1 = listOf("1-50", "51-100", "101-150", "151-200")
    val A2A = A1
    val A2B = A1
    val A3A = A1
    val A3B = A1
    val A3C = A1
    val B2A = listOf("1-51", "52-102", "103-153", "154-204")
    val B2B = B2A
    val B2C = B2A
}

private object RbRangeSpecificList {
    val A1 = emptyList<String>()
    val A2A = listOf("1-24")
    val A2B = listOf("1-35", "36-70")
    val A3A = listOf("1-36", "37-72")
    val A3B = listOf("1-36", "37-71")
    val A3C = listOf("1-35", "36-70", "71-105", "106-139")
    val B2A = emptyList<String>()
    val B2B = emptyList<String>()
    val B2C = listOf("1-40")
}