package com.sunday.kmplicenseperu.pages.legal

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.components.MyParagraph
import com.sunday.kmplicenseperu.components.MySubtitle
import com.sunday.kmplicenseperu.components.MyTitle
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.components.PageStructure
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.navigation.Routes
import com.sunday.kmplicenseperu.styles.MainButtonStyle
import com.sunday.kmplicenseperu.util.Constants
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.w3c.dom.get

@Page(routeOverride = Routes.CONTACT_ROUTE)
@Composable
fun Contact() {
    val breakpoint = rememberBreakpoint()
    var darkMode by remember { mutableStateOf(localStorage[Constants.DARK_MODE].toBoolean()) }

    PageStructure(
        darkMode = darkMode,
        useHeaderInfo = false,
        onSwitchTheme = { darkMode = !darkMode },
        headerTitle = {
            ContactPageTitle(
                breakpoint = breakpoint,
                darkMode = darkMode
            )
        },
        mainColumn = {
            ContactMainColumn(
                darkMode = darkMode,
            )
        }
    )
}

@Composable
fun ContactMainColumn(
    darkMode: Boolean,
) {
    val surfacePrimary = if (!darkMode) Theme.SurfacePrimary.color.rgb
    else Theme.SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb
    val surfaceBlue = if(!darkMode) Theme.SurfaceBlue.color.rgb
    else Theme.SurfaceBlueDark.color.rgb

    val breakpoint = rememberBreakpoint()
    var visible by remember { mutableStateOf(true) }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(90.percent)
                .backgroundColor(surfacePrimary),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            if(breakpoint <= Breakpoint.SM) {
                MyVerticalSpacer(20.px)
                ContactPageTitle(
                    breakpoint = breakpoint,
                    darkMode = darkMode,
                )
            }

            MyVerticalSpacer(20.px)
            Row(modifier = Modifier.fillMaxWidth()) {
                MySubtitle(
                    text = "* Ponte en contacto",
                    color = textPrimary
                )
            }
            MyVerticalSpacer(20.px)
            MyParagraph(
                text = "Si quieres contactarnos para alguna propuesta comercial, o para mejorar algún apartado de la web, utiliza el siguiente correo electrónico.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)

            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                // behind
                MyParagraph(
                    text = "contacto@mtcsimulacro.net.pe",
                    color = textPrimary
                )
                // front
                Button(
                    attrs = MainButtonStyle.toModifier()
                        .cursor(Cursor.Pointer)
                        .visibility(if(visible) Visibility.Visible else Visibility.Hidden)
                        .onClick { visible = false }
                        .height(40.px)
                        .border(width = 0.px)
                        .borderRadius(r = 5.px)
                        .backgroundColor(surfaceBlue)
                        .toAttrs()
                ) {
                    MyParagraph(
                        text = "Ver correo",
                        color = textPrimary
                    )
                }
            }
        }
    }
}

@Composable
fun ContactPageTitle(
    breakpoint: Breakpoint,
    darkMode: Boolean,
) {
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb
    val textColor = if(breakpoint <= Breakpoint.SM) textPrimary else Theme.SurfacePrimary.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = "Contacto",
            color = textColor,
        )
    }
}