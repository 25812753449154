package com.sunday.kmplicenseperu.navigation

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.navigation.Routes.CONTACT_ROUTE
import com.sunday.kmplicenseperu.navigation.Routes.COOKIES_POLICY_ROUTE
import com.sunday.kmplicenseperu.navigation.Routes.HOME_ROUTE
import com.sunday.kmplicenseperu.navigation.Routes.PRIVACY_POLICY_ROUTE
import com.sunday.kmplicenseperu.navigation.Routes.QUESTION_ROUTE
import com.sunday.kmplicenseperu.navigation.Routes.TERMS_OF_USE_ROUTE
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.silk.components.icons.fa.FaShieldHalved
import com.varabyte.kobweb.silk.components.icons.fa.IconSize

sealed class Screen(val route: String) {
    object Home: Screen(route = HOME_ROUTE)
    object Questions: Screen(route = QUESTION_ROUTE)
}

object Routes {
    const val HOME_ROUTE = "/"
    const val QUESTION_ROUTE = "/preguntas"
    const val PRIVACY_POLICY_ROUTE = "/politica-de-privacidad"
    const val COOKIES_POLICY_ROUTE = "/politica-de-cookies"
    const val CONTACT_ROUTE = "/contacto"
    const val TERMS_OF_USE_ROUTE = "/terminos-de-uso"
//    const val ABOUT_US_ROUTE = "/sobre-nosotros"
//    const val LEGAL_ADVICE_ROUTE = "/aviso-legal"
//    const val CUSTOMIZE_COOKIES_ROUTE = "/personalizar-cookies"
}

enum class Legal(val spanish: String, val path: String) {
    PRIVACY_POLICY(spanish = "Política de privacidad", path = PRIVACY_POLICY_ROUTE),
    COOKIES_POLICY(spanish = "Política de cookies", path = COOKIES_POLICY_ROUTE),
    TERMS_OF_USE(spanish = "Términos de uso", path = TERMS_OF_USE_ROUTE),
    CONTACT(spanish = "Contacto", path = CONTACT_ROUTE),
//    ABOUT_US(spanish = "Sobre nosotros", path = ABOUT_US_ROUTE);
//    LEGAL_ADVICE(spanish = "Aviso legal", path = LEGAL_ADVICE_ROUTE),
//    CUSTOMIZE_COOKIES(spanish = "Personalizar cookies", path = CUSTOMIZE_COOKIES_ROUTE),
}