package com.sunday.kmplicenseperu.pages.legal

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.components.MyLinkToNewTab
import com.sunday.kmplicenseperu.components.MyParagraph
import com.sunday.kmplicenseperu.components.MySubtitle
import com.sunday.kmplicenseperu.components.MyTitle
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.components.PageStructure
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.navigation.Routes
import com.sunday.kmplicenseperu.util.Constants
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get

@Page(routeOverride = Routes.PRIVACY_POLICY_ROUTE)
@Composable
fun PrivacyPolicy() {
    val breakpoint = rememberBreakpoint()
    var darkMode by remember { mutableStateOf(localStorage[Constants.DARK_MODE].toBoolean()) }

    PageStructure(
        darkMode = darkMode,
        useHeaderInfo = false,
        onSwitchTheme = { darkMode = !darkMode },
        headerTitle = {
            PrivacyPolicyPageTitle(
                breakpoint = breakpoint,
                darkMode = darkMode
            )
        },
        mainColumn = {
            PrivacyPolicyMainColumn(
                darkMode = darkMode,
            )
        }
    )
}

@Composable
fun PrivacyPolicyMainColumn(
    darkMode: Boolean,
) {
    val surfacePrimary = if (!darkMode) Theme.SurfacePrimary.color.rgb
    else Theme.SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb
    val textBlue = if(!darkMode) Theme.TextBlue.color.rgb
    else Theme.TextBlueDark.color.rgb

    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(90.percent)
                .backgroundColor(surfacePrimary),
        ) {
            if(breakpoint <= Breakpoint.SM) {
                MyVerticalSpacer(20.px)
                PrivacyPolicyPageTitle(
                    breakpoint = breakpoint,
                    darkMode = darkMode,
                )
            }

            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Información inicial",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "En este apartado se informa sobre nuestras políticas de privacidad respecto a la recopilación y uso de datos mientras usted navega en este sitio web. La información recopilada es utilizada para proporcionar y mejorar el Servicio, nosotros no usaremos ni compartiremos su información con nadie excepto como se describe en esta Política de Privacidad y en la Política de Cookies.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Recopilación y uso de información",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Esta aplicación no solicita directamente información personal de ningún tipo (nombre, e-mail, teléfono, u otro). Por otro lado, sí utiliza servicios de terceros que pueden recopilar información para comprender sus hábitos de navegación, de tal modo que se pueda mejorar el servicio ofrecido. Estos servicios son:",
                color = textPrimary
            )
            MyVerticalSpacer(5.px)
            MyLinkToNewTab(
                text = "Google Analytics",
                path = "https://support.google.com/analytics/answer/7318509?hl=en",
                color = textBlue,
                modifier = Modifier.padding(left = 15.px)
            )
            MyLinkToNewTab(
                text = "Google AdSense",
                path = "https://policies.google.com/privacy?hl=es-419",
                color = textBlue,
                modifier = Modifier.padding(left = 15.px)
            )
            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Enlaces a otros sitios",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Este Servicio puede contener enlaces a otros sitios, tenga en cuenta que estos sitios externos no son operados por nosotros. Por lo tanto, le recomendamos que revise la Política de Privacidad de estos sitios web, ya que no tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Privacidad de los niños",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Este Servicio tiene como fin el de prepararse para aprobar el Examen Teórico y obtener una Licencia de conducir en Perú, por lo que se deduce que está dirigido a mayores de edad. Aunque, cabe mencionar que conforme indica el Reglemanto Nacional de Tránsito, la licencia puede ser obtenida desde los 16 años. No obstante, se reitera que esta aplicación no solicita información personal de ningún tipo.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)

            MySubtitle(
                text = "* Cambios a las Políticas",
                color = textPrimary
            )
            MyVerticalSpacer(15.px)
            MyParagraph(
                text = "Nosotros podemos actualizar nuestra Política de privacidad de vez en cuando. Por lo tanto, se recomienda revisar esta página periódicamente para detectar cualquier cambio.",
                color = textPrimary
            )
            MyParagraph(
                text = "Esta política entra en vigor a partir del 01-02-2024.",
                color = textPrimary
            )
            MyVerticalSpacer(20.px)
        }
    }
}

@Composable
fun PrivacyPolicyPageTitle(
    breakpoint: Breakpoint,
    darkMode: Boolean,
) {
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else Theme.TextPrimaryDark.color.rgb
    val textColor = if(breakpoint <= Breakpoint.SM) textPrimary else Theme.SurfacePrimary.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = "Política de Privacidad",
            color = textColor,
        )
    }
}