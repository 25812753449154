package com.sunday.kmplicenseperu.pages.legal

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.sunday.kmplicenseperu.components.DismissButton
import com.sunday.kmplicenseperu.components.MyParagraph
import com.sunday.kmplicenseperu.components.MySubtitle
import com.sunday.kmplicenseperu.components.MyText14
import com.sunday.kmplicenseperu.components.MyTitle
import com.sunday.kmplicenseperu.components.MyVerticalSpacer
import com.sunday.kmplicenseperu.components.PageStructure
import com.sunday.kmplicenseperu.components.SubmitButton
import com.sunday.kmplicenseperu.components.updateConsentInCookieMessage
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.navigation.Routes
import com.sunday.kmplicenseperu.util.Constants
import com.sunday.kmplicenseperu.util.Cookies
import com.sunday.kmplicenseperu.util.Cookies.ACCEPT_ALL
import com.sunday.kmplicenseperu.util.Cookies.ACCEPT_ANALYTICS
import com.sunday.kmplicenseperu.util.Cookies.ACCEPT_TARGETING
import com.sunday.kmplicenseperu.util.Cookies.ONLY_NECESSARY
import com.sunday.kmplicenseperu.util.Cookies.setExpDate
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaToggleOff
import com.varabyte.kobweb.silk.components.icons.fa.FaToggleOn
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get
import org.w3c.dom.set

@Page(routeOverride = Routes.COOKIES_POLICY_ROUTE)
@Composable
fun CookiesPolicyPage() {
    val breakpoint = rememberBreakpoint()
    var darkMode by remember { mutableStateOf(localStorage[Constants.DARK_MODE].toBoolean()) }

    PageStructure(
        darkMode = darkMode,
        useHeaderInfo = false,
        cookiesBannerVisibleInThisPage = false,
        onSwitchTheme = {
            darkMode = !darkMode
            localStorage[Constants.DARK_MODE] = darkMode.toString()
        },
        headerTitle = {
            CookiesPolicyPageTitle(
                breakpoint = breakpoint,
                darkMode = darkMode
            )
        },
        mainColumn = {
            CookiesPolicyMainColumn(
                darkMode = darkMode,
            )
        }
    )
}

@Composable
fun CookiesPolicyMainColumn(
    darkMode: Boolean,
) {
    val breakpoint = rememberBreakpoint()

    val surfacePrimary = if (!darkMode) Theme.SurfacePrimary.color.rgb
    else Theme.SurfacePrimaryDark.color.rgb

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfacePrimary),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(90.percent)
                .backgroundColor(surfacePrimary),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            if(breakpoint <= Breakpoint.SM) {
                MyVerticalSpacer(12.px)
                CookiesPolicyPageTitle(
                    breakpoint = breakpoint,
                    darkMode = darkMode,
                )
                MyVerticalSpacer(12.px)
            }

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(surfacePrimary),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                CookiesPolicy(darkMode)
                CustomizeCookies(darkMode)
            }
        }
    }
}

@Composable
fun CookiesPolicyPageTitle(
    breakpoint: Breakpoint,
    darkMode: Boolean,
) {
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val textColor = if(breakpoint <= Breakpoint.SM) textPrimary else Theme.SurfacePrimary.color.rgb

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        MyTitle(
            text = "Política de Cookies",
            color = textColor,
        )
    }
}

@Composable
fun CookiesPolicy(darkMode: Boolean) {
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb

    Column(
        modifier = Modifier.fillMaxWidth(),
    ) {
        MyVerticalSpacer(20.px)

        MySubtitle(
            text = "¿Qué son las cookies?",
            color = textPrimary
        )
        MyVerticalSpacer(15.px)
        MyParagraph(
            text = "Las cookies son un conjunto de datos que un servidor deposita en el navegador del usuario para recoger información de su comportamiento en un sitio web. Su uso es común y frecuente en la web ya que permite a las páginas funcionar de manera más eficiente y conseguir una mayor personalización.",
            color = textPrimary
        )
        MyVerticalSpacer(20.px)

        MySubtitle(
            text = "¿Qué tipos de cookies utiliza este sitio web?",
            color = textPrimary
        )
        MyVerticalSpacer(15.px)
        MyParagraph(
            text = "Las cookies utilizadas en nuestro sitio web, son de sesión y de terceros, y nos permiten almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características generales predefinidas por el usuario, así como, seguir y analizar la actividad que lleva a cabo, con el objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y personalizada.",
            color = textPrimary
        )
        MyVerticalSpacer(20.px)

        MySubtitle(
            text = "¿Por qué usamos cookies?",
            color = textPrimary
        )
        MyVerticalSpacer(15.px)
        MyParagraph(
            text = "Este sitio web usa cookies porque el único sustento que tiene son los ingresos generados por la publicidad mostrada.",
            color = textPrimary
        )
    }
}

@Composable
fun CustomizeCookies(
    darkMode: Boolean,
) {
    val surfacePrimary = if(!darkMode) Theme.SurfacePrimary.color.rgb
    else Theme.SurfacePrimaryDark.color.rgb
    val textPrimary = if(!darkMode) Theme.TextPrimary.color.rgb
    else TextPrimaryDark.color.rgb
    val surfaceBlue = if(!darkMode) Theme.SurfaceBlue.color.rgb
    else Theme.SurfaceBlueDark.color.rgb
    val borderPrimary = if(!darkMode) Theme.BorderPrimary.color.rgb
    else Theme.BorderPrimaryDark.color.rgb
    val shadowColor = if(!darkMode) Theme.Black10Percent.color.rgb
    else Theme.White10Percent.color.rgb

    var analyticsSelected by remember { mutableStateOf(true) }
    var targetingSelected by remember { mutableStateOf(true) }

    var userCookie = document.cookie.split("; ").find { it.contains("user_cookie") }

    if(userCookie == null) {
        analyticsSelected = false
        targetingSelected = false
    } else {
        if(userCookie.contains("analytics") && userCookie.contains("targeting")) {
            analyticsSelected = true
            targetingSelected = true
        } else if(userCookie.contains("analytics") && !userCookie.contains("targeting")) {
            analyticsSelected = true
            targetingSelected = false
        } else if(!userCookie.contains("analytics") && userCookie.contains("targeting")) {
            analyticsSelected = false
            targetingSelected = true
        } else {
            analyticsSelected = false
            targetingSelected = false
        }
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MyVerticalSpacer(20.px)
        MySubtitle(
            text = "Personalización de Cookies",
            color = textPrimary
        )
        Row() {
        }
        MyVerticalSpacer(15.px)
        Box(
            modifier = Modifier
                .fillMaxWidth(90.percent)
                .backgroundColor(surfacePrimary)
                .borderRadius(5.px)
                .border(width = 1.px, style = LineStyle.Solid, color = borderPrimary)
                .boxShadow(
                    offsetY = 0.px,
                    offsetX = 0.px,
                    blurRadius = 8.px,
                    spreadRadius = 6.px,
                    color = shadowColor
                ),
            contentAlignment = Alignment.Center
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(90.percent),
            ) {
                MyVerticalSpacer(20.px)

                Column(modifier = Modifier.fillMaxWidth()) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        MyParagraph(
                            text = "Cookies de Analítica",
                            color = textPrimary
                        )
                        if(analyticsSelected)
                            FaToggleOn(
                                modifier = Modifier
                                    .color(surfaceBlue)
                                    .onClick { analyticsSelected = false },
                                size = IconSize.XL
                            )
                        else
                            FaToggleOff(
                                modifier = Modifier
                                    .color(textPrimary)
                                    .onClick { analyticsSelected = true },
                                size = IconSize.XL
                            )
                    }
                    MyVerticalSpacer(10.px)
                    MyText14(
                        text = "Estas cookies permiten medir el tráfico que llega a nuestra web para así mejorar su rendimiento. Nos ayudan a saber qué páginas son las más visitadas. La información que maneja es anónima.",
                        color = textPrimary
                    )
                    MyVerticalSpacer(20.px)
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        MyParagraph(
                            text = "Cookies de Anuncios",
                            color = textPrimary
                        )
                        if(targetingSelected)
                            FaToggleOn(
                                modifier = Modifier
                                    .color(surfaceBlue)
                                    .onClick { targetingSelected = false },
                                size = IconSize.XL
                            )
                        else
                            FaToggleOff(
                                modifier = Modifier
                                    .color(textPrimary)
                                    .onClick { targetingSelected = true },
                                size = IconSize.XL
                            )
                    }
                    MyVerticalSpacer(10.px)
                    MyText14(
                        text = "Estas cookies no almacenan información personal directamente sino que sirven para que los anuncios mostrados sean más relevantes para ti.",
                        color = textPrimary
                    )

                    MyVerticalSpacer(20.px)
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceEvenly
                    ) {
                        DismissButton(
                            darkMode = darkMode,
                            buttonText = "Guardar cambios",
                            onDismiss = {
                                var cookiesToConsent =
                                    if(targetingSelected && analyticsSelected) ACCEPT_ALL
                                    else if(targetingSelected && !analyticsSelected) ACCEPT_TARGETING
                                    else if(!targetingSelected && analyticsSelected) ACCEPT_ANALYTICS
                                    else ONLY_NECESSARY
                                js(deleteAllCookies)
                                document.cookie = "user_cookie=$cookiesToConsent; path=/; expires=${setExpDate(3)}"
                                js(updateConsentInCookieMessage)
                            }
                        )
                        SubmitButton(
                            darkMode = darkMode,
                            buttonText = "Aceptar todas",
                            onSubmit = {
                                document.cookie = "user_cookie=$ACCEPT_ALL; path=/; expires=${setExpDate(3)}"
                                js(updateConsentInCookieCustomization)
                                Cookies.cookiesSet = true
                                analyticsSelected = true
                                targetingSelected = true
                            }
                        )
                    }
                    MyVerticalSpacer(20.px)
                }
            }
        }
        MyVerticalSpacer(20.px)
    }
}

const val updateConsentInCookieCustomization = "dataLayer.push({'event': 'client-consent-update'});"
const val deleteAllCookies = """
    (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
            while (d.length > 0) {
                var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                };
                d.shift();
            }
        }
    })();
"""