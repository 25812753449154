package com.sunday.kmplicenseperu.components

import androidx.compose.runtime.Composable
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.SurfaceBlueDark
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimaryDark
import com.sunday.kmplicenseperu.models.Theme.TextPrimaryDark
import com.sunday.kmplicenseperu.navigation.Legal
import com.sunday.kmplicenseperu.util.Cookies.ACCEPT_ALL
import com.sunday.kmplicenseperu.util.Cookies.cookiesSet
import com.sunday.kmplicenseperu.util.Cookies.setExpDate
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.bottom
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaCookieBite
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import kotlinx.browser.document
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CookieMessage(darkMode: Boolean) {
    val context = rememberPageContext()
    val shadowColor = if(!darkMode) Theme.Black10Percent.color.rgb
    else Theme.White10Percent.color.rgb

    Box(
        modifier = Modifier
            .size(300.px)
            .backgroundColor(Theme.SurfaceSecondaryDark.color.rgb)
            .position(Position.Fixed)
            .left(20.px)
            .bottom(20.px)
            .borderRadius(5.px)
            .boxShadow(
                offsetY = 0.px,
                offsetX = 0.px,
                blurRadius = 8.px,
                spreadRadius = 6.px,
                color = shadowColor
            ).visibility(if(cookiesSet) Visibility.Hidden else Visibility.Visible)
            .zIndex(3),
        contentAlignment = Alignment.Center
    ) {

        Column(
            modifier = Modifier.fillMaxWidth(90.percent),
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center
            ) {
                FaCookieBite(
                    modifier = Modifier.color(SurfaceBlueDark.color.rgb),
                    size = IconSize.X5)
            }
            MyVerticalSpacer(10.px)
            MyParagraph(
                text = "Usamos cookies para analizar el rendimiento y tráfico en nuestra web, así como para mejorar tu experiencia de navegación y mostrar contenido personalizado.",
                color = TextPrimaryDark.color.rgb
            )

            MyVerticalSpacer(20.px)
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                DismissButton(
                    darkMode = darkMode,
                    buttonText = "Configurar",
                    surface = SurfacePrimaryDark.color.rgb,
                    textColor = TextPrimaryDark.color.rgb,
                    lineStyle = LineStyle.None,
                    onDismiss = {
                        context.router.navigateTo(Legal.COOKIES_POLICY.path)
                    }
                )
                SubmitButton(
                    darkMode = darkMode,
                    buttonText = "Aceptar y Cerrar",
                    surface = SurfaceBlueDark.color.rgb,
                    textColor = TextPrimaryDark.color.rgb,
                    onSubmit = {
                        document.cookie = "user_cookie=$ACCEPT_ALL; path=/; expires=${setExpDate(3)}"
                        js(updateConsentInCookieMessage)
                        cookiesSet = true
                    }
                )
            }
        }
    }
}

const val updateConsentInCookieMessage = "dataLayer.push({'event': 'client-consent-update'});"