package com.sunday.kmplicenseperu.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.sunday.kmplicenseperu.components.MyHorizontalSpacer
import com.sunday.kmplicenseperu.models.Theme
import com.sunday.kmplicenseperu.models.Theme.SurfaceBrand
import com.sunday.kmplicenseperu.models.Theme.SurfaceBrandDark
import com.sunday.kmplicenseperu.models.Theme.SurfacePrimary
import com.sunday.kmplicenseperu.navigation.Screen
import com.sunday.kmplicenseperu.util.Constants.HEADER_HEIGHT
import com.sunday.kmplicenseperu.util.Constants.PAGE_WIDTH
import com.sunday.kmplicenseperu.util.Cookies.validateCookies
import com.sunday.kmplicenseperu.util.Res
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleHalfStroke
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaGooglePlay
import com.varabyte.kobweb.silk.components.icons.fa.FaHouse
import com.varabyte.kobweb.silk.components.icons.fa.FaRectangleXmark
import com.varabyte.kobweb.silk.components.icons.fa.FaX
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun HeaderSection(
    breakpoint: Breakpoint,
    darkMode: Boolean,
    logo: String,
    useHeaderInfo: Boolean,
    menuOpened: Boolean,
    onMenu: () -> Unit,
    onMenuClosed: () -> Unit,
    onGooglePlay: () -> Unit,
    onSwitchTheme: () -> Unit,
    onHeaderInfo: () -> Unit,
    onHouse: () -> Unit,
    headerTitle: @Composable () -> Unit
) {
    val surfaceBrand = if(!darkMode) SurfaceBrand.color.rgb
    else SurfaceBrandDark.color.rgb

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(surfaceBrand),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(surfaceBrand)
                .maxWidth(PAGE_WIDTH.px),
            contentAlignment = Alignment.Center
        ) {
            Header(
                breakpoint = breakpoint,
                logo = logo,
                useHeaderInfo = useHeaderInfo,
                menuOpened = menuOpened,
                onMenu = onMenu,
                onMenuClosed = onMenuClosed,
                onGooglePlay = onGooglePlay,
                onSwitchTheme = onSwitchTheme,
                onHeaderInfo = onHeaderInfo,
                onHouse = onHouse,
                headerTitle = headerTitle
            )
        }
    }
}

@Composable
fun Header(
    breakpoint: Breakpoint,
    logo: String,
    useHeaderInfo: Boolean,
    menuOpened: Boolean,
    onMenu: () -> Unit,
    onMenuClosed: () -> Unit,
    onGooglePlay: () -> Unit,
    onSwitchTheme: () -> Unit,
    onHeaderInfo: () -> Unit,
    onHouse: () -> Unit,
    headerTitle: @Composable () -> Unit
) {
    val context = rememberPageContext()
    val imageWidth = when(breakpoint) {
        Breakpoint.ZERO, Breakpoint.SM -> 90.px
        else -> 100.px
    }

    Row(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint > Breakpoint.MD) 80.percent
                else 90.percent
            )
            .height(HEADER_HEIGHT.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        LaunchedEffect(breakpoint) {
            onMenuClosed()
        }
        if(breakpoint <= Breakpoint.MD)
            if(!menuOpened)
                FaBars(
                    modifier = Modifier
                        .color(SurfacePrimary.color.rgb)
                        .cursor(Cursor.Pointer)
                        .onClick { onMenu() },
                    size = IconSize.XL
                )
        else
            FaRectangleXmark(
//            FaXmark(
                modifier = Modifier
                    .color(SurfacePrimary.color.rgb)
                    .cursor(Cursor.Pointer)
                    .onClick { onMenuClosed() },
                size = IconSize.XL
            )
        Image( // TODO .. se muestra la mano cuando aun no entra al logo
            modifier = Modifier
                .width(imageWidth)
                .cursor(Cursor.Pointer)
                .onClick {
                    validateCookies()
                    context.router.navigateTo(Screen.Home.route)
                },
            src = logo,
            description = "Logo Image"
        )
        if(breakpoint > Breakpoint.SM) {
            headerTitle()
            MyHorizontalSpacer(24.px)
        } else {
            Spacer()
        }
//        FaGooglePlay(
//            modifier = Modifier
//                .margin(right = 24.px)
//                .color(SurfacePrimary.color.rgb)
//                .cursor(Cursor.Pointer)
//                .onClick { onGooglePlay() },
//            size = IconSize.XL
//        )
        FaCircleHalfStroke(
            modifier = Modifier
                .margin(right = 24.px)
                .color(SurfacePrimary.color.rgb)
                .cursor(Cursor.Pointer)
                .onClick { onSwitchTheme() },
            size = IconSize.XL
        )
        if(useHeaderInfo)
            FaCircleInfo(
                modifier = Modifier
                    .color(SurfacePrimary.color.rgb)
                    .cursor(Cursor.Pointer)
                    .onClick { onHeaderInfo() },
                size = IconSize.XL
            )
        else
            FaHouse(
                modifier = Modifier
                    .color(SurfacePrimary.color.rgb)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        validateCookies()
                        onHouse()
                    },
                size = IconSize.XL
            )

    }
}