package com.sunday.kmplicenseperu.local

import com.sunday.kmplicenseperu.local.questions.A1GeneralQuestions.getA1LocalQuestions
import com.sunday.kmplicenseperu.models.License
import com.sunday.kmplicenseperu.models.Question
import com.sunday.kmplicenseperu.local.questions.A2ASpecificQuestions.getA2ASpecificQuestions
import com.sunday.kmplicenseperu.local.questions.A2BSpecificQuestions.getA2BSpecificQuestions
import com.sunday.kmplicenseperu.local.questions.A3ASpecificQuestions.getA3ASpecificQuestions
import com.sunday.kmplicenseperu.local.questions.A3BSpecificQuestions.getA3BSpecificQuestions
import com.sunday.kmplicenseperu.local.questions.A3CSpecificQuestions.getA3CSpecificQuestions
import com.sunday.kmplicenseperu.local.questions.B2AGeneralQuestions.getB2AGeneralQuestions
import com.sunday.kmplicenseperu.local.questions.B2BGeneralQuestions.getB2BGeneralQuestions
import com.sunday.kmplicenseperu.local.questions.B2CSpecificQuestions.getB2CSpecificQuestions

object LocalImpl: ILocal {

    override fun getQuestions(license: License): List<Question> {
        return when(license) {
            License.A1 -> getA1LocalQuestions()
            License.A2A -> getA1LocalQuestions() + getA2ASpecificQuestions()
            License.A2B -> getA1LocalQuestions() + getA2BSpecificQuestions()
            License.A3A -> getA1LocalQuestions() + getA3ASpecificQuestions()
            License.A3B -> getA1LocalQuestions() + getA3BSpecificQuestions()
            License.A3C -> getA1LocalQuestions() + getA3CSpecificQuestions()
            License.B2A -> getB2AGeneralQuestions()
            License.B2B -> getB2AGeneralQuestions().dropLast(2) + getB2BGeneralQuestions()
            License.B2C -> getB2AGeneralQuestions() + getB2CSpecificQuestions()
        }
    }
}