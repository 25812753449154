package com.sunday.kmplicenseperu.local.questions

import com.sunday.kmplicenseperu.models.Question

object A3ASpecificQuestions {

    fun getA3ASpecificQuestions() = listOf(

        Question(
            question = "¿Cuál es un elemento, característica o dispositivo considerado como requisito técnico general?",
            options = listOf(
                "a) Dispositivos de alumbrado y señalización óptica.",
                "b) Sistema de frenos.",
                "c) Carrocería diseñada para evitar salpicaduras de las ruedas y/o protegida por guardafangos o escarpines.",
                "d) Todas las anteriores. ",
            ),
            result = "d"
        ),

        Question(
            question = "La instalación de las láminas retroreflectivas debe ser de acuerdo a las siguientes especificaciones:",
            options = listOf(
                "a) Las láminas deben ser fijadas horizontalmente en los laterales del vehículo y en la parte posterior, alternando los colores rojo y blanco.",
                "b) Las láminas deben colocarse a no menos de 300 mm y no más de 1,60 m sobre la superficie de la carretera.",
                "c) Las láminas podrán fijarse a la carrocería del vehículo por medio de diferentes elementos, tales como: remaches, tornillos, autoadhesivo o pegamento, asegurando la fijación permanente.",
                "d) Todas las anteriores. ",
            ),
            result = "d"
        ),

        Question(
            question = "¿Los vehículos de las categorías M2 destinados al servicio de transporte terrestre, M3 y N deben contar con láminas retroreflectivas?",
            options = listOf(
                "a) Solo los Vehículos M3. ",
                "b) Sí. ",
                "c) No es obligatorio. ",
                "d) Ninguna de las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué se entiende por neumático reencauchado?",
            options = listOf(
                "a) Neumático que luego de haberse desgastado, se vuelve operativo al efectuar nuevas ranuras en su banda de rodamiento.",
                "b) Neumático que luego de haberse desgastado, se vuelve operativo al adherirle una nueva banda de rodamiento.",
                "c) Que sin haberse desgastado, presenta ranuras y se le adhiere una banda de rodamiento.",
                "d) Ninguna de las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "La definición: El conjunto de elementos que hace posible que el vehículo pueda operarse usando combustible líquido y GNV simultáneamente, corresponde a:",
            options = listOf(
                "a) Sistema Bicombustible. ",
                "b) Sistema Dual. ",
                "c) Sistema Simultáneo. ",
                "d) Ninguna de las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Con cuántas ruedas de repuesto deben contar los vehículos de categoría M y N? ",
            options = listOf(
                "a) 2. ",
                "b) 1. ",
                "c) No es obligatorio. ",
                "d) Ninguna de las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál es una característica adicional para los vehículos de las categorías M, según el Reglamento Nacional de Vehículos?",
            options = listOf(
                "a) Retrovisores y asientos del conductor. ",
                "b) Guardafangos. ",
                "c) Bocina de sonido uniforme y continua y Fórmula rodante. ",
                "d) a y c son correctas. ",
            ),
            result = "d"
        ),

        Question(
            question = "¿Cuál es el documento que acredita ante SUNARP las modificaciones efectuadas a las características registrables de los vehículos?",
            options = listOf(
                "a) Certificado de Revisión Técnica Vehicular.",
                "b) Certificado de conformidad de Conversión y Modificación.",
                "c) Certificado de Conformidad de Modificación.",
                "d) Declaración Jurada de Conformidad de Modificación.",
            ),
            result = "c"
        ),

        Question(
            question = "La definición de: Complementarias a las luces altas utilizada para alumbrar una mayor distancia de la vía por delante del vehículo, correponde a:",
            options = listOf(
                "a) Luz neblinero delantero. ",
                "b) Luz de largo alcance. ",
                "c) Luz baja. ",
                "d) Luz de freno. ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cúal es una medida preventiva que puede aplicar la autoridad competente, según en el Reglamento Nacional de Vehículos?",
            options = listOf(
                "a) Retención de la licencia de conducir. ",
                "b) Retención de mercancía. ",
                "c) Internamiento del vehículo. ",
                "d) a y c son correctas. ",
            ),
            result = "c"
        ),

        Question(
            question = "¿Cuál es la definición que corresponde a la carrocería, de acuerdo al Reglamento Nacional de Vehículos?",
            options = listOf(
                "a) Estructura básica del vehículo, compuesta por el bastidor, el tren motriz, suspensión, dirección, ejes, ruedas y otras partes mecánicas relacionadas.",
                "b) Estructura que se instala sobre el chasis o estructura autoportante, para el transporte de personas y/o mercancías.",
                "c) Elemento mecánico que sirve de soporte del vehículo, aloja a las ruedas y permite la movilidad del mismo.",
                "d) Estructura básica del vehículo, que sirve de soporte del vehículo y se instala con el chasis.",
            ),
            result = "b"
        ),

        Question(
            question = "La definición: El conjunto de elementos que hace posible que el vehículo pueda operarse usando combustible líquido y GNV simultáneamente, corresponde a:",
            options = listOf(
                "a) Sistema bicombustible. ",
                "b) Sistema Dual. ",
                "c) Sistema Simultaneo. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué porcentaje como mínimo de transparencia debe tener los parabrisas de vidrio de seguridad? ",
            options = listOf(
                "a) 50% ",
                "b) 70% ",
                "c) 80% ",
                "d) 60% ",
            ),
            result = "b"
        ),

        Question(
            question = "¿Qué porcentaje como máximo de oscurecimiento debe tener los parabrisas de vidrio de seguridad? ",
            options = listOf(
                "a) 40% ",
                "b) 20% ",
                "c) 30% ",
                "d) 50% ",
            ),
            result = "c"
        ),

        Question(
            question = "¿De qué categoría son los vehículos de más de ocho asientos, sin contar el asiento del conductor y peso bruto vehicular de 5 toneladas o menos?",
            options = listOf(
                "a) M2 ",
                "b) M3 ",
                "c) N3 ",
                "d) M1",
            ),
            result = "a"
        ),

        Question(
            question = "¿A cuál clase pertenecen los vehículos construidos exclusivamente para el transporte de pasajeros sentados?",
            options = listOf(
                "a) Clase I ",
                "b) Clase II ",
                "c) Clase III ",
                "d) Ninguna de las anteriores. ",
            ),
            result = "c"
        ),

        Question(
            question = "¿A qué categoría pertenecen los vehículos de más de ocho asientos, sin contar el asiento del conductor y peso bruto vehicular de más de 5 toneladas?",
            options = listOf(
                "a) M1 ",
                "b) M3 ",
                "c) M2 ",
                "d) N3",
            ),
            result = "b"
        ),

        Question(
            question = "¿A qué se denomina un ómnibus bi-articulado?",
            options = listOf(
                "a) Vehículo compuesto de tres secciones rígidas unidas entre sí por dos juntas articuladas permitiendo libre paso entre las secciones.",
                "b) Vehículo compuesto de dos secciones rígidas unidas entre sí por una junta articulada permitiendo libre paso entre una sección y otra.",
                "c) Vehículo con la carrocería monocasco autoportante a la cual se fija el conjunto direccional en la parte delantera y el conjunto del tren motriz en la parte posterior.",
                "d) Ninguna de la anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Es obligatorio el Sistema de Freno Auxiliar para los vehículos de categoría M2 y M3? ",
            options = listOf(
                "a) Sí. ",
                "b) No, es opcional para ambos.",
                "c) Para los vehículos de categoría M2 es opcional y para los de categoría M3 es obligatorio.",
                "d) No se aplica para esas categorías. ",
            ),
            result = "c"
        ),

        Question(
            question = "¿Qué cantidad mínima de puertas de servicio deben tener los vehículos que se destinen al servicio de transporte escolar?",
            options = listOf(
                "a) 1 ",
                "b) 2 ",
                "c) Depende de la categoría M2 y M3 ",
                "d) 3",
            ),
            result = "a"
        ),

        Question(
            question = "¿Qué cantidad mínima de salidas de emergencia deben tener los vehículos de categoría M2 y M3 que se destinen al servicio de transporte escolar?",
            options = listOf(
                "a) 2 salidas de emergencia para ambas categorías.",
                "b) 2 salidas de emergencia para la categoría M2 y 3 salidas de emergencia para la categoría M3.",
                "c) 1 Salida de emergencia para la categoría M2 y 2 salidas de emergencia para la categoría M3.",
                "d) 1 Salida de emergencia como mínimo para ambas categorías.",
            ),
            result = "b"
        ),

        Question(
            question = "¿Cuál es la exigencia de la tercera luz de freno? ",
            options = listOf(
                "a) Opcional. ",
                "b) Obligatoria. ",
                "c) No aplica a categorías M. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál es la potencia mínima de la luz baja?",
            options = listOf(
                "a) 20W",
                "b) 10W",
                "c) 25w",
                "d) 55w",
            ),
            result = "c"
        ),

        Question(
            question = "La licencia de conducir de Clase A - Categoría III A tendrá una vigencia de ______. ",
            options = listOf(
                "a) 3 años. ",
                "b) 5 años. ",
                "c) 8 años. ",
                "d) 2 años. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál es la antigüedad mínima contando con licencia de Clase A - Categoría II B que se requiere para obtener la licencia de Clase A categoría III A por recategorización?",
            options = listOf(
                "a) 2 años. ",
                "b) 4 años. ",
                "c) 5 años. ",
                "d) No existe antigüedad mínima. ",
            ),
            result = "a"
        ),

        Question(
            question = "La licencia de conducir de clase A categoría III A permite conducir los vehículos señalados en la categoría:",
            options = listOf(
                "a) I, II A y II B ",
                "b) III ",
                "c) IV ",
                "d) I y II B ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Que permite controlar el GPS instalado en un bus interprovincial?",
            options = listOf(
                "a) El tiempo de conducción. ",
                "b) La distancia recorrida. ",
                "c) La posición del bus. ",
                "d) La velocidad.",
            ),
            result = "d"
        ),

        Question(
            question = "Señale cuál no es una función de las hendiduras de la banda de rodadura de un neumático:",
            options = listOf(
                "a) Permite, en caso de lluvia, esparcir hacia afuera el paso del agua.",
                "b) Facilitar la dispersión de calor acumulado durante la rotación.",
                "c) Brinda la flexibilidad adecuada para permitir una eficaz adherencia.",
                "d) Disminuye la presión de los neumáticos.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Con qué nombre se conoce al elemento que no deja pasar el aceite del carter a la cámara de combustión?",
            options = listOf(
                "a) Bulón. ",
                "b) Retén. ",
                "c) Anillo. ",
                "d) Lumbrera",
            ),
            result = "c"
        ),

        Question(
            question = "¿Qué elemento del sistema de suspensión no corresponde para ser usado en semirremolques? ",
            options = listOf(
                "a) Muelles de hoja. ",
                "b) Neumática.",
                "c) Resorte. ",
                "d) Brazo de torsión.",
            ),
            result = "c"
        ),

        Question(
            question = "¿A qué se llama \"capacidad\" del eje de un vehículo?",
            options = listOf(
                "a) Es la carga máxima permitida que puede soportar el eje de un vehículo.",
                "b) Es la carga máxima que puede soportar el eje de un vehículo.",
                "c) La capacidad de carga de todos los ejes de un vehículo.",
                "d) La carga máxima incluyendo el peso del vehículo.",
            ),
            result = "a"
        ),

        Question(
            question = "Las sanciones por las infracciones al servicio de transporte se encuentran comprendidas en:",
            options = listOf(
                "a) El Reglamento Nacional de Administración de Transporte.",
                "b) El Texto Único Ordenado del Reglamento Nacional de Tránsito - Código de Tránsito.",
                "c) El Reglamento Nacional de Inspecciones Técnicas Vehiculares.",
                "d) Todas las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "Con relación a las obligaciones del conductor del servicio de transporte terrestre, marque la alternativa incorrecta:",
            options = listOf(
                "a) Debe ser titular de una licencia de conducir de la clase y categoría que corresponda al vehículo que conduce.",
                "b) Debe portar su licencia de conducir vigente, así como la documentación del vehículo.",
                "c) Debe conducir solo vehículos habilitados por la autoridad competente.",
                "d) Puede oponerse a la labor de supervisión y fiscalización de la autoridad competente.",
            ),
            result = "d"
        ),

        Question(
            question = "La edad máxima para conducir vehículos destinados al servicio de transporte terrestre es de: ",
            options = listOf(
                "a) 55 años. ",
                "b) 60 años. ",
                "c) 65 años. ",
                "d) 70 años. ",
            ),
            result = "d"
        ),

        Question(
            question = "En el servicio de transporte de personas de ámbito nacional, la habilitación vehicular permite que un vehículo pueda ser empleado en:",
            options = listOf(
                "a) En cualquiera de las rutas autorizadas del transportista a nivel nacional.",
                "b) En cualquiera de las rutas a nivel nacional. ",
                "c) En toda la Red Vial Nacional. ",
                "d) Todas las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "¿Cuál de los siguientes servicios no corresponde al servicio de transporte especial de personas? ",
            options = listOf(
                "a) Diferenciado. ",
                "b) Turístico. ",
                "c) De trabajadores. ",
                "d) De estudiantes. ",
            ),
            result = "a"
        ),

        Question(
            question = "Si un conductor atenta contra la integridad física del inspector durante la realización de sus funciones, será sancionado con:",
            options = listOf(
                "a) Cancelación de licencia de conducir e inhabilitación para obtener nueva licencia por tres (3) años.",
                "b) Suspensión de la licencia de conducir por tres (3) años.",
                "c) Cancelación de la licencia de conducir e inhabilitación definitiva para obtener nueva licencia. Multa de 0.5 UIT.",
                "d) Cancelación de la licencia de conducir por un (1) año e inhabilitación definitiva para obtener nueva licencia. Multa de 1 UIT.",
            ),
            result = "c"
        ),

        Question(
            question = "En el servicio de transporte la vigencia de la habilitación del conductor es ______ y de renovación automática una vez acreditada la vigencia de la licencia de conducir de la categoría correspondiente al vehículo habilitado:",
            options = listOf(
                "a) Anual. ",
                "b) Cada dos años. ",
                "c) Cada cinco años. ",
                "d) Ninguna de las anteriores.",
            ),
            result = "a"
        ),

        Question(
            question = "¿A partir de qué edad el conductor del servicio de transporte deberá rendir y aprobar los exámenes médicos semestrales que establezca la Dirección General de Transporte Terrestre (DGTT)?",
            options = listOf(
                "a) 60 años. ",
                "b) 55 años. ",
                "c) 65 años. ",
                "d) No existe dicha exigencia en la norma. ",
            ),
            result = "c"
        ),

        Question(
            question = "Si un presunto infractor paga voluntariamente dentro de los cinco días hábiles de levantada el acta de control, la multa que corresponda a la infracción imputada, ésta será reducida en:",
            options = listOf(
                "a) 20% de su monto. ",
                "b) 30% de su monto. ",
                "c) 40% de su monto. ",
                "d) 50% de su monto. ",
            ),
            result = "d"
        ),

        Question(
            question = "Cuando hablamos del «servicio de transporte especial de ámbito provincial, prestado en vehículos M1 de la clasificación vehicular, que tiene por objeto el traslado de personas desde un punto de origen hasta un punto de destino señalado por quien lo contrata.», nos estamos refiriendo al:",
            options = listOf(
                "a) Servicio de transporte de trabajadores.",
                "b) Servicio de transporte de mercancías.",
                "c) Servicio de transporte de taxi.",
                "d) Servicio de transporte turístico. ",
            ),
            result = "c"
        ),

        Question(
            question = "Es la medida preventiva que consiste en el traslado del vehículo fuera de la vía pública dispuesto por la autoridad competente y/o PNP, utilizando cualquier medio eficaz y proporcional al fin que se persigue:",
            options = listOf(
                "a) Remoción del vehículo. ",
                "b) Retención del vehículo. ",
                "c) Internamiento del vehículo. ",
                "d) Interrupción del viaje. ",
            ),
            result = "a"
        ),

        Question(
            question = "Es la entidad encargada de supervisar, fiscalizar y sancionar las actividades del transporte terrestre de personas, carga y mercancías en el ámbito nacional:",
            options = listOf(
                "a) La Superintendencia de Transporte Terrestre de Personas, Carga y Mercancías - SUTRAN.",
                "b) La Dirección General de Transporte Terrestre del Ministerio de Transportes y Comunicaciones.",
                "c) El Organismo Supervisor de la Inversión en Infraestructura de Transporte de uso Público - OSITRAN.",
                "d) Las alternativas a) y b) son correctas.",
            ),
            result = "a"
        ),

        Question(
            question = "El servicio de transporte debe ser prestado a través de vehículos que:",
            options = listOf(
                "a) Se encuentren habilitados.",
                "b) Hayan aprobado la Inspección Técnica Vehicular, cuando corresponda.",
                "c) Cuenten con póliza de Seguro Obligatorio de Accidentes de Tránsito vigente o Certificado Contra Accidentes de Tránsito, según corresponda.",
                "d) Todas las anteriores. ",
            ),
            result = "d"
        ),

        Question(
            question = "¿Qué sanción pecuniaria le corresponde al conductor que presta el servicio sin contar con autorización otorgada por la autoridad competente o en una modalidad o ámbito diferente al autorizado?",
            options = listOf(
                "a) Multa de 1 UIT. ",
                "b) Multa de 2 UIT. ",
                "c) Multa de 0.5 UIT. ",
                "d) Multa de 0.05 UIT.",
            ),
            result = "a"
        ),

        Question(
            question = "Con relación a las jornadas de conducción. Marque la alternativa correcta:",
            options = listOf(
                "a) No debe exceder las cinco (5) horas de conducción continuas en el servicio diurno, ni las cuatro (4) horas en el servicio nocturno.",
                "b) No debe exceder las cuatro (4) horas de conducción continuas en el servicio diurno, ni las cinco (5) horas en el servicio nocturno.",
                "c) No debe exceder las cinco (5) horas de conducción continuas en el servicio diurno, ni las cuatro.cinco (4.5) horas en el servicio nocturno.",
                "d) No debe exceder las cuatro (4) horas de conducción continuas en el servicio diurno, ni las tres (3) horas en el servicio nocturno.",
            ),
            result = "a"
        ),

        Question(
            question = "La hoja de ruta electrónica se debe elaborar: ",
            options = listOf(
                "a) Al culminar el servicio de transporte terrestre.",
                "b) Durante el servicio de transporte terrestre, que incluye la relación de pasajeros.",
                "c) Antes del inicio del servicio de transporte terrestre.",
                "d) Durante la venta del pasaje al usuario.",
            ),
            result = "c"
        ),

        Question(
            question = "No es obligación de los usuarios del servicio de transporte de personas:",
            options = listOf(
                "a) Acatar las instrucciones sobre seguridad que emita el conductor o tripulación.",
                "b) Transportar sustancias peligrosas y materiales explosivos, con autorización del transportista.",
                "c) No portar en el salón del vehículo artículos o paquetes que pueden molestar o incomodar a los demás usuarios.",
                "d) Respetar la tarifa convenida. ",
            ),
            result = "b"
        ),

        Question(
            question = "Es el documento que sustenta el traslado de bienes por el transportista autorizado para prestar el servicio de transporte terrestre de mercancías:",
            options = listOf(
                "a) Hoja de ruta.",
                "b) Flete. ",
                "c) Licencia de conducir. ",
                "d) Guía de Remisión de Transportista.",
            ),
            result = "d"
        ),

        Question(
            question = "Si el conductor transporta a sabiendas, productos explosivos, inflamables corrosivos, venenosos o similares en un vehículo del servicio de transporte de personas, comete una infracción calificada como:",
            options = listOf(
                "a) Muy grave.",
                "b) Grave.",
                "c) Leve.",
                "d) No es ni la alternativa a) ni la alternativa c).",
            ),
            result = "a"
        ),

        Question(
            question = "Es la persona natural o jurídica por cuyo encargo se transporte mercancías en un vehículo habilitado para la prestación del servicio de transporte público de mercancías:",
            options = listOf(
                "a) Transportista.",
                "b) Destinatario de carga.",
                "c) Generador de carga.",
                "d) Ninguna de las anteriores.",
            ),
            result = "c"
        ),

        Question(
            question = "El inspector de transporte es la persona acreditada u homologada para:",
            options = listOf(
                "a) Realizar acciones de control, supervisión y detección de incumplimientos o infracciones a las normas del servicio de transporte terrestre.",
                "b) Imponer las papeletas de infracción al tránsito.",
                "c) Emitir la resolución de sanción por las infracciones a las normas del servicio de transporte terrestre.",
                "d) Todas las anteriores. ",
            ),
            result = "d"
        ),

        Question(
            question = "Los terminales terrestres son obligatorios para el servicio público de transporte regular de personas en el ámbito:",
            options = listOf(
                "a) Nacional y Regional. ",
                "b) Regional y Provincial. ",
                "c) Nacional, Regional y Provincial.",
                "d) Distrital, Provincial, Regional, Nacional e Internacional.",
            ),
            result = "c"
        ),

        Question(
            question = "¿En qué momento se debe elaborar la Hoja de ruta electrónica?",
            options = listOf(
                "a) Al culminar el servicio de transporte terrestre.",
                "b) Durante el servicio de transporte terrestre, que incluye la relación de pasajeros.",
                "c) Antes del inicio del servicio de transporte terrestre",
                "d) Durante la venta del pasaje al usuario.",
            ),
            result = "c"
        ),

        Question(
            question = "El Reglamento Nacional de Administración de Transporte no comprende el servicio de: ",
            options = listOf(
                "a) Trabajadores. ",
                "b) Servicio de Transporte en vehículos menores.",
                "c) Servicio de Transporte en auto colectivo.",
                "d) Servicio de Transporte Turístico.",
            ),
            result = "b"
        ),

        Question(
            question = "En el servicio de transporte el ______ debe velar por el cumplimiento de las obligaciones que corresponden al conductor.",
            options = listOf(
                "a) Generador de carga. ",
                "b) Usuario del servicio. ",
                "c) Transportista. ",
                "d) La a) y b) son correctas.",
            ),
            result = "c"
        ),

        Question(
            question = "¿Qué sanción pecuniaria acarrea no informar por escrito a la autoridad competente dentro de las cuarenta y ocho (48) horas de producidos los accidentes de tránsito con daños personales ocurridos durante la operación del servicio?",
            options = listOf(
                "a) Multa de 1 UIT. ",
                "b) Multa de 2 UIT. ",
                "c) Multa de 0.5 UIT. ",
                "d) No está tipificada esta conducta como infracción.",
            ),
            result = "c"
        ),

        Question(
            question = "De las siguientes alternativas, ¿cuál de ellas no es una forma a través de la cual concluye el procedimiento sancionador?",
            options = listOf(
                "a) Resolución de sanción. ",
                "b) Resolución de archivamiento.",
                "c) Pago voluntario de la total de sanción.",
                "d) Ninguna de las anteriores.",
            ),
            result = "d"
        ),

        Question(
            question = "Es exigible el manifiesto de usuarios para el servicio de:",
            options = listOf(
                "a) Transporte regular de personas de ámbito nacional y regional.",
                "b) Transporte regular de personas de ámbito nacional, regional y provincial.",
                "c) Transporte especial de personas de ámbito provincial.",
                "d) Todas las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "Los medios probatorios que sustentan los incumplimientos y las infracciones son:",
            options = listOf(
                "a) El acta de control levantada por el inspector de transporte.",
                "b) El documento por el que se da cuenta de la detección de algún incumplimiento o infracción en la fiscalización de gabinete.",
                "c) Las actas de inspecciones, constataciones, ocurrencias, formularios y similares levantados por otras instituciones en el ejercicio de sus funciones.",
                "d) Todas las anteriores. ",
            ),
            result = "d"
        ),

        Question(
            question = "La sanción que le corresponde al conductor por obstruir la labor de fiscalización, cuando incurre en actos de simulación, suplantación u otras conductas destinadas a hacer incurrir en error a la autoridad competente respecto de la autorización para prestar el servicio, es:",
            options = listOf(
                "a) Suspensión por 30 días de la autorización.",
                "b) Suspensión por 60 días de la autorización.",
                "c) Suspensión de la licencia de conducir por 90 días y multa de 0.5 UIT.",
                "d) Suspensión por 120 días de la autorización.",
            ),
            result = "c"
        ),

        Question(
            question = "En el servicio de transporte el ______ debe velar por el cumplimiento de las obligaciones que corresponden al conductor:",
            options = listOf(
                "a) Generador de carga. ",
                "b) Usuario del servicio. ",
                "c) Transportista. ",
                "d) La a) y b) son correctas.",
            ),
            result = "c"
        ),

        Question(
            question = "La duración acumulada de jornadas de conducción no deberá exceder:",
            options = listOf(
                "a) De doce (12) horas en un periodo de veinticuatro (24) horas, contadas desde el inicio de la conducción en un servicio.",
                "b) De ocho (8) horas en un periodo de veinticuatro (24) horas, contadas desde el inicio de la conducción en un servicio.",
                "c) De diez (10) horas en un periodo de treinta y seis (36) horas, contadas desde el inicio de la conducción en un servicio.",
                "d) De diez (10) horas en un periodo de veinticuatro (24) horas, contadas desde el inicio de la conducción en un servicio.",
            ),
            result = "d"
        ),

        Question(
            question = "¿A partir de qué edad los menores de edad deben ocupar su propio asiento?",
            options = listOf(
                "a) A partir de los cinco (5) años de edad.",
                "b) A partir de los tres (3) años de edad.",
                "c) A partir de los siete (7) años de edad.",
                "d) No edad mínima para que los menores de edad ocupen su propio asiento.",
            ),
            result = "a"
        ),

        Question(
            question = "En materia de transporte y tránsito terrestre, la Superintendencia de Transporte Terrestre de personas, carga y Mercancías - SUTRAN, es ______:",
            options = listOf(
                "a) La entidad encargada de regular la infraestructura de transporte de uso público.",
                "b) La entidad que tiene como función la promoción del mercado y la protección de los derechos de los consumidores.",
                "c) El órgano rector a nivel nacional en materia de transporte y tránsito terrestre.",
                "d) La autoridad encargada de fiscalizar el servicio de transporte de personas y carga de ámbito nacional.",
            ),
            result = "d"
        ),

        Question(
            question = "¿Pueden viajar dos personas en un mismo asiento? ",
            options = listOf(
                "a) Nunca se da este supuesto.",
                "b) Sí, cuando una de ellas es un menor de cinco (5) años de edad.",
                "c) Sí, cuando una de ellas es un menor de tres (3) años de edad.",
                "d) Ninguna de las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "En el servicio de transporte estándar de ámbito provincial, está permitido:",
            options = listOf(
                "a) El viaje de personas sentadas y de pie, respetando la capacidad prevista por el fabricante.",
                "b) El transporte de mercancías correctamente estibadas.",
                "c) El viaje de personas sentadas y de pie, en el lugar donde fácilmente puedan ser ubicadas.",
                "d) Ninguna de las anteriores. ",
            ),
            result = "a"
        ),

        Question(
            question = "De las entidades que se indican a continuación, ¿cuál de ellas no es una autoridad competente en materia de transporte y tránsito terrestre?",
            options = listOf(
                "a) El Ministerio de Transportes y Comunicaciones.",
                "b) Las Municipalidades provinciales y distritales.",
                "c) El Consejo Nacional de Seguridad Vial.",
                "d) La Policía Nacional del Perú. ",
            ),
            result = "c"
        ),

        Question(
            question = "En el servicio de transporte de personas de ámbito provincial, el ascenso y descenso de los usuarios se podrá realizar en:",
            options = listOf(
                "a) Los lugares autorizados o paraderos de ruta.",
                "b) En los paraderos urbanos e interurbanos.",
                "c) En cualquier punto de la vía, siempre que se tome la debida precaución.",
                "d) Todas las anteriores. ",
            ),
            result = "b"
        ),

        Question(
            question = "Al conductor que durante una acción de fiscalización se niega a entregar la información o documentación sobre su habilitación como conductor del servicio de transporte, se le debe aplicar la siguiente sanción:",
            options = listOf(
                "a) Suspensión de la licencia de conducir por 30 días calendario.",
                "b) Suspensión de la licencia de conducir por 90 días calendario y multa de 0.5 UIT.",
                "c) Suspensión de la licencia de conducir por 10 días calendario.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "Es el servicio de transporte de personas y de mercancías en un vehículo autorizado para este tipo de transporte:",
            options = listOf(
                "a) Servicio de transporte estándar.",
                "b) Servicio de transporte mixto.",
                "c) Servicio de transporte diferenciado.",
                "d) Ninguna de las anteriores.",
            ),
            result = "b"
        ),

        Question(
            question = "El vehículo que presta el servicio de transporte de ámbito nacional o regional debe contar con el siguiente Seguro:",
            options = listOf(
                "a) Seguro Obligatorio de Accidentes de Tránsito - SOAT.",
                "b) Certificado Contra Accidentes de Tránsito - CAT.",
                "c) Seguro Obligatorio de Accidentes de Tránsito - SOAT o Certificado Contra Accidentes de Tránsito - CAT.",
                "d) Alternativas a) y b). ",
            ),
            result = "a"
        ),

    )
}