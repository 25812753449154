package com.sunday.kmplicenseperu.util

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.document
import kotlin.js.Date

object Cookies {
    var cookiesSet by mutableStateOf(document.cookie.contains("user_cookie"))

    val ONLY_NECESSARY = "{\"categories\":[\"necessary\"],\"consent_date\":\"${consentDate()}\"}"
    val ACCEPT_ANALYTICS = "{\"categories\":[\"necessary\",\"analytics\"],\"consent_date\":\"${consentDate()}\"}"
    val ACCEPT_TARGETING = "{\"categories\":[\"necessary\",\"targeting\"],\"consent_date\":\"${consentDate()}\"}"
    val ACCEPT_ALL = "{\"categories\":[\"necessary\",\"analytics\",\"targeting\"],\"consent_date\":\"${consentDate()}\"}"

    fun cookiesExistInBrowser() = document.cookie.contains("user_cookie")

    fun validateCookies() {
        if(!cookiesExistInBrowser()) cookiesSet = false
    }

    private fun consentDate() = Date(Date.now())

    fun setExpDate(monthsAdded: Int): String {
        val currentMonth = Date().getMonth()
        var day = Date().getDate()
        var month = currentMonth + monthsAdded
        var year = Date().getFullYear()
        if(month > 11) {
            month = month - 11 - 1
            year = Date().getFullYear() + 1
        }
        if(day in 29..31) day = 28
        return Date(Date.UTC(year, month, day)).toUTCString()
    }
}